import { IStackProps, ITextProps, Stack, Text } from 'native-base';
import * as React from 'react';

import { AvatarImage, AvatarImageProps } from './AvatarImage';

interface AvatarProps extends AvatarImageProps {
  name?: string;
  nameProps?: ITextProps;
  containerProps?: IStackProps;
}

export const Avatar = ({
  name,
  nameProps = { size: 'caption', fontWeight: 900 },
  containerProps,
  ...props
}: AvatarProps) => {
  return (
    <Stack justifyContent="center" alignItems="center" space="4px" {...containerProps}>
      <Stack borderRadius="100%" w={props.imageSize} h={props.imageSize} overflow="hidden">
        <AvatarImage {...props} />
      </Stack>
      {name ? <Text {...nameProps}>{name}</Text> : null}
    </Stack>
  );
};
