import { datadogLogs } from '@datadog/browser-logs';
import { useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import {
  StripeError,
  StripeElementsOptions,
  PaymentRequest,
  PaymentRequestShippingAddressEvent,
  PaymentRequestPaymentMethodEvent,
  PaymentMethodCreateParams,
} from '@stripe/stripe-js';
import axios from 'axios';
import { Spacer, View, Heading, Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';

import { PreparingCheckoutAnimation } from './PreparingCheckoutAnimation';
import {
  OrderSummary,
  CheckoutFormBilling,
  ExpressCheckout,
  CheckoutFormShipping,
} from './components';
import { trackCheckoutStarted } from './trackCheckoutStarted';
import { trackOrderCompleted } from './trackOrderCompleted';

import { Cart, ContactPreference, useCheckValidEmail } from '@/api';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useTaxRates, useValidateAddressQuery, useFriendbuy, useUTT } from '@/hooks';
import { useFunnelErrorHandler } from '@/hooks/useFunnelErrorHandler';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { useSessionQuery } from '@/hooks/useSessionQuery';
import { FALLBACK_TAX_RATE } from '@/hooks/useTaxRates';
import { queryClient } from '@/lib/react-query';
import { CheckoutFormResolver } from '@/screens';
import segment from '@/segment';
import { useStripeElementsOptionsBase, stripePromise } from '@/stripe';
import { FunnelSession, FunnelScreenProps } from '@/types';
import { invalidAddressErrorHandler, isPup } from '@/utils';
import { getCurrentPet } from '@/utils/getCurrentPet';

const { logger } = datadogLogs;

const DEFAULT_CHECKOUT_ERROR_MESSAGE =
  'There was a problem with your request. Please try again or contact care@spotandtango.com for further assistance.';

const calculateCartTotal = (cart: Cart) =>
  Math.round(Number(cart?.prices?.total_to_be_charged) * 100);

export interface CheckoutDetails {
  first_name: string;
  last_name: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  readonly country: 'US';
  zip_code: string;
  phone_number: string;
  to_receive_text_messages: boolean;
}

export default function Checkout({ navigation }: FunnelScreenProps<'Checkout'>) {
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(true);

  return (
    <>
      {showLoadingAnimation ? <PreparingCheckoutAnimation /> : null}
      <CheckoutContent navigation={navigation} onReady={() => setShowLoadingAnimation(false)} />
    </>
  );
}

const CheckoutContent = ({
  navigation,
  onReady,
}: {
  navigation: FunnelScreenProps<'Checkout'>['navigation'];
  onReady: () => void;
}) => {
  const { prepareCheckout, session } = useSessionQuery();
  const [clientSecret, setClientSecret] = useState<string>('');
  const [intentObjectType, setIntentObjectType] =
    useState<Cart['stripe_payment_intent_object']>('payment_intent');
  const [cart, setCart] = useState<Cart['cart_preview']>();
  const funnelErrorHandler = useFunnelErrorHandler();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const doPrepareCheckout = async () => {
      try {
        await prepareCheckout();
      } catch (error) {
        if (axios.isAxiosError(error) && error?.response?.status === 400) {
          showBoundary(error);
        } else {
          funnelErrorHandler(error, 'Prepare Checkout');
        }
        return;
      }
      const { stripe_payment_intent_secret, stripe_payment_intent_object, cart_preview } =
        queryClient.getQueryData(['cart']) as Cart;
      setClientSecret(stripe_payment_intent_secret);
      setIntentObjectType(stripe_payment_intent_object);

      setCart(cart_preview);
    };
    // wait for queryClient to be ready

    doPrepareCheckout();
  }, []);

  useEffect(() => {
    if (session && cart) {
      try {
        trackCheckoutStarted({ cart, session });
      } catch (e) {
        console.error('Error sending Segment call', e);
      }
    }
  }, [cart]);

  const methods = useForm<CheckoutDetails>({
    resolver: CheckoutFormResolver,
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip_code: '',
      to_receive_text_messages: false,
    },
    mode: 'onSubmit',
  });

  const elementsOptionsBase = useStripeElementsOptionsBase();

  if (cart?.prices?.total_to_be_charged === undefined || !clientSecret) {
    return null;
  }

  const elementsOptions: StripeElementsOptions = {
    ...elementsOptionsBase,
    clientSecret,
  };

  const onCartUpdate = () => {
    const { stripe_payment_intent_secret, stripe_payment_intent_object, cart_preview } =
      queryClient.getQueryData(['cart']) as Cart;
    setClientSecret(stripe_payment_intent_secret);
    setIntentObjectType(stripe_payment_intent_object);
    setCart(cart_preview);
    queryClient.invalidateQueries('cart');
  };

  return (
    <Elements stripe={stripePromise} options={elementsOptions}>
      <CheckoutFormInner
        methods={methods}
        cart={cart}
        clientSecret={clientSecret}
        intentObjectType={intentObjectType}
        navigation={navigation}
        onReady={onReady}
        onCartUpdate={onCartUpdate}
      />
    </Elements>
  );
};

const CheckoutFormInner = ({
  navigation,
  methods,
  cart,
  clientSecret,
  intentObjectType,
  onReady,
  onCartUpdate,
}: {
  navigation: FunnelScreenProps<'Checkout'>['navigation'];
  methods: UseFormReturn<CheckoutDetails, any>;
  cart: Cart['cart_preview'];
  clientSecret: string;
  intentObjectType: Cart['stripe_payment_intent_object'];
  onReady: () => void;
  onCartUpdate: () => void;
}) => {
  const nextStep = useNextFunnelStep(FunnelScreenNames.CHECKOUT);
  const { session: funnelSession, removeDiscount, applyDiscounts } = useSessionQuery();
  const dog = getCurrentPet(funnelSession);
  const dogIsPup = isPup(dog?.birth_year, dog?.birth_month);

  const stripe = useStripe();
  const elements = useElements();
  const { isLoading: isLoadingTaxRate, getTaxRateByZipCode } = useTaxRates();
  const { mutateAsync: checkValidEmail } = useCheckValidEmail();
  const [checkoutSubmitError, setCheckoutSubmitError] = useState<string | undefined>();
  const [expressPaymentSubmitError, setExpressPaymentSubmitError] = useState<string | undefined>();
  const [stripeErrorMessage, setStripeErrorMessage] = useState<string | undefined>();
  const [discountError, setDiscountError] = useState<string | undefined>();
  const [isLoadingCanMakeExpressPayment, setIsLoadingCanMakeExpressPayment] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expressPaymentRequest, setExpressPaymentRequest] = useState<PaymentRequest>();
  const funnelErrorHandler = useFunnelErrorHandler();
  const { trackFunnelCheckoutOrder } = useFriendbuy();
  const { trackImpactConversion } = useUTT();
  const showCheckoutSubmitError = (message?: any) =>
    setCheckoutSubmitError((prev) => message || prev || DEFAULT_CHECKOUT_ERROR_MESSAGE);

  const { prepareCheckout, finalize, mutateUpdateSession, updateEmail, session } =
    useSessionQuery();
  const {
    validateAddress: { mutateAsync: validateAddress },
  } = useValidateAddressQuery();

  const onApplyDiscount = async (discountCode: string) => {
    setIsLoading(true);
    setDiscountError(undefined);
    try {
      await applyDiscounts([discountCode]);
      await prepareCheckout();
    } catch (error) {
      funnelErrorHandler(error, 'Apply discounts', () => {
        setDiscountError('Discount code is invalid.');
      });
    }
    onCartUpdate();
    setIsLoading(false);
  };

  const onRemoveDiscount = async (discountCode: string) => {
    setIsLoading(true);
    try {
      await removeDiscount(discountCode);
      await prepareCheckout();
    } catch (error) {
      funnelErrorHandler(error, 'Remove discounts');
      return;
    }
    onCartUpdate();
    setIsLoading(false);
  };

  const onPaymentRequestShippingAddressChange = async (
    event: PaymentRequestShippingAddressEvent
  ) => {
    // When the payment request popup first appears or when the user selects a
    // different address, we should update the estimated total with the correct
    // tax multiplier for their zip code.
    let taxRateStatus: string;
    let taxRate = FALLBACK_TAX_RATE;
    if (event.shippingAddress.postalCode) {
      const taxRateRecord = getTaxRateByZipCode(event.shippingAddress.postalCode);
      taxRateStatus = taxRateRecord.result;
      taxRate = taxRateRecord.taxRate;
    } else {
      taxRateStatus = 'zip-code-not-provided';
    }

    if (taxRateStatus !== 'estimated') {
      logger.warn('Tax estimation failed in express checkout', {
        shippingAddress: event.shippingAddress,
        taxRate,
        taxRateStatus,
      });
    }

    event.updateWith({
      status: 'success',
      total: {
        label: "Today's Total",
        amount: Math.round(Number(cart?.prices?.subtotal_to_be_charged) * 100 * (1 + taxRate)),
      },
      displayItems: [
        {
          label: `Est. Tax (${event.shippingAddress.postalCode})`,
          amount: Math.round(Number(cart?.prices?.subtotal_to_be_charged) * 100 * taxRate),
        },
      ],
    });
  };

  const onExpressCheckoutSubmit = async (event: PaymentRequestPaymentMethodEvent) => {
    if (!stripe) {
      return;
    }
    setCheckoutSubmitError(undefined);
    setExpressPaymentSubmitError(undefined);
    const paymentShippingAddress = event.shippingAddress;
    const paymentShippingState = paymentShippingAddress?.region?.toUpperCase();
    try {
      await validateAddress({
        address1: paymentShippingAddress?.addressLine?.[0],
        address2: paymentShippingAddress?.addressLine?.[1],
        city: paymentShippingAddress?.city,
        state: paymentShippingState,
        zip: paymentShippingAddress?.postalCode,
      });
    } catch (error) {
      funnelErrorHandler(
        error,
        'Validate address',
        () => {
          const validationError = error as any;
          const response = validationError.response as any;
          const errors = response?.data.errors;
          // Handles validateAddress fail case
          if (Array.isArray(errors)) {
            if (errors.find((error) => ['zip_code', 'state'].includes(error.name))) {
              event.complete('invalid_shipping_address');
              return;
            }
            const avalaraAddressErrors = errors.find((error) =>
              error?.message?.includes('Address.resolve_address')
            );
            if (avalaraAddressErrors) {
              invalidAddressErrorHandler();
            }
          }
          event.complete('fail');
        },
        () => {
          event.complete('fail');
        }
      );
      return;
    }

    const fullName = event.payerName;
    if (!fullName) {
      event.complete('fail');
      setExpressPaymentSubmitError('Full name is required.');
      return;
    }
    const [firstName, ...lastNameParts] = fullName.split(' ');
    const lastName = lastNameParts.join(' ');
    if (!firstName || !lastName) {
      event.complete('fail');
      setExpressPaymentSubmitError('Full name is required.');
      return;
    }

    const updatedSessionData: Partial<FunnelSession> = {
      first: firstName,
      last: lastName,
      address1: paymentShippingAddress?.addressLine?.[0],
      address2: paymentShippingAddress?.addressLine?.[1],
      city: paymentShippingAddress?.city,
      state: paymentShippingState,
      zip_code: paymentShippingAddress?.postalCode,
    };

    try {
      await mutateUpdateSession({ data: updatedSessionData });
    } catch (error) {
      event.complete('fail');
      funnelErrorHandler(error, 'Update session (payment)');
      return;
    }

    try {
      await prepareCheckout();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        setExpressPaymentSubmitError(error.response?.data || DEFAULT_CHECKOUT_ERROR_MESSAGE);
      }
      event.complete('fail');
      funnelErrorHandler(error, 'Prepare Checkout');
      return;
    }

    try {
      let confirmError: StripeError | undefined;
      if (intentObjectType === 'setup_intent') {
        const { error } = await stripe.confirmCardSetup(
          clientSecret,
          { payment_method: event.paymentMethod.id },
          { handleActions: false }
        );
        confirmError = error;
      } else if (intentObjectType === 'payment_intent') {
        const { error } = await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: event.paymentMethod.id },
          { handleActions: false }
        );
        confirmError = error;
      } else {
        throw Error(`Unknown object type from prepare_checkout: ${intentObjectType}}`);
      }

      if (confirmError) {
        throw confirmError;
      }
    } catch (error) {
      funnelErrorHandler(error, 'Stripe confirm credit card payment');
      return;
    }

    try {
      const finalizeResponse = await finalize();
      event.complete('success');
      const cartTotal = cart?.prices?.total_to_be_charged;
      const initialOrderID = finalizeResponse.order.id;
      const userName =
        session?.last && session.first ? `${session.first} ${session.last}` : `${session?.first}`;

      trackFunnelCheckoutOrder(
        session?.id,
        initialOrderID,
        cartTotal,
        session?.email,
        session?.discounts?.[0]?.code ?? '',
        userName
      );
      if (session) {
        try {
          trackOrderCompleted({ cart, session, finalizeResponse });
        } catch (e) {
          console.error('Error sending Segment call', e);
        }
        try {
          if (initialOrderID && session.account && session.email) {
            trackImpactConversion(initialOrderID, session.account, session.email, cart as Cart);
          } else {
            logger.error('Failed to track UTT user conversion', { session });
          }
        } catch (e) {
          logger.error('Caught error trying to track UTT user conversion', { e });
        }
      }
      navigation.navigate(nextStep, {
        chargeDate: finalizeResponse.order.charged,
      });
    } catch (error) {
      event.complete('fail');
      funnelErrorHandler(error, 'Finalize Checkout');
    }
  };

  useEffect(() => {
    if (stripe && elements && !isLoadingTaxRate) {
      const cartTotal = calculateCartTotal(cart as Cart);
      const cartTax = Math.round(Number(cart?.prices?.tax_amount) * 100);
      const paymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: "Today's Total",
          amount: cartTotal,
        },
        displayItems: [{ label: `Est. Tax ${session?.zip_code}`, amount: cartTax }],
        requestPayerName: true,
        requestPayerEmail: true,
        requestShipping: true,
        shippingOptions: [
          { amount: 0, id: 'free-shipping', label: 'Free Shipping', detail: 'Free Shipping' },
        ],
      });

      // Check the availability of the Payment Request API
      paymentRequest.canMakePayment().then((result) => {
        if (result?.googlePay || result?.applePay) {
          setExpressPaymentRequest(paymentRequest);
        }
        paymentRequest.on('paymentmethod', onExpressCheckoutSubmit);
        setIsLoadingCanMakeExpressPayment(false);
      });
    }
  }, [stripe, elements, isLoadingTaxRate]);

  useEffect(() => {
    // Updates the event listener for the 'paymentmethod' event to ensure that it uses the new
    // handleExpressCheckoutConfirm function that has the updated intentObjectType
    // This ensures that we make the correct stripe call to 'payment_intent' or 'setup_intent'
    // depending on the updated coupon code
    // Also updates the charge amount on express payment pop up to reflect the updated
    // total whenever the customer changes the coupon code
    if (expressPaymentRequest) {
      const cartTotal = calculateCartTotal(cart as Cart);
      const cartTax = Math.round(Number(cart?.prices?.tax_amount) * 100);
      expressPaymentRequest.update({
        total: {
          label: "Today's Total",
          amount: cartTotal,
        },
        displayItems: [{ label: `Est. Tax ${session?.zip_code}`, amount: cartTax }],
      });
      expressPaymentRequest.off('paymentmethod').on('paymentmethod', onExpressCheckoutSubmit);
    }
  }, [cart, intentObjectType, expressPaymentRequest]);

  useEffect(() => {
    // Whenever the cart changes, we need to re-listen with the newest price data
    // e.g. if a discount code is added/removed
    if (expressPaymentRequest) {
      expressPaymentRequest
        .off('shippingaddresschange')
        .on('shippingaddresschange', onPaymentRequestShippingAddressChange);
    }
  }, [expressPaymentRequest, cart]);

  useEffect(() => {
    if (stripe && elements && !isLoadingCanMakeExpressPayment) {
      onReady();
    }
  }, [stripe, elements, isLoadingCanMakeExpressPayment]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('beforeRemove', (e) => {
      if (!isLoading) {
        return;
      }
      e.preventDefault();
    });
    return () => unsubscribe();
  }, [navigation, isLoading]);

  if (!stripe || !elements || isLoadingCanMakeExpressPayment) {
    return null;
  }

  const onSubmit = async ({
    first_name,
    last_name,
    email,
    address1,
    address2,
    city,
    state,
    country = 'US',
    zip_code,
    phone_number,
    to_receive_text_messages,
  }: CheckoutDetails) => {
    setCheckoutSubmitError(undefined);
    setExpressPaymentSubmitError(undefined);
    setStripeErrorMessage(undefined);
    setIsLoading(true);

    segment.trackEvent('User Clicked Checkout Button', {
      email,
      first_name,
      last_name,
      phone: phone_number,
      account_id: null,
      session_id: funnelSession?.id,
    });

    const { error: elementsSubmitError } = await elements.submit();
    if (elementsSubmitError) {
      showCheckoutSubmitError(elementsSubmitError.message);
      setIsLoading(false);
      return;
    }

    try {
      await checkValidEmail(email);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const resData = e.response?.data;
        if (resData && (resData === 'ACTIVE_ACCOUNT' || resData === 'ACTIVE_SHOPIFY_ACCOUNT')) {
          methods.setError('email', {
            type: 'string',
            message: 'This email is already associated with an existing account.',
          });
        } else {
          showCheckoutSubmitError(resData);
        }
      } else {
        showCheckoutSubmitError();
      }
      setIsLoading(false);
      return;
    }

    try {
      await validateAddress({ address1, city, state, zip: zip_code });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const resData = e.response?.data;
        const errors = resData?.errors;
        if (Array.isArray(errors)) {
          // handle cases like mismatching state + zipcode
          const fullAddressErrors = errors.filter((error) => error?.name === 'full_address');
          if (fullAddressErrors.length > 0) {
            methods.setError('address1', {
              type: 'string',
              message: 'There was a problem with the address you submitted.',
            });
          }
          for (const error of errors) {
            methods.setError(error.name, { type: 'string', message: error.message });
          }

          const avalaraAddressErrors = errors.find((error) =>
            error?.message?.includes('Address.resolve_address')
          );
          if (avalaraAddressErrors) {
            logger.error(`[ERROR] ${email} hit address validation error on checkout`, {
              error: avalaraAddressErrors,
              fullAddress: `${address1} ${address2} ${city} ${state} ${zip_code}`,
              email,
            });
            invalidAddressErrorHandler();
          }
        } else {
          showCheckoutSubmitError(resData);
        }
      } else {
        showCheckoutSubmitError();
      }
      setIsLoading(false);
      return;
    }

    // Updates the session with the validated form data
    const contact_preference_marketing =
      to_receive_text_messages.toString() === 'true'
        ? ContactPreference.ANY
        : ContactPreference.EMAIL;
    const newData: Partial<FunnelSession> = {
      first: first_name,
      last: last_name,
      zip_code,
      address1,
      address2,
      city,
      state,
      contact_number: phone_number,
      contact_preference_marketing,
    };

    try {
      await mutateUpdateSession({ data: newData });
    } catch (error) {
      funnelErrorHandler(error, 'Update session (payment)');
      showCheckoutSubmitError();
      setIsLoading(false);
      return;
    }

    try {
      await updateEmail({ email });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.status === 409) {
          methods.setError('email', {
            type: 'string',
            message: 'This email is already associated with an existing account.',
          });
        } else {
          showCheckoutSubmitError(
            'There was an error with your email. Please try again or contact care@spotandtango.com for further assistance.'
          );
        }
      } else {
        showCheckoutSubmitError();
      }
      setIsLoading(false);
      return;
    }

    try {
      await prepareCheckout();
    } catch (e) {
      let errorMessage: any;
      // handle address already exists in prepare_checkout
      if (axios.isAxiosError(e) && e.response?.status === 400) {
        errorMessage = e.response?.data;
      }
      showCheckoutSubmitError(errorMessage);
      setIsLoading(false);
      return;
    }

    // Charge the payment method
    const card = elements.getElement('card');
    if (!card) {
      logger.error('Card element not found');
      showCheckoutSubmitError();
      setIsLoading(false);
      return;
    }
    try {
      let confirmPaymentError: StripeError | undefined;
      const billingDetails: PaymentMethodCreateParams.BillingDetails = {
        name: `${first_name} ${last_name}`,
        email,
        address: {
          line1: address1,
          line2: address2 ? address2 : '',
          city,
          state,
          country,
          postal_code: zip_code,
        },
        phone: phone_number ? phone_number : '',
      };

      if (intentObjectType === 'setup_intent') {
        const { error } = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card,
            billing_details: billingDetails,
          },
        });
        confirmPaymentError = error;
      } else if (intentObjectType === 'payment_intent') {
        const { error } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card,
            billing_details: billingDetails,
          },
        });
        confirmPaymentError = error;
      } else {
        throw Error(`Unknown object type from prepare_checkout: ${intentObjectType}}`);
      }

      if (confirmPaymentError) {
        throw confirmPaymentError;
      }
    } catch (error) {
      funnelErrorHandler(error, 'Stripe confirm card payment', () => {
        const stripeError = error as StripeError;
        logger.error('Card checkout Stripe error: ', { error: stripeError });
        setStripeErrorMessage(stripeError.message);
      });
      setIsLoading(false);
      return;
    }

    try {
      const finalizeResponse = await finalize();
      const cartTotal = cart?.prices?.total_to_be_charged;
      const initialOrderID = finalizeResponse.order.id;
      const userName =
        session?.last && session.first ? `${session.first} ${session.last}` : `${session?.first}`;

      trackFunnelCheckoutOrder(
        session?.id,
        initialOrderID,
        cartTotal,
        session?.email,
        session?.discounts?.[0]?.code ?? '',
        userName
      );
      if (session) {
        try {
          trackOrderCompleted({ cart, session, finalizeResponse });
        } catch (e) {
          console.error('Error sending Segment call', e);
        }
        try {
          if (initialOrderID && session.account && session.email) {
            trackImpactConversion(initialOrderID, session.account, session.email, cart as Cart);
          } else {
            logger.error('Failed to track UTT user conversion', { session });
          }
        } catch (e) {
          logger.error('Caught error trying to track UTT user conversion', { e });
        }
      }
      navigation.navigate(nextStep, {
        chargeDate: finalizeResponse.order.charged,
      });
    } catch (e) {
      funnelErrorHandler(e, 'Finalize checkout');
      if (axios.isAxiosError(e)) {
        const resData = e.response?.data;
        showCheckoutSubmitError(resData);
      }
    }
  };

  return (
    <FunnelWrapper showDiscountBanner>
      <Heading
        size="titleSmToMd"
        fontWeight="bold"
        pb={dogIsPup ? 4 : { base: 6, lg: 12 }}
        textAlign="center"
      >
        You're one step away from a healthier dog!
      </Heading>
      {dogIsPup ? (
        <Heading
          size="bodySmToMd"
          fontFamily="secondary"
          textAlign="center"
          maxW={{ base: '100%', md: '960px' }}
          pb={{ base: 6, lg: 12 }}
        >
          We're excited to be a part of your puppy's journey by simplifying their meal times with
          100% balanced, whole foods they're going to love!
        </Heading>
      ) : null}

      <View w="100%" flexDirection={{ base: 'column', lg: 'row' }} my="0" mx="auto" pb={10}>
        {cart && (
          <FormProvider {...methods}>
            <View
              px={{ base: 0, md: 6 }}
              flex={{ base: 1, md: 5 }}
              width="100%" // Forces the width to appear as expected in Safari
              flexBasis="auto"
            >
              {expressPaymentRequest ? (
                <ExpressCheckout
                  isLoading={isLoadingTaxRate || isLoading}
                  paymentRequest={expressPaymentRequest}
                />
              ) : null}
              {expressPaymentSubmitError ? (
                <Text size="bodySm" mt="4px" color="error.default">
                  {expressPaymentSubmitError}
                </Text>
              ) : null}
              <CheckoutFormShipping />
              <CheckoutFormBilling isLoading={isLoading} stripeErrorMessage={stripeErrorMessage} />
            </View>
            <Spacer size={{ base: 0, xl: 4 }} />
            <View
              flex={{ base: 1, md: 3 }}
              px={{ base: 0, md: 6 }}
              flexBasis="auto"
              width="100%" // Forces the width to appear as expected in Safari
              maxW={{ base: 'auto', lg: '550px' }}
            >
              <OrderSummary
                isLoading={isLoading}
                stripe={stripe}
                elements={elements}
                cart={cart}
                onApplyDiscount={onApplyDiscount}
                onRemoveDiscount={onRemoveDiscount}
                submitError={checkoutSubmitError}
                discountError={discountError}
                onSubmit={onSubmit}
              />
            </View>
          </FormProvider>
        )}
      </View>
    </FunnelWrapper>
  );
};
