import { useFocusEffect } from '@react-navigation/native';
import axios from 'axios';
import dayjs from 'dayjs';
import { HStack, Stack, Text, VStack } from 'native-base';
import { useCallback, useMemo, useState } from 'react';
import { Linking } from 'react-native';

import { FormSubmitButton } from '../../../FormSubmitButton';
import { CardBrandLogo } from '../../Billing/CardBrandLogo';
import { ReactivatePlanOrderSummary } from './ReactivatePlanOrderSummary';
import { ReactivatePlanPromoCard } from './ReactivatePlanPromoCard';
import { ReactivatePlanSummary } from './ReactivatePlanSummary';

import { PetGender, usePaymentMethods, useReactivate, useReactivationPreview } from '@/api';
import {
  EditableCard,
  HiddenTextDots,
  Link,
  LoadingSpinner,
  ToastType,
  displayToast,
} from '@/components/Elements';
import { LockAlertOutline } from '@/components/Icons';
import { useAccount } from '@/hooks';
import { AXIOS_TOAST_NAME } from '@/lib/axios';
import { ProtectedScreenProps } from '@/types';
import { dateFormat, defaultErrorHandler } from '@/utils';
import { sendErrorReport } from '@/utils/analytics';

const Disclaimer = ({ startDateIsToday }: { startDateIsToday: boolean }) => {
  const textProps = {
    color: 'sntGrey.primary',
    fontFamily: 'secondary',
    size: 'bodySmToMd',
    fontWeight: 700,
  };
  const linkProps = {
    href: 'https://spotandtango.com/terms-of-use',
    variant: 'inline',
    color: 'black',
    fontWeight: 'bold',
    textDecoration: 'underline',
    isExternal: true,
  };
  if (startDateIsToday) {
    return (
      <VStack space={2} justifyContent={{ base: 'left', md: 'center' }}>
        <Text {...textProps} textAlign="center">
          <LockAlertOutline size="14px" color="sntGrey.primary" mr={2} />
          All transactions are secure and encrypted through Stripe.
        </Text>
        <Text {...textProps}>
          Clicking 'Place Order and Reactivate' processes an order immediately with the above plan
          details. To modify your plan or delay the next order, please adjust the order date above
          prior to confirmation. By reactivating, you agree to Spot & Tango's{' '}
          <Link {...linkProps}>Terms of Use</Link>, and understand that this is an auto-renewing
          subscription with no long-term commitment. You may pause or cancel your plan at any time.
        </Text>
      </VStack>
    );
  }
  return (
    <Text {...textProps}>
      Clicking 'Confirm Reactivation' processes an order on the date you have selected above. You
      will not be charged until that date. By reactivating your dog's plan, you agree to Spot &
      Tango's <Link {...linkProps}>Terms of Use</Link>, and understand that this is an auto-renewing
      subscription with no long-term commitment. You may pause or cancel your plan at any time.
    </Text>
  );
};

export const ReactivatePlan = ({ navigation, route }: ProtectedScreenProps<'ReactivatePlan'>) => {
  const account = useAccount();
  const primaryAddress = account.addresses.find((addr) => addr.is_primary) || account.addresses[0];
  const { data: paymentMethods } = usePaymentMethods();
  const stripeCard = paymentMethods?.length
    ? paymentMethods.find((method) => method.is_default)?.card
    : undefined;
  const { mutateAsync: reactivate, isLoading } = useReactivate({ noErrorToast: true });
  const petId = route.params?.petId;

  const pets = account?.pets.filter((pet) => pet.status !== 'ACTIVE');
  const pet = pets?.find((pet) => pet.id === route.params?.petId);
  const orderDate = route.params?.date || new Date();
  const [discountCode, setDiscountCode] = useState<string>('');

  const {
    data: reactivationPreview,
    isSuccess,
    refetch,
  } = useReactivationPreview({
    petPlanId: pet?.pet_plan.id,
    discountCode,
  });

  useMemo(() => {
    if (discountCode !== '' && reactivationPreview) {
      if (reactivationPreview?.errors?.INVALID_DISCOUNT_CODE === undefined) {
        displayToast({
          message: `${discountCode} promo code has been applied to your order.`,
          type: ToastType.Success,
        });
      }
      if (reactivationPreview?.errors?.INVALID_DISCOUNT_CODE !== undefined) {
        displayToast({
          message: `${discountCode} promo code is invalid.`,
          type: ToastType.Error,
        });
      }
    }
  }, [discountCode, reactivationPreview]);

  // When screen is Focussed, refetching reactivationPreview data
  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch])
  );

  const startDate = route.params?.date || new Date();
  const startDateIsToday = startDate.toDateString() === new Date().toDateString();
  const CTAtext = startDateIsToday ? 'PLACE ORDER AND REACTIVATE' : 'CONFIRM REACTIVATION';

  if (!(pet && stripeCard && isSuccess)) {
    return <LoadingSpinner accessLabel="Reactivate Pet Page" />;
  }

  const previewOrder = {
    products: pet.pet_plan.products.map((product) => ({
      name: product.recipes.map((recipe) => recipe.name).join(', '),
      type: product.recipes[0].type,
      price: reactivationPreview.products.find(({ product_code }) => product_code === product.code)
        ?.unit_price as string,
    })),
    total_price: reactivationPreview.total.total_price,
    total_discounts: reactivationPreview.total.total_discounts,
    total_tax: reactivationPreview.total.total_tax,
    discount_codes: reactivationPreview.discount_codes,
  };

  const onSubmit = async () => {
    try {
      await reactivate({
        petId: pet.id,
        startDate: dayjs(startDate).local().format('YYYY-MM-DD'),
        discountCodes: reactivationPreview.discount_codes,
      });
      navigation.navigate('Account');
      displayToast({ message: `${pet.name}'s plan is now active.`, type: ToastType.Success });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const msg_start = error.response?.data.split(':')[0];
        if (msg_start === 'Payment Failed') {
          navigation.navigate('Home');
          const pronoun = pet?.gender === PetGender.MALE ? 'his' : 'her';
          displayToast({
            message: `${pet.name}'s plan is now active, but there was an issue with your payment method. Please add a new payment method so we can send ${pet.name} ${pronoun} next order!`,
            type: ToastType.Error,
            duration: 9000,
            id: AXIOS_TOAST_NAME,
          });
        } else if (msg_start === 'DiscountValidationError') {
          displayToast({
            onPress: () =>
              Linking.openURL(
                'https://spotandtango.com/faq?a=How-do-I-contact-customer-support---id--IKoHhdLiR3SYrMUbeS90EQ'
              ),
            // String message used in Toast accessibility message
            message:
              "The promo code you've entered is invalid. Please try again or contact us for help.",
            styledMessage: (
              <>
                The promo code you've entered is invalid. Please try again or{' '}
                <Text textDecorationLine="underline" fontWeight={900}>
                  contact us
                </Text>{' '}
                for help.
              </>
            ),
            type: ToastType.Error,
            duration: 9000,
            id: AXIOS_TOAST_NAME,
          });
        } else {
          defaultErrorHandler();
          sendErrorReport(error);
        }
      } else {
        defaultErrorHandler();
        sendErrorReport(error);
      }
    }
  };

  return (
    <Stack w="100%" h="100%" px={4} alignItems="center">
      <Stack h="100%" alignItems="center" maxW={{ base: '100%', lg: '850px' }} w="100%">
        <Stack space={6} pb="128px" w="100%">
          <Text
            size="bodySmToMd"
            textAlign="center"
            fontWeight="medium"
            fontFamily="secondary"
            mb={{ base: 1, lg: '28px' }}
          >
            Please review {pet.name}’s plan and confirm that you would like to reactivate it.
          </Text>
          <EditableCard
            w="100%"
            heading="Order Date"
            onPress={() =>
              navigation.navigate('ReactivatePlanRescheduleDelivery', {
                petId: pet.id,
                date: orderDate,
              })
            }
          >
            <Stack flexDirection="row" alignSelf="center">
              <Text size="bodyMdToLg" fontWeight="medium">
                {dateFormat(orderDate, 'long')}
              </Text>
            </Stack>
          </EditableCard>
          <Stack w="100%" justifyContent="center" alignItems="center" space={{ base: 4, lg: 6 }}>
            <ReactivatePlanSummary pet={pet} orderDate={orderDate} />
            <Stack
              direction={{ base: 'column', lg: 'row' }}
              space={{ base: 4, lg: 6 }}
              justifyContent="center"
              alignItems="center"
              w="100%"
            >
              <EditableCard
                h={{ lg: '100%' }}
                flex={{ lg: 1 }}
                heading="Shipping"
                onPress={() =>
                  navigation.navigate('AddressList', {
                    successRedirect: 'ReactivatePlan',
                    successRedirectParams: { petId, date: orderDate },
                  })
                }
                stackProps={{
                  direction: { base: 'row', lg: 'column' },
                  space: { base: 0, lg: 4 },
                }}
              >
                <Stack
                  alignItems={{ base: 'flex-end', lg: 'flex-start' }}
                  space={{ base: 0, lg: 2 }}
                  pr={2}
                >
                  <Text size="bodyMdToLg" fontWeight="medium">
                    {primaryAddress.address1}
                  </Text>
                  {primaryAddress.address2 ? (
                    <Text size="bodyMdToLg" fontWeight="medium">
                      {primaryAddress.address2}
                    </Text>
                  ) : null}
                  <Text size="bodyMdToLg" fontWeight="medium">
                    {primaryAddress.city},{' '}
                    {primaryAddress?.state ? primaryAddress.state.toUpperCase() : ''}{' '}
                    {primaryAddress.zip}
                  </Text>
                </Stack>
              </EditableCard>
              <EditableCard
                h={{ lg: '100%' }}
                heading="Billing"
                flex={{ lg: 1 }}
                stackProps={{
                  direction: { base: 'row', lg: 'column' },
                  space: { base: 0, lg: 4 },
                }}
                onPress={() =>
                  navigation.navigate('PaymentMethodList', {
                    successRedirect: 'ReactivatePlan',
                    successRedirectParams: { petId, date: orderDate },
                  })
                }
              >
                <HStack alignItems="center" space={{ base: 1, lg: 2 }} pr={2}>
                  <CardBrandLogo brand={stripeCard.brand} />
                  <HStack display={{ base: 'none', lg: 'flex' }}>
                    <HiddenTextDots />
                  </HStack>
                  <Text size="bodyMdToLg" fontWeight="medium">
                    {stripeCard.last4}
                  </Text>
                </HStack>
              </EditableCard>
            </Stack>
            <ReactivatePlanPromoCard
              discountCodes={previewOrder.discount_codes}
              setDiscountCode={setDiscountCode}
            />
            <ReactivatePlanOrderSummary pet={pet} previewOrder={previewOrder} />
          </Stack>
          <FormSubmitButton alignSelf="center" onPress={onSubmit} isDisabled={isLoading}>
            {CTAtext}
          </FormSubmitButton>
          <Disclaimer startDateIsToday={startDateIsToday} />
        </Stack>
      </Stack>
    </Stack>
  );
};
