import { RecipeType } from '@/api/types';

export const MAX_SNACKS_PER_PLAN = 4;
export const MAX_SUPPLEMENTS_PER_PLAN = 6;

export type AddonsType = RecipeType.SNACK | RecipeType.SUPPLEMENT;

export interface AddonConfig {
  title: string;
  subtitle: { [key: string]: string };
  type: RecipeType;
  maxAddons: number;
  textForDissabledMessage: string;
  unit: 'bag' | 'pack';
  description: DescriptionForAddons;
}

interface DescriptionForAddons {
  forCarousel: 'Snack' | 'Supplement';
  forShopModal: {
    [key: string]: string;
  };
}

/**
 * Some of this should be moved to the BE at some point
 */
export const ADDONS_CONFIGURATION: Record<AddonsType, AddonConfig> = {
  [RecipeType.SUPPLEMENT]: {
    title: 'Supplements',
    subtitle: {},
    type: RecipeType.SUPPLEMENT,
    maxAddons: MAX_SUPPLEMENTS_PER_PLAN,
    textForDissabledMessage: 'packs of Supplements',
    unit: 'pack',
    description: {
      forCarousel: 'Supplement',
      forShopModal: {
        'SUPP-AI': 'Promotes healthy skin and a strong immune response',
        'SUPP-C': 'Helps regulate cortisol for a calm disposition',
        'SUPP-HJ': 'Supports joint mobility in dogs of all ages',
      },
    },
  },
  [RecipeType.SNACK]: {
    title: 'Snacks',
    subtitle: {
      'SN-CHICKENBITES': '2.5 oz per bag; approx. 23 treats',
      'SN-SPBITES': '3 oz per bag; approx. 28 treats',
      'SN-BISONBITES': '2.5 oz per bag; approx. 85 treats',
      'SN-BEEFTRAINERS': '2.5 oz per bag; approx. 250 treats',
    },
    type: RecipeType.SNACK,
    maxAddons: MAX_SNACKS_PER_PLAN,
    textForDissabledMessage: 'bags of Snacks',
    unit: 'bag',
    description: {
      forCarousel: 'Snack',
      forShopModal: {
        'SN-BISONBITES': '100% Bison Kidney',
        'SN-CHICKENBITES': '100% Chicken Breast',
        'SN-SPBITES': '100% Sweet Potato',
        'SN-BEEFTRAINERS': '100% Beef Liver',
      },
    },
  },
};

export const ADDONS_FREE_FOR_LIFE_CODES = [
  'SNACK-SPBITES-1PK-R-FREE',
  'SNACK-BISONBITES-1PK-R-FREE',
  'SNACK-CHICKENBITES-1PK-R-FREE',
];

export const PORTAL_NEW_BADGE_RECIPE_IDS = ['SN-BEEFTRAINERS'];

export const LINK_TO_WEB1 = 'https://app.spotandtango.com/login';
export const LINK_TO_WEB2 = 'https://shop.spotandtango.com/account/login';
export const ASSETS_URL = 'https://assets.spotandtango.com';
export const WTP_URL = 'https://whatthepup.spotandtango.com/';
export const SNT_HOMEPAGE_URL = 'https://spotandtango.com';
export const HOW_TO_CONTACT_SUPPORT_URL =
  'https://spotandtango.com/faq?a=How-do-I-contact-customer-support---id--IKoHhdLiR3SYrMUbeS90EQ';
export const FRIENDBUY_MERCHANT_ID = '19dddc88-1f0b-4624-9952-32b86c5756cf';
