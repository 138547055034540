import { Divider, IconButton, Pressable, Slider, Stack, Text } from 'native-base';
import { useEffect, useState } from 'react';
import { PlusCircleIcon, MinusCircleIcon } from 'react-native-heroicons/solid';

import { FormSubmitButton } from '../../FormSubmitButton';
import { useTopperChoiceModal } from './useTopperChoiceModal';

import { usePlanSizesForRecipes, useUpdatePetPlan } from '@/api';
import { BuildPlan, PetGender } from '@/api/types';
import { LoadingSpinner, displayToast } from '@/components/Elements';
import { useDogModal } from '@/hooks';
import { useAccount } from '@/hooks/useAuth';
import { contactUs } from '@/utils';
import { sendErrorReport } from '@/utils/analytics';

export const AdjustPortionSize = ({ navigation, route }: any) => {
  const account = useAccount();

  const { petId, successRedirect, successRedirectParams } = route.params;
  const { pets } = account;
  const pet = pets.find((pet) => pet.id === petId);
  const currentIsTopper = pet?.pet_plan.is_topper || false;

  const [selectedPlanSizeIndex, setSelectedPlanSizeIndex] = useState(0);
  const { Modal: TopperChoiceModal, setShowModal: setShowTopperConfirmModal } =
    useTopperChoiceModal();

  const { mutateAsync: updatePetPlan, isLoading: isLoadingUpdatePetPlan } = useUpdatePetPlan();
  const { Modal: UpdateDogModal, setShowModal: showUpdateDogModal } = useDogModal('update');

  const pronoun = pet?.gender === PetGender.MALE ? 'his' : 'her';

  const { data: planSizesData, isSuccess: isSuccessPlanSizes } = usePlanSizesForRecipes({
    recipes: pet?.pet_plan.recipe_data.map((recipe) => recipe.id) || [],
    account_id: account.id,
  });

  const planSizes: BuildPlan[] = planSizesData ? Object.values(planSizesData) : [];
  const selectedPetPlan = planSizes[selectedPlanSizeIndex];

  useEffect(() => {
    navigation.setOptions({ title: `Adjust ${pet?.name}'s Portion Size` });
  }, [account]);

  useEffect(() => {
    if (pet && isSuccessPlanSizes) {
      setSelectedPlanSizeIndex(
        planSizes.findIndex(({ plan_size }) => plan_size === pet.pet_plan.plan_size)
      );
    }
  }, [pet, isSuccessPlanSizes]);

  const onPressChangePlanSize = (n: 1 | -1) => {
    if (selectedPlanSizeIndex + n >= 0 && selectedPlanSizeIndex + n < planSizes.length) {
      setSelectedPlanSizeIndex(selectedPlanSizeIndex + n);
    }
  };

  if (!(pet && selectedPetPlan)) {
    return <LoadingSpinner accessLabel="Adjust Portion Size Page" />;
  }

  const onSubmit = () => {
    if (selectedPetPlan.plan_size < pet.pet_plan.plan_size && !currentIsTopper) {
      setShowTopperConfirmModal(true);
    } else if (selectedPetPlan.plan_size > pet.pet_plan.plan_size && currentIsTopper) {
      setShowTopperConfirmModal(true);
    } else {
      onConfirmPortion();
    }
  };

  const onConfirmIntentToTopper = (intendsToKeepToperChoice: boolean) => {
    setShowTopperConfirmModal(false);
    onConfirmPortion(intendsToKeepToperChoice);
  };

  const onConfirmPortion = async (intentToTopper?: boolean) => {
    try {
      await updatePetPlan({
        petPlanId: pet.pet_plan.id,
        data: {
          plan_size: planSizes[selectedPlanSizeIndex].plan_size,
          is_topper: intentToTopper,
        },
      });
      if (successRedirect) {
        navigation.navigate(successRedirect, successRedirectParams || {});
      } else {
        navigation.navigate('Account');
      }
      displayToast({
        message: `${pet?.name}'s meal plan has been updated.`,
      });
    } catch (error) {
      sendErrorReport(error);
    }
  };

  const ResponsiveIcon = ({ icon }: { icon: 'minus' | 'plus' }) => {
    return (
      <Stack>
        <IconButton
          p="0px"
          display={{ base: 'flex', md: 'none' }}
          onPress={() => onPressChangePlanSize(icon === 'minus' ? -1 : 1)}
          icon={
            icon === 'minus' ? (
              <MinusCircleIcon color="blueBayoux.primary" size="20px" />
            ) : (
              <PlusCircleIcon color="blueBayoux.primary" size="20px" />
            )
          }
        />
        <IconButton
          p="0px"
          display={{ base: 'none', md: 'flex' }}
          onPress={() => onPressChangePlanSize(icon === 'minus' ? -1 : 1)}
          icon={
            icon === 'minus' ? (
              <MinusCircleIcon color="blueBayoux.primary" size="30px" />
            ) : (
              <PlusCircleIcon color="blueBayoux.primary" size="30px" />
            )
          }
        />
      </Stack>
    );
  };

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      alignSelf="center"
      h="100%"
      w={{ base: '100%', md: '540px' }}
      px={{ base: '16px', md: '0px' }}
      pb="24px"
      space={{ base: 0, md: '24px' }}
    >
      <Stack space={{ base: '16px', lg: '24px' }}>
        <Text
          color="black"
          fontWeight="medium"
          size="bodySmToMd"
          textAlign="center"
          mb={{ base: '8px', md: '16px' }}
        >
          If you'd like to feed more or less food, you can adjust daily portion size below.
        </Text>
        <Stack variant="card" alignItems="center" space={{ base: '16px', md: '24px' }}>
          <Stack>
            <Text
              size="bodyMdToLg"
              textAlign="center"
              fontWeight="bold"
              mb={{ base: '8px', md: '16px' }}
            >
              {pet.name}'s Daily Portions
            </Text>
            <Text
              variant="helperText"
              size="bodySmToMd"
              color="sntGrey.tertiary"
              fontWeight="medium"
              textAlign="center"
            >
              Currently Feeding <Text fontWeight="bold">{pet.pet_plan.plan_size} calories</Text>
            </Text>
            <Text
              variant="helperText"
              size="bodySmToMd"
              color="sntGrey.tertiary"
              fontWeight="medium"
              textAlign="center"
            >
              {pet.pet_plan.is_topper ? 'Mixing S&T with other food' : 'Feeding 100% S&T'}
            </Text>
          </Stack>
          <Divider />
          <Stack direction="row" alignItems="center" space={{ base: '16px', md: '24px' }}>
            <ResponsiveIcon icon="minus" />
            <Stack
              direction="row"
              space={{ base: '4px', md: '8px' }}
              alignItems="center"
              justifyContent="cneter"
            >
              <Text
                fontSize={{ base: 'title.sm', md: 'largeTitle' }}
                lineHeight={{ base: 'title.sm', md: 'largeTitle' }}
                fontWeight="bold"
              >
                {selectedPetPlan?.calories_per_day}
              </Text>
              <Text fontWeight="medium" fontFamily="primary" size="bodyMlToTitleSm">
                calories
              </Text>
            </Stack>
            <ResponsiveIcon icon="plus" />
          </Stack>
          <Slider
            value={selectedPlanSizeIndex}
            minValue={0}
            maxValue={planSizes?.length - 1}
            onChange={(value) => setSelectedPlanSizeIndex(value)}
            accessibilityLabel="calories"
          >
            <Slider.Track>
              <Slider.FilledTrack />
            </Slider.Track>
            <Slider.Thumb />
          </Slider>
          <Text fontWeight="medium" size="bodySmToMd">
            ${selectedPetPlan?.subscription.price.price_per_week}/wk
          </Text>
        </Stack>
        <Text size="bodySmToMd" color="grey.dark" fontWeight="500">
          Has anything about {pet.name} changed?{' '}
          <Pressable
            onPress={() => {
              showUpdateDogModal(true);
            }}
          >
            <Text fontWeight="semibold" textDecorationLine="underline">
              Update {pronoun} profile
            </Text>
          </Pressable>{' '}
          to see if we'd recommend changing {pronoun} portions.
        </Text>
      </Stack>
      <Stack variant="stickyFooter" px={4} position="fixed" paddingBottom="0">
        <Text fontWeight="medium" size="bodySmToMd" color="sntGrey.primary">
          Need help determining your dog's perfect portions?{' '}
          <Pressable
            onPress={contactUs}
            textDecorationLine="underline"
            fontWeight={700}
            color="black"
            size={{ base: 'bodySm' }}
          >
            <Text size="bodySmToMd">Contact us</Text>
          </Pressable>{' '}
          for personal advice — we'll make sure their daily portions are just right!
        </Text>
        <FormSubmitButton
          onPress={onSubmit}
          isDisabled={
            isLoadingUpdatePetPlan || selectedPetPlan.plan_size === pet.pet_plan.plan_size
          }
          mt="20px"
          mb="20px"
        >
          Continue
        </FormSubmitButton>
      </Stack>
      <TopperChoiceModal
        decreaseOrIncrease={
          selectedPetPlan.plan_size < pet.pet_plan.plan_size ? 'decrease' : 'increase'
        }
        petName={pet.name}
        onConfirm={onConfirmIntentToTopper}
        onClose={() => setShowTopperConfirmModal(false)}
      />
      <UpdateDogModal />
    </Stack>
  );
};
