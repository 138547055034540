import { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { Cart } from '@/api';

interface ItemToTrack {
  category: string;
  name: string;
  quantity: number;
  sku: string;
  subTotal: number;
}

const UTT_SCRIPT_URL = 'https://utt.impactcdn.com/A1398952-593f-4474-b9c5-d23033b0e6121.js';

const getDiscountCodeAndItemsFromCart = (cart: Cart) => {
  const items: ItemToTrack[] = [];
  const discountCode = cart?.plans[0].discounts[0].code;
  cart?.plans.map(({ plan, snacks, supplements }) => {
    const price = plan.trial.price.discounted_price || plan.trial.price.price;
    const productType = plan.subscription.product.type;
    const { sku, name } = plan.trial.product.items[0];
    items.push({
      category: productType || 'PetFood',
      name,
      quantity: 1,
      sku,
      subTotal: +price,
    });
    [...snacks, ...supplements].map((addon) => {
      const price = addon.price.toString();
      const productType = plan.subscription.product.type;
      const { sku, name } = addon.product.items[0];
      items.push({
        category: productType || 'PetFood',
        name,
        quantity: 1,
        sku,
        subTotal: +price,
      });
    });
  });
  return {
    discountCode,
    items,
  };
};

export const useUTT = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (Platform.OS === 'web' && !window.ire) {
      const alias = 'ire';
      const currWindow = window as any;
      currWindow['ire_o'] = alias;
      currWindow[alias] =
        currWindow[alias] ||
        function () {
          currWindow[alias].a = currWindow[alias].a || [];
        };
      const uttScript = document.createElement('script');
      const scriptElements = document.getElementsByTagName('script')[0];
      uttScript.async = true;
      uttScript.src = UTT_SCRIPT_URL;
      scriptElements?.parentNode?.insertBefore(uttScript, scriptElements);
      uttScript.addEventListener('load', () => {
        setScriptLoaded(true);
      });
    } else if (window.ire) {
      setScriptLoaded(true);
    }
  }, []);

  return {
    UTTidentify: (customerId: string, customerEmail: string, params?: any) => {
      if (scriptLoaded && window?.ire) {
        window.ire('identify', {
          customerId,
          customerEmail,
          ...params,
        });
      }
    },
    trackImpactConversion: (
      orderId: string,
      customerId: string,
      customerEmail: string,
      cart: Cart
    ) => {
      if (scriptLoaded && window?.ire) {
        const { discountCode, items } = getDiscountCodeAndItemsFromCart(cart);
        window.ire(
          'trackConversion',
          17274,
          {
            orderId,
            customerId,
            customerEmail,
            currencyCode: 'USD',
            orderPromoCode: discountCode,
            items,
          },
          {
            verifySiteDefinitionMatch: true,
          }
        );
      }
    },
  };
};
