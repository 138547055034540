import { HStack, Text, useBreakpointValue } from 'native-base';

import { DiscountLimitType, DiscountType } from '@/api';
import { useSessionQuery } from '@/hooks';

export const DiscountBanner = () => {
  const { session } = useSessionQuery();
  const fontWeight = useBreakpointValue({ base: 'bold', lg: 'medium' });

  if (!session?.discounts || session?.discounts.length === 0) {
    return null;
  }

  const discount = session.discounts[0]; // only one discount per session is supported
  const trialValue =
    discount.limit_type === DiscountLimitType.X_ORDERS_PER_CUSTOMER_SUBSCRIPTION_TRIAL_SPLIT
      ? discount.trial_value
      : discount.value;
  const trialValueText = Number(trialValue).toFixed(0);

  let discountText = 'Your discount has been applied!'; // fallback in case a discount doesn't fit below cases
  if (discount.discount_type === DiscountType.PERCENTAGE_DISCOUNT) {
    discountText = `Your ${trialValueText}% discount has been applied!`;
  } else if (discount.discount_type === DiscountType.AMOUNT_DISCOUNT) {
    discountText = `Your $${trialValueText} discount has been applied!`;
  }

  if (
    discount.discount_type === DiscountType.PERCENTAGE_DISCOUNT &&
    discount.limit_type === DiscountLimitType.X_ORDERS_PER_CUSTOMER_SUBSCRIPTION_TRIAL_SPLIT &&
    discount.value &&
    discount.limit_value
  ) {
    const subscriptionValueText = Number(discount.value).toFixed(0);
    const orderLanguage = discount.limit_value === 1 ? 'order' : `${discount.limit_value} orders`;
    discountText = `${trialValueText}% off trial order + ${subscriptionValueText}% off next ${orderLanguage} applied!`;
  }

  return (
    <HStack w="100%" py={4} mt={4} alignItems="center" justifyContent="center" bg="sntBlue.primary">
      <Text size="bodySmToMd" fontWeight={fontWeight}>
        {discountText}
      </Text>
    </HStack>
  );
};
