import { AccountStack } from './AccountStack';

import { PortalHead } from '@/components/Elements';

export const PortalScreens = () => {
  return (
    <>
      <PortalHead />
      <AccountStack />
    </>
  );
};
