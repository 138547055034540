import { Divider, Heading, IStackProps, Stack } from 'native-base';

import { LineItemPlanCardAddons } from './LineItemPlanCardAddons';
import { LineItemPlanCardExtra } from './LineItemPlanCardExtra';
import { LineItemPlanCardHeading } from './LineItemPlanCardHeading';
import { LineItemPlanCardMeal } from './LineItemPlanCardMeal';
import { LineItemPlanCardSnackOrSupplement } from './LineItemPlanCardSnackOrSupplement';

import { OrderProduct, Pet, PetStatus } from '@/api/types/accountServices';
import { addonSeqNoCompare, extrasOrderProductCompare } from '@/utils';

export interface LineItemPlanCardProps extends IStackProps {
  content: {
    meals: OrderProduct[];
    snacks: OrderProduct[];
    supplements: OrderProduct[];
    extras: OrderProduct[]; // for materials, like scoops and clips
    addons: OrderProduct[];
    dog: Pet;
    orderDate: string;
    orderEditable: boolean;
    orderWasCharged: boolean;
    frequency?: number;
    planStatus?: PetStatus;
  };
  disabled?: boolean;
  onDecAddon?: (orderProduct: OrderProduct) => void;
  onIncAddon?: (orderProduct: OrderProduct) => void;
}

export const LineItemPlanCard = ({
  content,
  disabled,
  onDecAddon,
  onIncAddon,
  ...props
}: LineItemPlanCardProps) => (
  <Stack w="100%" {...props}>
    <Stack p={{ base: 4, lg: 6 }} borderWidth={1} borderColor="sntGrey.outline" w="100%" space={4}>
      <LineItemPlanCardHeading
        dog={content?.dog}
        frequency={content.frequency}
        planStatus={content.planStatus}
        orderEditable={content.orderEditable}
        disabled={disabled}
      />

      <Stack space={{ base: 4, lg: 6 }}>
        {content.meals.length > 0 && (
          <>
            <Divider bg="gallery" />
            <LineItemPlanCardMeal
              meals={content.meals}
              frequency={content.frequency}
              disabled={disabled}
            />
          </>
        )}

        {content.snacks.length ? (
          <Stack space={{ base: 4, lg: 6 }}>
            <Divider bgColor="gallery" />
            <Stack space={{ base: 4, lg: 6 }}>
              <Heading
                size="bodyMdToLg"
                fontWeight="bold"
                color={disabled ? 'sntGrey.primary' : undefined}
              >
                Snacks
              </Heading>
              {content.snacks.sort(addonSeqNoCompare).map((snack, i, arr) => (
                <LineItemPlanCardSnackOrSupplement
                  product={snack}
                  key={snack.id}
                  disabled={disabled}
                  showDivider={i < arr.length - 1}
                />
              ))}
            </Stack>
          </Stack>
        ) : null}
        {content.supplements.length ? (
          <Stack space={{ base: 4, lg: 6 }}>
            <Divider bgColor="gallery" />
            <Stack space={{ base: 4, lg: 6 }}>
              <Heading
                size="bodyMdToLg"
                fontWeight="bold"
                color={disabled ? 'sntGrey.primary' : undefined}
              >
                Supplements
              </Heading>
              {content.supplements.sort(addonSeqNoCompare).map((supplement, i, arr) => (
                <LineItemPlanCardSnackOrSupplement
                  product={supplement}
                  key={supplement.id}
                  disabled={disabled}
                  showDivider={i < arr.length - 1}
                />
              ))}
            </Stack>
          </Stack>
        ) : null}
        {content.extras.length > 0 ? (
          <Stack space={{ base: 4, lg: 6 }}>
            <Divider bgColor="gallery" />
            <Stack space={{ base: 4, lg: 6 }}>
              <Heading
                size="bodyMdToLg"
                fontWeight="bold"
                color={disabled ? 'sntGrey.primary' : undefined}
              >
                Extras
              </Heading>
              {content.extras.sort(extrasOrderProductCompare).map((extra, i, arr) => (
                <LineItemPlanCardExtra
                  product={extra}
                  key={extra.id}
                  disabled={disabled}
                  showDivider={i < arr.length - 1}
                />
              ))}
            </Stack>
          </Stack>
        ) : null}
      </Stack>
    </Stack>

    {content.addons?.length > 0 && (
      <>
        <Divider bg="sntGrey.outline" orientation="vertical" alignSelf="center" h={4} />
        <LineItemPlanCardAddons
          onDecAddon={onDecAddon}
          onIncAddon={onIncAddon}
          content={content}
          disabled={disabled}
        />
      </>
    )}
  </Stack>
);
