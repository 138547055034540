import { Stripe, StripeElements } from '@stripe/stripe-js';
import {
  Divider,
  Stack,
  View,
  Button,
  Text,
  Heading,
  VStack,
  Badge,
  useBreakpointValue,
} from 'native-base';
import { useFormContext } from 'react-hook-form';

import { CheckoutDetails } from '../../Checkout';
import { Extras } from '../Extras';
import { Total } from '../Total';
import { SubscriptionMealPlan, SubscriptionAddonPlan, CheckoutFormImage } from '../subcomponents';
import { OrderSummaryExplainer } from './OrderSummaryExplainer';

import { Cart } from '@/api';
import { Link } from '@/components/Elements';

export interface OrderSummaryProps {
  isLoading: boolean;
  stripe: Stripe;
  elements: StripeElements;
  cart: Cart['cart_preview'];
  onRemoveDiscount: (discountCode: string) => Promise<void>;
  onApplyDiscount: (discountCode: string) => Promise<void>;
  submitError?: string;
  discountError?: string;
  onSubmit: (data: CheckoutDetails) => Promise<void>;
}

export const OrderSummary = ({
  isLoading,
  stripe,
  elements,
  cart,
  onRemoveDiscount,
  onApplyDiscount,
  submitError,
  discountError,
  onSubmit,
}: OrderSummaryProps) => {
  const methods = useFormContext<CheckoutDetails>();
  const termsFontWeight = useBreakpointValue({ base: 'book', lg: 'medium' });

  return (
    <>
      <View
        bgColor="white"
        borderColor="sntBlue.primary"
        borderWidth={{ base: '1px', lg: '3px' }}
        p={{ base: 4, md: 6 }}
        mb={{ base: 4, lg: 0 }}
      >
        <Stack pb={4} space={{ base: 2, lg: 4 }}>
          <Heading fontWeight="bold" size="bodyMlToTitleSm">
            Order Summary
          </Heading>
          <View display="inline-block">
            <Text size="bodySmToMd" fontWeight="medium" fontFamily="secondary">
              Your two-week trial of Spot & Tango will be shipped in the next{' '}
            </Text>
            <Text fontFamily="secondary" size="bodySmToMd" fontWeight="bold">
              1-3 business days.
            </Text>
          </View>
          <OrderSummaryExplainer cart={cart} />
        </Stack>

        <Divider bg="sntGrey.outline" />
        {cart.plans.map(({ pet_name, discounts, plan, snacks, supplements }, index) => (
          <View key={index} mt={{ base: 1, lg: 2 }}>
            <Heading size="bodyMdToLg" py={3} textAlign="center" fontWeight="bold">
              {pet_name}'s Trial
            </Heading>

            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
              <Heading size="bodyMdToLg" fontWeight="bold">
                Two weeks of food
              </Heading>
              {plan?.trial?.price?.discounted_price &&
              plan?.trial?.price?.discounted_price !== plan?.trial?.price?.price ? (
                <Badge variant="smallInfoPillSecondary" p={2}>
                  {`${Number(discounts[0]?.trial_value || discounts[0]?.value).toFixed(0)}% Off`}
                </Badge>
              ) : null}
            </Stack>

            <SubscriptionMealPlan plan={plan} />

            <Divider bg="sntGrey.outline" />

            {snacks?.length > 0 ? (
              <SubscriptionAddonPlan
                header="Snacks"
                items={snacks}
                discount_value={discounts[0]?.trial_value || discounts[0]?.value}
              />
            ) : null}
            {supplements?.length > 0 ? (
              <SubscriptionAddonPlan
                header="Supplements"
                items={supplements}
                discount_value={discounts[0]?.trial_value || discounts[0]?.value}
              />
            ) : null}
            <Divider bg="sntGrey.outline" />
            <Extras recipeType={plan.recipe_type} />
            <Divider bg="sntGrey.outline" />
          </View>
        ))}

        {cart && (
          <Total
            isLoading={isLoading}
            onRemoveDiscount={onRemoveDiscount}
            onApplyDiscount={onApplyDiscount}
            discountError={discountError}
          />
        )}
      </View>
      <Stack mb={4} space={2}>
        <Button
          onPress={methods.handleSubmit(onSubmit)}
          isDisabled={!stripe || !elements}
          w="100%"
          isLoading={isLoading}
          borderColor={{ lg: 'sntBlue.primary' }}
          _hover={{ bg: { lg: 'sntBlue.primary' } }}
          _pressed={{ bg: { lg: 'sntBlue.primary' } }}
          _text={{ letterSpacing: { lg: '3px' } }}
          _loading={{ opacity: { lg: 1 } }}
        >
          START MY TRIAL
        </Button>
        {submitError ? (
          <Text size="bodySm" mt="4px" color="error.default">
            {submitError}
          </Text>
        ) : null}
      </Stack>
      <Divider bg="gallery" mt={2} mb={4} display={{ lg: 'none' }} />
      <VStack alignItems="center">
        <Stack
          flexDirection={{ base: 'row', lg: 'column' }}
          justifyContent={{ base: 'space-between', lg: 'center' }}
          px={{ base: 6, lg: 0 }}
          mb={4}
          space={3}
        >
          <CheckoutFormImage source="refundable">Refundable Trial</CheckoutFormImage>
          <CheckoutFormImage source="shipping">Free Shipping</CheckoutFormImage>
          <CheckoutFormImage source="happy-pup">Happy Pup Guarantee</CheckoutFormImage>
        </Stack>
      </VStack>
      <Divider bg="sntGrey.outline" mb={4} />
      <Stack justifyContent="center" pb={{ base: 20, md: 0 }}>
        <Text
          size="bodySmToMd"
          fontFamily="secondary"
          fontWeight={termsFontWeight}
          color="sntGrey.primary"
          textAlign="justify"
        >
          By continuing with payment, I agree to Spot & Tango's{' '}
          <Link
            href="https://spotandtango.com/terms-of-use"
            variant="inline"
            color="black"
            textDecoration="underline"
            fontWeight="bold"
            isExternal
          >
            Terms of Use
          </Link>{' '}
          and understand that this is an auto-renewing subscription with no long-term commitment.
          You may pause or cancel your meal plan at any time.
        </Text>
      </Stack>
    </>
  );
};
