import { useIsFocused } from '@react-navigation/native';
import {
  Heading,
  Button,
  VStack,
  HStack,
  Stack,
  Badge,
  useBreakpointValue,
  IStackProps,
  Box,
} from 'native-base';
import React, { useEffect, useState } from 'react';

import { useRecipes, RecipeFull } from '@/api';
import { PDPModal, RecipeCard } from '@/components/Elements';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useAxiosStatusContext } from '@/context';
import { useFunnelErrorHandler, useNextFunnelStep, useSessionQuery } from '@/hooks';
import { FunnelScreenProps } from '@/types';
import { isPup, round } from '@/utils';
import { getCurrentPet } from '@/utils/getCurrentPet';

const Snacks = ({ navigation }: FunnelScreenProps<'Snacks'>) => {
  const nextStep = useNextFunnelStep(FunnelScreenNames.SNACKS);
  const { session: funnelSession, mutateUpdatePetAnswer } = useSessionQuery();
  const funnelErrorHandler = useFunnelErrorHandler();
  const { addAxiosPromise } = useAxiosStatusContext();
  const [snacks, setSnacks] = useState<string[]>([]);
  const [showPDPModal, setShowPDPModal] = useState(false);
  const [detailSnack, setDetailSnack] = useState<RecipeFull>();
  const dog = getCurrentPet(funnelSession);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dogIsPup = isPup(dog?.birth_year, dog?.birth_month);
  const stickyFooterStyle: IStackProps['style'] = useBreakpointValue({
    '2xl': { boxShadow: 'none' },
  });

  const discount = funnelSession?.discounts ? funnelSession?.discounts[0] : null;

  const onSubmit = (snacks: string[]) => {
    setIsSubmitting(true);
    try {
      addAxiosPromise(
        mutateUpdatePetAnswer({
          snacks,
        })
      );
      navigation.navigate(nextStep);
      setIsSubmitting(false);
    } catch (error) {
      funnelErrorHandler(error, 'Update Snacks');
    }
  };

  const { data: allSnacks, isLoading: isLoadingSnacks } = useRecipes({
    recipeType: 'snacks',
    discount: discount?.code,
    isTrial: true,
    sessionId: funnelSession?.id,
  });

  const isDiscountApplied = allSnacks
    ? allSnacks.every((snack) => snack.discounted_price !== null)
    : false;

  const updateSnacks = (id: string) => {
    if (snacks.some((selected) => selected === id)) {
      setSnacks(snacks.filter((selected) => selected !== id));
    } else {
      setSnacks(snacks.concat([id]));
    }
  };

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused && dog?.snacks) {
      setSnacks(dog.snacks);
    }
  }, [dog?.snacks, isFocused]);

  if (!(allSnacks && dog)) {
    return null;
  }

  if (!isFocused || !dog || !Object.keys(dog).length) {
    return null;
  }

  return (
    <>
      <FunnelWrapper
        containerProps={{
          space: { base: 6, md: 12 },
          justifyContent: 'flex-start',
          alignItems: 'center',
          maxW: { '2xl': '100%' },
        }}
      >
        <VStack space="16px" justifyContent="center" alignItems="center">
          <Heading size="titleSmToMd" fontWeight="bold" textAlign="center">
            Want to add Snacks to {dog.name}'s Plan?
          </Heading>
          <Heading
            size="bodySmToMd"
            fontFamily="secondary"
            textAlign="center"
            maxW={{ base: '100%', md: '960px' }}
          >
            {dogIsPup
              ? "Meet our line of single-ingredient, premium treats. They're great for training, and even for nibbles in between meals! Packed full of vitamins and protein, your puppy will be head-over-heels for these healthy snacks."
              : 'Single ingredient treats for your pup — give them the best between meals, too.'}
          </Heading>
          {isDiscountApplied && (
            <Badge size="bodySmToMd" mb="2px">
              {`${round(discount?.trial_value || discount?.value || 0, 0)}% Off Applied`}
            </Badge>
          )}
        </VStack>
        <Stack w="100%" alignItems="center" pb={{ base: '160px', md: '200px' }}>
          <Stack
            flexDirection={{ base: 'column', lg: 'row' }}
            flexWrap={allSnacks.length === 4 ? 'wrap' : 'nowrap'}
            w={{
              base: '100%',
              lg: allSnacks.length === 4 ? '800px' : undefined,
              '2xl': '100%',
            }}
            justifyContent="center"
            alignItems={{ base: 'center', lg: 'flex-start' }}
          >
            {allSnacks?.map((snack) => (
              <Box key={snack.id} alignItems="center" w={{ base: '100%', md: 'auto', lg: 'auto' }}>
                <RecipeCard
                  w={{ base: '100%', md: '350px', lg: '324px' }}
                  h={{ base: '142px', md: '175px', lg: 'inherit' }}
                  minH={{ base: '142px', md: '175px', lg: '378px' }}
                  justifyContent="center"
                  alignItems="center"
                  recipe={snack}
                  pricing={Number(snack.default_product?.price.price)}
                  discountedPrice={
                    snack.discounted_price !== null ? Number(snack.discounted_price) : null
                  }
                  selected={snacks.some((selected) => selected === snack.id)}
                  onPress={() => updateSnacks(snack.id)}
                  pricingUnit="bag"
                  underlineButton={{
                    text: 'View Details',
                    onPress: () => {
                      setShowPDPModal(true);
                      setDetailSnack(snack);
                    },
                  }}
                />
              </Box>
            ))}
          </Stack>
        </Stack>
      </FunnelWrapper>

      {detailSnack && (
        <PDPModal
          recipe={detailSnack}
          buttonText="Select Snack"
          prices={[detailSnack.default_product?.price.price]}
          discountedPrices={[detailSnack.discounted_price]}
          pricingUnits={['bag']}
          updateState={() => {
            if (!snacks.some((selectedId: string) => selectedId === detailSnack.id)) {
              setSnacks(snacks.concat([detailSnack.id]));
            }
          }}
          isOpen={showPDPModal}
          onClose={() => setShowPDPModal(false)}
        />
      )}

      <Stack
        justifyContent="center"
        alignItems="center"
        space={{ base: 4, lg: 6 }}
        w="100%"
        variant="stickyFooter"
        style={stickyFooterStyle}
        position="fixed"
        px={4}
      >
        <HStack w={{ base: '100%', lg: '336px' }}>
          <Button
            h={{ base: '52px', lg: '56px' }}
            isDisabled={snacks.length === 0 || isLoadingSnacks}
            isLoading={isSubmitting && snacks.length !== 0}
            size="bodyMdToLg"
            w="100%"
            onPress={() => onSubmit(snacks)}
          >
            CONTINUE
          </Button>
        </HStack>
        {snacks.length === 0 ? (
          <HStack justifyContent="center" w={{ base: '100%', lg: '336px' }}>
            <Button
              h="20px"
              isDisabled={isLoadingSnacks}
              isLoading={isSubmitting && snacks.length === 0}
              variant="underlineMini"
              size="bodyMdToLg"
              onPress={() => onSubmit(snacks)}
            >
              No thanks, I don't want to add any snacks
            </Button>
          </HStack>
        ) : null}
      </Stack>
    </>
  );
};
export default Snacks;
