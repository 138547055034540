import { addonSeqNoCompare } from './orders';

import { Account, PetPlan, PetPlanProductStatus, PetPlanStatus, RecipeType } from '@/api';

export const getPetPlanMeals = (petPlan: PetPlan) =>
  petPlan.products.filter((product) =>
    [RecipeType.FRESH, RecipeType.UNKIBBLE].includes(product.recipes[0].type)
  );

export const getPetPlanAddons = (
  petPlan: PetPlan,
  recipeType: RecipeType,
  { excludeCancelled }: { excludeCancelled?: boolean } = {}
) => {
  const addonsOfType = petPlan.products
    .filter((product) => recipeType === product.recipes[0].type)
    .sort(addonSeqNoCompare);

  if (excludeCancelled) {
    return addonsOfType.filter((product) => product.status !== PetPlanProductStatus.CANCELLED);
  } else {
    return addonsOfType;
  }
};

export const getActivePlans = (account: Account) =>
  account.petplans.filter((plan) => plan.status === PetPlanStatus.ACTIVE);

export const getProductCountByRecipeType = (plan: PetPlan, recipeType: RecipeType) =>
  plan.products.reduce((count, product) => {
    let quantity = 0;
    if (
      product.recipes[0].type === recipeType &&
      product.status !== PetPlanProductStatus.CANCELLED
    ) {
      quantity = product.quantity;
    }
    return count + quantity;
  }, 0);

export const accountHasMultipleDogs = (account: Account) => account.pets.length > 1;
