import { Stack, Heading, Text, FormControl, Link, View } from 'native-base';
import { useRef, useEffect } from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';

import { CheckoutDetails } from '../../Checkout';
import { CheckoutFormRow } from './components';

import { Checkbox } from '@/components/Elements';
import { useIsMobile } from '@/hooks';

const getAddressComponent = (components: any[], type: string, nameType = 'long_name') => {
  const component = components.find((c: { types: string | any[] }) => c.types.includes(type));
  return component ? component[nameType] : '';
};

export const CheckoutFormShipping = (props: any) => {
  const {
    control,
    formState: { errors },
    setValue,
    trigger,
  } = useFormContext<CheckoutDetails>();
  const addressInputRef = useRef(null);

  const phoneNumber = useWatch({ control, name: 'phone_number' });
  useEffect(() => {
    // Reset the checkbox if the phone_number is removed
    if (!phoneNumber) {
      setValue('to_receive_text_messages', false);
    }
  }, [phoneNumber, setValue]);

  // initialize Google Maps Autocomplete
  useEffect(() => {
    if (!addressInputRef.current) return;

    const autocomplete = new google.maps.places.Autocomplete(addressInputRef.current, {
      types: ['address'],
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      const streetNumber = getAddressComponent(place.address_components, 'street_number');
      const route = getAddressComponent(place.address_components, 'route');
      const address1 = `${streetNumber} ${route}`.trim();
      setValue('address1', address1);

      const locality = getAddressComponent(place.address_components, 'locality');
      const sublocality = getAddressComponent(place.address_components, 'sublocality');
      setValue('city', locality || sublocality);

      const administrativeAreaLevel1 = getAddressComponent(
        place.address_components,
        'administrative_area_level_1',
        'short_name'
      );
      setValue('state', administrativeAreaLevel1);

      const postalCode = getAddressComponent(place.address_components, 'postal_code');
      setValue('zip_code', postalCode);
      trigger(['address1', 'city', 'state', 'zip_code']);
    });

    return () => {
      autocomplete.unbindAll();
    };
  }, [addressInputRef]);

  const isMobile = useIsMobile();

  return (
    <Stack {...props}>
      <Heading size="bodyMlToTitleSm" mb={{ base: 4, lg: 6 }} fontWeight="bold">
        Shipping Information
      </Heading>
      <CheckoutFormRow
        types={['input', 'input']}
        names={['first_name', 'last_name']}
        placeholders={['First Name', 'Last Name']}
        flexRatio={[1, 1]}
        required={[true, true]}
        autoFocus={!isMobile}
      />
      <CheckoutFormRow
        types={['addressInput']}
        names={['address1']}
        placeholders={['Street address (no PO boxes)']}
        flexRatio={[1]}
        required={[true]}
        addressInputRef={addressInputRef}
      />
      <CheckoutFormRow
        types={['input', 'input']}
        names={['address2', 'city']}
        placeholders={['Apt # (optional)', 'City']}
        flexRatio={[1, 1]}
        required={[false, true]}
        addressInputRef={addressInputRef}
      />
      <CheckoutFormRow
        types={['select', 'input']}
        names={['state', 'zip_code']}
        placeholders={['State', 'Zip']}
        flexRatio={[1, 1]}
        direction="row"
        required={[true, true]}
      />
      <CheckoutFormRow
        types={['input', 'phoneInput']}
        names={['email', 'phone_number']}
        placeholders={['Email', 'Phone Number (optional)']}
        flexRatio={[1, 1]}
        required={[true, false]}
      />

      <Stack my={4}>
        <FormControl isInvalid={'to_receive_text_messages' in errors} isRequired>
          <Controller
            control={control}
            name="to_receive_text_messages"
            render={({ field: { onChange, value, name } }) => (
              <Stack flexDirection="row" maxW="inherit">
                <Checkbox
                  value={name}
                  alignItems="flex-start"
                  isChecked={value}
                  w="inherit"
                  onChange={(value) => onChange(value)}
                  variant="blackBorder"
                  isDisabled={!phoneNumber} // Disable if phone_number is not provided
                  accessibilityLabel="By checking this checkbox, I agree to receive text messages from Spot & Tango and its service providers
                  regarding the delivery of your product or promotions. Message and data rates may
                  apply. We'll always send you order updates via email."
                />
                <View display="inline-block" flex={1} pl={{ base: 2, md: 4 }}>
                  <Text
                    onPress={() => onChange(!value)}
                    size="captionToBodySm"
                    w="inherit"
                    color="black"
                    fontFamily="secondary"
                    fontWeight="medium"
                  >
                    By checking this box, you agree to receive recurring automated promotional and
                    personalized marketing messages by call or text at this number from Spot &
                    Tango. Consent is not a condition of any purchase. Reply HELP for help and STOP
                    to cancel. Msg & data rates may apply. View{' '}
                  </Text>
                  <Link
                    display="inline"
                    href="https://attnl.tv/t/EiO/iEE"
                    variant="inline"
                    color="black"
                    textDecoration="underline"
                    isExternal
                  >
                    <Text
                      size="captionToBodySm"
                      fontFamily="secondary"
                      fontWeight="bold"
                      color="black"
                      textDecoration="underline"
                    >
                      Terms
                    </Text>
                  </Link>
                  <Text
                    size="captionToBodySm"
                    w="inherit"
                    color="black"
                    fontFamily="secondary"
                    fontWeight="medium"
                  >
                    {' '}
                    &{' '}
                  </Text>
                  <Link
                    display="inline"
                    href="https://attnl.tv/p/EiO"
                    variant="inline"
                    color="black"
                    textDecoration="underline"
                    fontWeight="bold"
                    isExternal
                  >
                    <Text
                      size="captionToBodySm"
                      fontWeight="bold"
                      fontFamily="secondary"
                      color="black"
                      textDecoration="underline"
                    >
                      Privacy
                    </Text>
                  </Link>
                  <Text
                    size="captionToBodySm"
                    w="inherit"
                    color="black"
                    fontFamily="secondary"
                    fontWeight="medium"
                  >
                    .
                  </Text>
                </View>
              </Stack>
            )}
          />

          <FormControl.ErrorMessage
            mx={{ base: 8, md: 10 }}
            justifyContent="flex-start"
            alignSelf="flex-start"
            _text={{ color: '#ff0000', textAlign: 'left' }}
          >
            {errors.to_receive_text_messages?.message}
          </FormControl.ErrorMessage>
        </FormControl>
      </Stack>
    </Stack>
  );
};
