import { Button, Link, Text, Heading, Image, Stack } from 'native-base';
import { useErrorBoundary } from 'react-error-boundary';
import { Linking, Pressable } from 'react-native';

import { ASSETS_URL } from '@/constants';
import { useSessionQuery } from '@/hooks';
import { sendFunnelErrorReport } from '@/utils';

interface ErrorScreenProps {
  type: 'Funnel' | 'Portal';
}

const notFoundURI = `${ASSETS_URL}/pictos/Illustrations/page-not-found.svg`;

const ErrorScreen = ({ type }: ErrorScreenProps) => {
  const { clearSession } = useSessionQuery();
  const { resetBoundary } = useErrorBoundary();

  const startOverFunc = async () => {
    if (type === 'Funnel') {
      try {
        await clearSession();
        resetBoundary();
      } catch (error) {
        sendFunnelErrorReport(error as Error);
      }
    } else if (type === 'Portal') {
      resetBoundary();
    }
  };

  const bodyText =
    (type === 'Funnel' &&
      `We can’t find the page you’re looking for.
  Start over or contact our Support Team for help.`) ||
    (type === 'Portal' &&
      `We can’t find the page you’re looking for.
  Head back to the last visited page or contact our Support Team for help.`);

  const CTAtext = (type === 'Funnel' && 'START OVER') || (type === 'Portal' && 'GO BACK');

  return (
    <Stack justifyContent="center" alignItems="center" paddingTop={{ base: '16px', md: '36px' }}>
      <Stack
        paddingBottom={{ base: '64px', md: '80px' }}
        h={{ base: '20px', lg: '' }}
        justifyContent="center"
        alignItems="center"
      >
        <Image
          width={{ base: '117px', lg: '282px' }}
          height={{ base: '20px', lg: '64px' }}
          source={{
            uri: `${ASSETS_URL}/logo.svg`,
          }}
          alt="Spot & Tango Logo"
          resizeMode="contain"
        />
      </Stack>
      <Heading
        fontSize={{ base: '24px', md: '32px' }}
        fontFamily="primary"
        fontWeight="bold"
        textAlign="center"
      >
        Sorry, we can’t find that page
      </Heading>
      <Stack py={{ base: '16px', md: '36px' }}>
        <Image
          width={{ base: '200px', md: '250px' }}
          height={{ base: '204px', md: '257px' }}
          source={{ uri: notFoundURI }}
          alt="Spot & Tango Logo"
          resizeMode="contain"
        />
      </Stack>

      <Text
        lineHeight={{ base: '20px', md: '24px' }}
        size="bodyMdToLg"
        fontFamily="secondary"
        textAlign="center"
      >
        {bodyText}
      </Text>
      <Button
        onPress={startOverFunc}
        alignSelf="center"
        size="bodyMdToLg"
        testID="name-and-email-submit"
        my="16px"
      >
        {CTAtext}
      </Button>
      <Pressable
        onPress={() =>
          Linking.openURL(
            'https://spotandtango.com/faq?a=How-do-I-contact-customer-support---id--IKoHhdLiR3SYrMUbeS90EQ'
          )
        }
      >
        <Link textDecoration="underline">
          <Text fontWeight="bold" fontSize="body.md" textAlign="center">
            Contact support
          </Text>
        </Link>
      </Pressable>
    </Stack>
  );
};

export default ErrorScreen;
