import { BadgeTheme } from './Badge';
import { ButtonTheme } from './Button';
import { CheckboxTheme } from './Checkbox';
import { DividerTheme } from './Divider';
import { FormControlTheme } from './FormControl';
import { HeadingTheme } from './Heading';
import { IconButtonTheme } from './IconButton';
import { InputTheme } from './Input';
import { LinkTheme } from './Link';
import { ModalContentTheme } from './Modal';
import { PressableTheme } from './Pressable';
import { RadioTheme } from './Radio';
import { SelectTheme } from './Select';
import { SliderTheme } from './Slider';
import { StackTheme } from './Stack';
import { SwitchTheme } from './Switch';
import { TextTheme } from './Text';
import { TooltipTheme } from './Tooltip';

export const componentThemes = {
  Button: ButtonTheme,
  Badge: BadgeTheme,
  Checkbox: CheckboxTheme,
  Divider: DividerTheme,
  ...FormControlTheme,
  Heading: HeadingTheme,
  IconButton: IconButtonTheme,
  Input: InputTheme,
  Link: LinkTheme,
  ModalContent: ModalContentTheme,
  Pressable: PressableTheme,
  Radio: RadioTheme,
  Select: SelectTheme,
  ...SliderTheme,
  Stack: StackTheme,
  Switch: SwitchTheme,
  Text: TextTheme,
  Tooltip: TooltipTheme,
};

export * from './Accordion';
export * from './Animation';
export * from './Avatar';
export * from './Badge';
export * from './Breadcrumbs';
export * from './Button';
export * from './Cards';
export * from './Carousel';
export * from './CarouselItem';
export * from './Checkbox';
export * from './Dropdown';
export * from './Grid';
export * from './HiddenTextDots';
export * from './Image';
export * from './Input';
export * from './Label';
export * from './LabeledFormControl';
export * from './LabeledInput';
export * from './LabeledSelect';
export * from './LineItemPlanCard';
export * from './Link';
export * from './LoadingSpinner';
export * from './Modal';
export * from './OptionSelector';
export * from './PaymentRetryErrorToastMessage';
export * from './PetPillButton';
export * from './PhoneInput';
export * from './Pictos';
export * from './Pill';
export * from './PillMenu';
export * from './PortalHead';
export * from './PortalPopUp';
export * from './PortalWrapper';
export * from './ProductModal';
export * from './Radio';
export * from './Scrollbar';
export * from './Select';
export * from './Spinner';
export * from './Stack';
export * from './Stepper';
export * from './Tab';
export * from './Toast';
export * from './Tooltip';
