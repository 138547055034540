import { useFocusEffect } from '@react-navigation/native';
import {
  Heading,
  Button,
  VStack,
  HStack,
  FormControl,
  Pressable,
  Stack,
  Link,
  Text,
} from 'native-base';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Platform } from 'react-native';

import { BirthdayMonthPlaceholder, BirthdayYearPlaceholder } from './placeholders';

import { Select, SelectOption } from '@/components/Elements';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { useAxiosStatusContext } from '@/context';
import { useEnterPressEffect, useFunnelErrorHandler, useIsMobile } from '@/hooks';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { useSessionQuery } from '@/hooks/useSessionQuery';
import { BirthdayResolver } from '@/screens/resolvers';
import { FunnelScreenProps } from '@/types';
import { Dog } from '@/types/FunnelSession';
import { getCurrentPet } from '@/utils/getCurrentPet';

type BirthdayFormData = Pick<Dog, 'birth_month' | 'birth_year'>;

export default function Birthday({ navigation }: FunnelScreenProps<'Birthday'>) {
  const nextStep = useNextFunnelStep(FunnelScreenNames.BIRTHDAY);
  const { session, mutateUpdatePetAnswer } = useSessionQuery();
  const funnelErrorHandler = useFunnelErrorHandler();
  const dog = getCurrentPet(session);
  const isMobile = useIsMobile();
  const { addAxiosPromise } = useAxiosStatusContext();

  const years: SelectOption[] = [];
  const thisYear = new Date().getFullYear();

  const [birthdayError, setBirthdayError] = useState<string | null>(null);

  for (let i = 0; i < 30; i++) {
    const pastYear = thisYear - i;
    years.push({ value: pastYear.toString(), label: pastYear.toString() });
  }

  const months: SelectOption[] = [...Array(12)].map((_, i) => {
    const date = new Date(0, i + 1, 0);
    const month = date.toLocaleString('en-US', { month: 'long' });
    return {
      value: (i + 1).toString(),
      label: month,
    };
  });

  const onSubmit = (data: BirthdayFormData) => {
    if (!data.birth_year) {
      setBirthdayError('Birth year is required');
      return;
    }
    try {
      addAxiosPromise(mutateUpdatePetAnswer(data));
    } catch (error) {
      funnelErrorHandler(error, 'Update birthday');
    }
    navigation.navigate(nextStep);
  };

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
    setFocus,
    reset,
    trigger,
  } = useForm<BirthdayFormData>({
    resolver: BirthdayResolver,
    defaultValues: { birth_year: dog?.birth_year, birth_month: dog?.birth_month },
    mode: 'onChange',
  });

  useEnterPressEffect(() => {
    if (!birthYear) {
      setFocus('birth_year');
    } else {
      handleSubmit(onSubmit)();
    }
  });

  const birthYear = watch('birth_year');
  const birthMonth = watch('birth_month');

  useFocusEffect(
    useCallback(() => {
      if (dog?.birth_year) setValue('birth_year', dog.birth_year);
      if (dog?.birth_month) setValue('birth_month', dog.birth_month);
    }, [dog])
  );

  return (
    <FunnelWrapper containerProps={{ space: 12 }}>
      <VStack alignItems="center" justifyContent={{ base: 'center', md: 'flex-start' }} w="100%">
        <Heading size="titleSmToMd" fontWeight="bold" mb={{ base: '36px', md: '48px' }}>
          When is {dog?.name}'s birthday?
        </Heading>
        <VStack w="100%" alignItems="center">
          {Platform.OS === 'web' ? (
            <HStack
              justifyContent="center"
              alignItems="flex-start"
              space={{ base: '15px', md: '22px' }}
              w={{ base: '100%', md: '472px' }}
            >
              <FormControl isInvalid={'birth_month' in errors} flex={1}>
                <Controller
                  control={control}
                  name="birth_month"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <VStack>
                        <Select
                          innerRef={ref}
                          options={months}
                          onChange={(newValue: any) => {
                            onChange(newValue?.value ? Number(newValue.value) : undefined);
                          }}
                          onBlur={onBlur}
                          name="month"
                          placeholder={<BirthdayMonthPlaceholder />}
                          value={
                            value === undefined
                              ? null // must explicitly set to null to clear react-select
                              : months.find((month) => month.value === value.toString())
                          }
                          blurInputOnSelect
                          autoFocus={!isMobile}
                        />
                        {errors?.birth_month?.message ? (
                          <FormControl.ErrorMessage>
                            {errors?.birth_month?.message}
                          </FormControl.ErrorMessage>
                        ) : null}
                      </VStack>
                    );
                  }}
                />
              </FormControl>
              <FormControl isRequired isInvalid={'birth_year' in errors} flex={1}>
                <Controller
                  control={control}
                  name="birth_year"
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <VStack>
                        <Select
                          options={years}
                          onChange={(newValue: any) => {
                            if (newValue?.value) {
                              setBirthdayError(null);
                            }
                            onChange(newValue?.value ? Number(newValue.value) : undefined);
                            trigger(); // validate month and year together
                          }}
                          onBlur={onBlur}
                          name="year"
                          placeholder={<BirthdayYearPlaceholder />}
                          value={
                            value === undefined
                              ? null // must explicitly set to null to clear react-select
                              : years.find((year) => year.value === value.toString())
                          }
                          blurInputOnSelect
                        />
                        {errors?.birth_year?.message ? (
                          <FormControl.ErrorMessage>
                            {errors?.birth_year?.message}
                          </FormControl.ErrorMessage>
                        ) : null}
                        {birthdayError ? (
                          <FormControl.ErrorMessage>{birthdayError}</FormControl.ErrorMessage>
                        ) : null}
                      </VStack>
                    );
                  }}
                />
              </FormControl>
            </HStack>
          ) : null}
        </VStack>

        {birthMonth ? (
          <Stack w={{ base: '100%', md: '472px' }} mt={{ base: '16px', md: '24px' }}>
            <Pressable
              alignSelf="flex-start"
              onPress={() => {
                reset();
              }}
            >
              <Link textDecoration="underline">
                <Text fontWeight="bold" fontSize="body.md">
                  Clear fields
                </Text>
              </Link>
            </Pressable>
          </Stack>
        ) : null}
      </VStack>
      <Button
        variant="primary"
        size="bodyMdToLg"
        w="100%"
        maxW={{ base: '100%', md: '290px' }}
        h={{ base: '52px', md: '56px' }}
        onPress={handleSubmit(onSubmit)}
        isDisabled={!birthYear || Object.keys(errors).length !== 0}
        isLoading={isSubmitting}
      >
        CONTINUE
      </Button>
    </FunnelWrapper>
  );
}
