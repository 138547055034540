import clsx from 'clsx';
import { useState } from 'react';
import * as React from 'react';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';

const defaultStyle =
  'flex justify-center items-center m-0 inline-block ' +
  'font-sans font-[400] text-[16px] text-[color:var(--secondary-grey)] leading-[20px] ' +
  'w-[156px] h-[52px] py-[16px] px-[49px] ' +
  'rounded-[25px] border-solid ';
// + 'hover:border-[2px] hover:border-[color:var(--aqua-island)] '; - FOR WEB

const borderStyles = {
  darkGrey: 'border-[1px] border-[color:var(--dark-grey)]',
  aquaIsland: 'border-[2px] border-[color:var(--aqua-island)]',
};

const variants = {
  default: borderStyles.darkGrey,
};

const withLabel = {
  noLabel: false,
  yesLabel: true,
};

const selectOptions = {
  integers: [1, 2, 3],
};

type ListProps = {
  arr: any[];
  updateSelect: (item: any, showMenu: boolean) => void;
  getSelected: () => { item: any; showMenu: boolean };
};

function OptionList(props: ListProps) {
  let compArr;
  const arr = props.arr;
  const updateSelect = props.updateSelect;
  const getSelected = props.getSelected;

  if (arr.length > 0) {
    compArr = arr.map((option, i) => {
      const currentKey = i + option.toString();
      return (
        <li
          className={clsx(
            getSelected().item && getSelected().item.key === currentKey
              ? 'bg-[color:#A5D9DC47]'
              : '', // hover:bg-[color:#A5D9DC47] - FOR WEB
            'w-full flex justify-Center items-center rounded-[8px] py-[8px]'
          )}
          key={currentKey}
        >
          <button
            className="w-full text-[color:var(--dark-grey)] text-[length:var(--font-size-body-lg)] !leading-[20px] "
            onClick={(e) => {
              e.preventDefault();

              if (!getSelected().item) {
                updateSelect({ content: option, key: currentKey }, false);
              } else if (getSelected().item && getSelected().item.key !== currentKey) {
                updateSelect({ content: option, key: currentKey }, false);
              } else if (getSelected().item && getSelected().item.key === currentKey) {
                updateSelect(null, false);
              }
            }}
          >
            {option.toString()}
            <span className="w-[18px] inline-block h-[18px] ml-[8px]" />
          </button>
        </li>
      );
    });
  }
  return <>{compArr}</>;
}

export type DropdownProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant: keyof typeof variants;
  className?: string;
  placeholder?: string;
  option: keyof typeof selectOptions;
  label: keyof typeof withLabel;
  labelText?: string;
};

export const Dropdown = React.forwardRef<HTMLButtonElement, DropdownProps>(
  (
    {
      type = 'button',
      className = '',
      variant = 'default',
      placeholder = 'Year',
      option = 'integers',
      label = 'noLabel',
      labelText = 'dog(s)',
      ...props
    },
    ref
  ) => {
    type SelectState = {
      item: { content: string; key: string } | null;
      showMenu: boolean;
    };

    const [selectState, setSelectState] = useState<SelectState>({ item: null, showMenu: false });

    const updateSelect = (item: any, showMenu: boolean) => {
      setSelectState({ item, showMenu });
    };

    const getSelected = () => selectState;

    return (
      <div>
        <div className="flex items-center">
          <button
            ref={ref}
            type={type}
            className={clsx(
              defaultStyle,
              variants[variant],
              selectState.showMenu ? 'border-[2px] border-[color:var(--aqua-island)]' : '',
              className
            )}
            style={{ fontFamily: 'customFont' }}
            {...props}
            onClick={(e) => {
              e.preventDefault();
              updateSelect(selectState.item, !selectState.showMenu);
            }}
          >
            <span className={clsx(selectState.item ? 'text-[color:var(--dark-grey)]' : '')}>
              {selectState.item ? selectState.item.content : placeholder}
            </span>
            <span>
              <Image src={`${ASSETS_URL}/chevron-down.svg`} />
            </span>
          </button>

          <span
            className={clsx(
              withLabel[label] ? 'inline-block' : 'hidden',
              'ml-[8px] text-[color:var(--blue-bayoux)] font-sans text-[18px] font-black leading-[24px]'
            )}
          >
            {labelText}
          </span>
        </div>
        <ul
          className={clsx(
            selectState.showMenu ? 'block relative z-10' : 'hidden',
            'w-[156px] h-[108px] pt-[4px] border-none'
          )}
        >
          <OptionList
            arr={selectOptions[option]}
            updateSelect={updateSelect}
            getSelected={getSelected}
          />
        </ul>
      </div>
    );
  }
);

Dropdown.displayName = 'Dropdown';
