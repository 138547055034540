import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import Constants from 'expo-constants';

import { toastIsActive } from '@/components/Elements';
import { defaultErrorHandler, petPlanInHoldStatusErrorHandler } from '@/utils';

export const AXIOS_TOAST_NAME = 'axios_toast';
export const PET_PLAN_HOLD_STATUS_ERROR = 'Pet Plan has order on HOLD';

async function authRequestInterceptor(config: AxiosRequestConfig) {
  const auth_token = localStorage.getItem('auth_token');
  if (config.headers) {
    if (auth_token) {
      config.headers['Authorization'] = `Token ${auth_token}`;

      // impersonate function for staff users
      const impersonateToken = localStorage.getItem('impersonate_token');
      if (impersonateToken) {
        config.headers['Impersonate-Token'] = impersonateToken;
      }
    }
    config.headers['x-application'] = 'native-apps';
    config.headers['x-fe-gh-commit-hash'] = process.env.FE_GH_COMMIT_HASH;
    config.headers['x-fe-build-date'] = process.env.FE_BUILD_DATE;
  }

  return config;
}

export const axios = Axios.create({
  baseURL: String(Constants?.expoConfig?.extra?.API_URL),
});

axios.interceptors.request.use(authRequestInterceptor);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: AxiosError) {
    if (!toastIsActive(AXIOS_TOAST_NAME) && !error.config?.noErrorToast) {
      if (error.response?.status === 403 && error.response?.data === PET_PLAN_HOLD_STATUS_ERROR) {
        petPlanInHoldStatusErrorHandler();
      } else {
        defaultErrorHandler();
      }
    }

    return Promise.reject(error);
  }
);
