import { HStack, IStackProps, Skeleton, Stack } from 'native-base';

import { GridSkeleton } from '@/components/Elements';

export const ShoppingExperienceSkeleton = (props: IStackProps) => (
  <Stack
    w={{ base: '100%', lg: '840px' }}
    alignItems="flex-start"
    space={{ base: '16px', lg: '24px' }}
    py="12px"
    {...props}
  >
    <HStack w="100%" justifyContent="space-between">
      <Skeleton.Text lines={1} w={{ base: '60px', lg: '90px' }} />
      <Skeleton.Text lines={1} w={{ base: '150px', lg: '180px' }} />
    </HStack>
    <HStack py="12px" w="100%" flexWrap="wrap">
      {[...Array(3)].map((_, i) => (
        <Stack flex={{ base: '50%', lg: 'unset' }} key={i} alignItems="flex-start">
          <GridSkeleton mx={{ lg: '12px' }} />
        </Stack>
      ))}
    </HStack>
  </Stack>
);
