import { IImageProps, Image as NBImage, ISkeletonProps, Skeleton } from 'native-base';

const FallbackSkeleton = ({ w = '100%', h = '100%', ...props }: ISkeletonProps) => (
  <Skeleton w={w} h={h} {...props} />
);

export const Image = ({ ...props }: IImageProps) => (
  <NBImage
    fallbackElement={
      <FallbackSkeleton
        width={props.width}
        height={props.height}
        w={props.w}
        h={props.h}
        size={props.size}
      />
    }
    resizeMode="contain"
    {...props}
  />
);
