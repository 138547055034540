import { IImageProps, IStackProps, Stack, Text } from 'native-base';

import { Pet, PetStatus } from '@/api';
import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { capitalize } from '@/utils';
const noAvatarImages = `${ASSETS_URL}/pictos/Gender/`;

export interface AvatarImageProps {
  petId?: string;
  petName?: string;
  petGender?: Pet['gender'];
  petStatus?: Pet['status'];
  source?: IImageProps['source'];
  imageProps?: Omit<IImageProps, 'source' | 'size'>;
  imageSize?: IImageProps['size'];
  stackProps?: IStackProps;
  grayscale?: boolean;
  noImgVariant?: 'dog' | 'initial' | 'digit';
  petsCount?: string | number;
}

export const AvatarImage = ({
  source,
  imageProps,
  imageSize,
  stackProps = {},
  grayscale,
  petGender,
  petName,
  petStatus = PetStatus.ACTIVE,
  noImgVariant = 'dog',
  petsCount = '',
}: AvatarImageProps) => {
  const noAvatarImage = petGender === 'MALE' ? 'BoyDog.png' : 'GirlDog.png';
  return (
    <Stack
      w={imageSize}
      h={imageSize}
      p={typeof imageSize === 'number' ? imageSize * 0.2 : '10%'}
      justifyContent="center"
      alignItems="center"
      rounded="100%"
      bg={petStatus !== PetStatus.ACTIVE ? 'white' : 'sntBlue.primary'}
      borderWidth="1px"
      borderColor={petStatus !== PetStatus.ACTIVE ? 'black' : 'sntGrey.primary'}
      overflow="hidden"
      variant="avatar"
      {...stackProps}
    >
      {noImgVariant === 'digit' && petsCount && (
        <Text size="bodySmToMd" fontWeight="bold" zIndex={100}>
          +{petsCount}
        </Text>
      )}
      {noImgVariant === 'initial' && petName && (
        <Text size="bodySmToMd" fontWeight="bold" zIndex={100}>
          {capitalize(petName.charAt(0))}
        </Text>
      )}
      {noImgVariant === 'dog' && (
        <Image
          source={source || { uri: `${noAvatarImages}${noAvatarImage}` }}
          size={source ? imageSize : typeof imageSize === 'number' ? imageSize * 0.8 : '100%'}
          resizeMode="contain"
          tintColor={petStatus !== PetStatus.ACTIVE ? 'sntGrey.primary' : undefined}
          opacity={grayscale ? 0.5 : 1}
          alt={petName}
          {...imageProps}
        />
      )}
    </Stack>
  );
};
