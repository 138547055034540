import { Heading, HStack, IStackProps, Text, Stack } from 'native-base';
import { forwardRef } from 'react';

import { Image, VToHStack } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { useIsMobile } from '@/hooks';

export interface ProductSectionProps extends IStackProps {
  title: string;
}

export const ProductSection = forwardRef<React.RefObject<HTMLDivElement>, ProductSectionProps>(
  ({ title, ...props }, ref) => {
    const isMobile = useIsMobile();

    const SubHeading = () => (
      <Text fontFamily="secondary" size="bodyMdToLg" maxW="305px">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua
      </Text>
    );

    return (
      <Stack ref={ref} {...props}>
        <VToHStack justifyContent="start" space={{ base: 4, lg: 6 }}>
          <Stack>
            <HStack space={{ base: 1, lg: 2 }} alignItems="center">
              <Heading size="titleMdToMl" fontWeight="bold">
                {title.toLocaleUpperCase()}
              </Heading>
              <Image
                source={{ uri: `${ASSETS_URL}/pictos` }}
                w={{ base: '34px', lg: '67px' }}
                h={{ base: '30px', lg: '40px' }}
              />
            </HStack>
            {!isMobile ? <SubHeading /> : null}
          </Stack>
          <Stack flex={1}>
            <Image
              w="100%"
              h={{ base: '305px', lg: '359px' }}
              source={{ uri: `${ASSETS_URL}/pictos` }}
              resizeMode="contain"
            />
          </Stack>
          {isMobile ? <SubHeading /> : null}
        </VToHStack>
      </Stack>
    );
  }
);
