import {
  Box,
  Heading,
  Text,
  Factory,
  Pressable,
  VStack,
  IconButton,
  IIconButtonProps,
  Center,
  IImageProps,
  Badge,
  ITextProps,
  IHeadingProps,
} from 'native-base';
import { useState } from 'react';

import { Image, Stepper, Tooltip } from '@/components/Elements';
import { PlusCircleFill } from '@/components/Icons';
import { roundPriceShort } from '@/utils';

export type CarouselItemProps = {
  title: string;
  description: string;
  image: string;
  value: number;
  price?: number | string;
  unit?: string;
  onAdd?: () => void;
  onRemove?: () => void;
  onPressImage?: () => void;
  isLongPressAvailable?: boolean;
  hoverStateImage?: string;
  isLoading?: boolean;
  plusDisabledMessage?: string;
  showNewBadge?: boolean;
  showStepper?: boolean;
  imageProps?: IImageProps;
  descriptionProps?: ITextProps;
  titleProps?: IHeadingProps;
};

export const MyCarouselItem = ({
  title,
  description,
  image,
  value,
  price,
  unit = 'bag',
  isLongPressAvailable = false,
  hoverStateImage = '',
  isLoading = false,
  plusDisabledMessage,
  showNewBadge,
  showStepper = true,
  onAdd = () => {},
  onRemove = () => {},
  onPressImage,
  imageProps,
  descriptionProps,
  titleProps,
  ...props
}: CarouselItemProps) => {
  const [showHoverStateImage, setShowHoverStateImage] = useState(false);

  const toggleHoverImage = () =>
    isLongPressAvailable ? setShowHoverStateImage((prev) => !prev) : null;

  return (
    <VStack
      justifyContent="center"
      alignItems="center"
      h="100%"
      space={{ base: '8px', lg: '16px' }}
      {...props}
    >
      <Pressable
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderWidth="0px"
        _hover={{ borderWidth: '0px' }}
        p="0px"
        h="fit-content"
        onPress={() => onPressImage?.()}
        onLongPress={toggleHoverImage}
        onHoverIn={toggleHoverImage}
        onHoverOut={toggleHoverImage}
      >
        {showNewBadge ? (
          <Badge
            variant="smallTabActive"
            pointerEvents="none"
            position="absolute"
            zIndex={1}
            top={2}
            right={2}
            px={1}
          >
            NEW!
          </Badge>
        ) : null}
        <Image
          alt={title}
          size={{ base: '140px', lg: '220px' }}
          source={{ uri: isLongPressAvailable && showHoverStateImage ? hoverStateImage : image }}
          resizeMode="contain"
          bg="sntGrey.athens"
          {...imageProps}
        />
      </Pressable>
      <VStack justifyContent="center" alignItems="center">
        <Heading size="bodyMlToLg" fontWeight="bold" mb={1} textAlign="center" {...titleProps}>
          {title}
        </Heading>

        <Text
          size="bodySmToMd"
          fontWeight="medium"
          fontFamily="secondary"
          mb={2}
          textAlign="center"
          {...descriptionProps}
        >
          {description}
        </Text>

        {price && unit ? (
          <Text size="bodySmToMd" fontWeight="medium" mb={2}>
            ${roundPriceShort(price)}/{unit}
          </Text>
        ) : null}

        {showStepper ? (
          <Box display="flex" justifyContent="center" alignItems="center" w="100%">
            {value > 0 ? (
              <Stepper
                controlledQuantity={value}
                onPressInc={onAdd}
                onPressDec={onRemove}
                disabledMinus={isLoading}
                disabledPlus={isLoading || !!plusDisabledMessage}
                plusTooltip={plusDisabledMessage}
                w="100%"
              />
            ) : (
              <AddNewItemButton
                plusTooltip={plusDisabledMessage}
                isDisabled={isLoading || !!plusDisabledMessage}
                onPress={() => onAdd()}
              />
            )}
          </Box>
        ) : null}
      </VStack>
    </VStack>
  );
};

export const CarouselItem = Factory(MyCarouselItem);

interface AddNewItemButtonProps extends IIconButtonProps {
  plusTooltip?: string;
}

const AddNewItemButton = ({ plusTooltip, ...props }: AddNewItemButtonProps) => (
  <Tooltip label={plusTooltip || ''} placement="top" isDisabled={!plusTooltip}>
    <Center>
      <IconButton
        _icon={{
          color: props.isDisabled ? 'sntGrey.primary' : 'black',
          size: { base: '20px', lg: '24px' },
        }}
        icon={<PlusCircleFill />}
        padding={0}
        borderColor="transparent"
        borderWidth="2px"
        borderRadius="50%"
        _light={{
          _hover: {
            borderColor: 'black',
          },
        }}
        {...props}
      />
    </Center>
  </Tooltip>
);
