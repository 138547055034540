import { NavigationAction, useIsFocused } from '@react-navigation/native';
import axios from 'axios';
import dayjs from 'dayjs';
import { Box, Button, Stack, Text, Toast } from 'native-base';
import { useCallback, useEffect, useState } from 'react';
import { DateData } from 'react-native-calendars';

import { ReschedulingCalendar } from '../../ReschedulingCalendar';
import { ConfirmationModalForBackButton } from './ConfirmationModalForBackButton';
import { DogRescheduleModal } from './DogRescheduleModal';

import { useUpdatePlanDates, Order } from '@/api';
import { LoadingSpinner, PortalPopUp, ToastType, displayToast } from '@/components/Elements';
import { useAccount } from '@/hooks';
import { ProtectedScreenProps } from '@/types';
import {
  defaultErrorHandler,
  findGeneratedOrder,
  paymentFailedErrorHandler,
  sendErrorReport,
} from '@/utils';

const scheduledEqualsSelectedDate = (scheduledDate: string, selectedDate: Date) => {
  return dayjs.utc(scheduledDate).isSame(selectedDate, 'day');
};

export const PaymentErrorMessage = () => {
  return (
    <Stack>
      Your payment method was unsuccessful.{' '}
      <Text fontWeight="bold" textDecorationLine="underline">
        Please add a new form of payment
      </Text>{' '}
      so we can start preparing your pup's order.
    </Stack>
  );
};

const rushOrderWarning = (order: Order, isPartialReschedule: boolean) => {
  const fullOrderTotal = isPartialReschedule ? '' : ` $${order?.total_price}`;
  return `By moving your order date to today, your order will be processed immediately and your card will be charged${fullOrderTotal}.`;
};

export const RescheduleDelivery = ({
  navigation,
  route,
}: ProtectedScreenProps<'RescheduleDelivery'>) => {
  const isFocused = useIsFocused();
  const account = useAccount();
  const order = account.orders.upcoming.find((order) => order.id === route.params.orderId);
  const products = order?.products || [];
  const petPlanIds = [
    ...new Set(products.map(({ pet_plan }) => pet_plan?.id).filter(Boolean)),
  ] as string[];
  const [selectedDate, setSelectedDate] = useState<Date>();
  const { mutateAsync: updatePlanDates, isLoading } = useUpdatePlanDates();
  const [isDogModalOpen, setIsDogModalOpen] = useState(false);
  const [isRushModalOpen, setIsRushModalOpen] = useState(false);
  const [showConfirmationModalForBackButton, setShowConfirmationModalForBackButton] =
    useState(false);
  const [navigateAwayAction, setNavigateAwayAction] = useState<NavigationAction>();
  const [petsOnOrderBeforeSubmit, setPetsOnOrderBeforeSubmit] = useState(petPlanIds.length);

  const [selectedPetIds, setSelectedPetIds] = useState<string[]>([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isRushOrder = dayjs.utc(new Date()).isSame(selectedDate, 'day');

  useEffect(() => {
    if (order) {
      setSelectedDate(new Date(order.scheduled));
    }
  }, [order]);

  const afterSubmit = useCallback(() => {
    if (route.params.successRedirect) {
      const order = findGeneratedOrder(
        account,
        selectedDate?.toISOString().split('T')[0],
        petsOnOrderBeforeSubmit
      );
      navigation.navigate(route.params.successRedirect, {
        orderId: order?.id,
      });
    } else if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('Home');
    }
    const pets = account.pets.filter((pet) => selectedPetIds.includes(pet.id));
    pets.forEach((pet) => {
      let message: string;
      if (isRushOrder) {
        message =
          "We'll get started on your order and send you tracking information as soon as it's officially on its way.";
      } else {
        message = `${pet.name}'s order date has been rescheduled.`;
      }
      displayToast({
        message,
        type: ToastType.Success,
      });
    });
  }, [
    account,
    isRushOrder,
    navigation,
    petsOnOrderBeforeSubmit,
    route.params.successRedirect,
    selectedDate,
    selectedPetIds,
  ]);

  useEffect(() => {
    if (isSubmitted) {
      setIsSubmitted(false);
      setSelectedPetIds([]);
      afterSubmit();
    }
  }, [isSubmitted, afterSubmit]);

  const hasUnsavedChanges =
    order && selectedDate && !scheduledEqualsSelectedDate(order.scheduled, selectedDate);

  useEffect(() => {
    const unsubscribe = navigation.addListener('beforeRemove', (e) => {
      if (hasUnsavedChanges && isFocused) {
        setNavigateAwayAction(e.data.action);
        setShowConfirmationModalForBackButton(true);
        e.preventDefault();
      }
    });
    return () => unsubscribe();
  }, [navigation, hasUnsavedChanges, isFocused]);

  if (!order || !selectedDate) {
    return <LoadingSpinner />;
  }

  const onPressToast = () => {
    Toast.close('payment_error_toast');
    navigation.navigate('PaymentMethodList');
  };

  const onConfirmationDontSave = () => {
    if (!navigateAwayAction) {
      throw new Error("User clicked Don't Save, but navigation action is missing");
    }
    navigation.dispatch(navigateAwayAction);
  };

  const handleDayPress = ({ day, month, year }: DateData) => {
    const pressedDate = dayjs.utc({ day, month: month - 1, year }).toDate();
    setSelectedDate(pressedDate);
  };

  const onPressSubmit = () => {
    setPetsOnOrderBeforeSubmit(petPlanIds.length);
    setShowConfirmationModalForBackButton(false);
    if (petPlanIds.length > 1) {
      setIsDogModalOpen(true);
    } else {
      const petIds = account.pets
        .filter((pet) => petPlanIds.includes(pet.pet_plan.id))
        .map((pet) => pet.id);
      confirmRushOrderOrSubmit(petIds);
    }
  };

  const confirmRushOrderOrSubmit = async (petIds: string[]) => {
    setIsDogModalOpen(false);
    setSelectedPetIds(petIds);
    if (isRushOrder) {
      setIsRushModalOpen(true);
    } else {
      submitDateChange(petIds);
    }
  };

  const submitDateChange = async (petIds: string[]) => {
    setIsDogModalOpen(false);
    setIsRushModalOpen(false);
    try {
      const pets = account.pets.filter((pet) => petIds.includes(pet.id));
      const petPlanIds = pets.map((pet) => pet.pet_plan.id);
      await updatePlanDates({
        petPlanIds,
        date: selectedDate.toISOString().split('T')[0],
      });
      setIsSubmitted(true);
    } catch (err) {
      if (axios.isAxiosError(err) && err?.response?.data.startsWith('Payment Failed')) {
        paymentFailedErrorHandler(<PaymentErrorMessage />, onPressToast);
      } else {
        defaultErrorHandler();
      }
      sendErrorReport(err);
    }
  };

  return (
    <Stack
      w="100%"
      h="100%"
      paddingBottom={{ base: '16px', md: '24px' }}
      px="16px"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack w={{ base: '100%', md: '396px' }} space="8px">
        <ReschedulingCalendar selectedDate={selectedDate} onDayPress={handleDayPress} />
      </Stack>
      <Box w={{ base: '100%', md: '357px' }}>
        <Button
          w="100%"
          variant="primary"
          isDisabled={!hasUnsavedChanges}
          isLoading={isLoading}
          onPress={onPressSubmit}
        >
          Reschedule
        </Button>
      </Box>
      <DogRescheduleModal
        initialPetPlanIds={petPlanIds}
        isOpen={isDogModalOpen}
        onConfirm={confirmRushOrderOrSubmit}
        chargeDate={selectedDate}
        onClose={() => setIsDogModalOpen(false)}
      />
      <PortalPopUp
        heading="Reschedule order to today?"
        description={rushOrderWarning(order, selectedPetIds.length < petPlanIds.length)}
        modalContentProps={{ size: { lg: 'md' }, height: 'auto' }}
        backBtnText="Cancel"
        onConfirm={() => submitDateChange(selectedPetIds)}
        onClose={() => setIsRushModalOpen(false)}
        onPressX={() => setIsRushModalOpen(false)}
        isOpen={isRushModalOpen}
      />
      <ConfirmationModalForBackButton
        isOpen={showConfirmationModalForBackButton}
        handleSavePress={onPressSubmit}
        handleCancelPress={onConfirmationDontSave}
        onClose={() => setShowConfirmationModalForBackButton(false)}
      />
    </Stack>
  );
};
