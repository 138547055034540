import {
  Box,
  Pressable,
  Heading,
  Text,
  Spacer,
  Badge,
  Stack,
  IPressableProps,
  HStack,
} from 'native-base';

import { Pricing } from '../ProductModal/Pricing';

import { Recipe } from '@/api/types';
import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { getRecipesProductImageSrc } from '@/utils';
const images = `${ASSETS_URL}/pictos/`;

export interface ProductCardProps extends IPressableProps {
  recipe: Recipe;
  heading: string;
  pricing: string | number | null;
  pricingUnit?: string;
  selected?: boolean;
  infoPills?: string[];
  pricingPrefix?: string;
  showInclusions?: boolean;
}

export const ProductCard = ({
  recipe,
  heading,
  pricing,
  pricingUnit = 'meal',
  selected = false,
  infoPills,
  pricingPrefix = '',
  showInclusions = true,
  ...props
}: ProductCardProps) => {
  return (
    <Pressable
      variant={selected ? 'productCardSelected' : 'productCard'}
      w="100%"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Box w="100%" px={{ base: 1, lg: 2 }}>
        <HStack
          alignItems="flex-start"
          w="100%"
          justifyContent={{ lg: 'center' }}
          pb={{ base: 2, lg: 4 }}
          mx={{ sm: 2, lg: 4 }}
          space={4}
        >
          <Image
            source={{ uri: getRecipesProductImageSrc([recipe], showInclusions) }}
            alt={recipe.name}
            width={{ base: '100px', lg: '200px' }}
            height={{ base: '100px', lg: '200px' }}
            resizeMode="contain"
          />
          <Stack
            flexWrap="wrap"
            justifyContent="center"
            pt={{ base: 2, lg: 4 }}
            pl={{ base: 1, md: 4 }}
            pr={{ base: 0, md: 4 }}
            space={{ base: 2, lg: 4 }}
          >
            <Heading fontWeight="bold" size="bodyMlToTitleSm" width={{ base: '94%', md: '100%' }}>
              {heading}
            </Heading>
            {infoPills && infoPills.length > 0 ? (
              <HStack
                w={{ base: 'fit-content', lg: '212px' }}
                space={1}
                maxW={{ base: '84%', md: '100%' }}
                flexWrap={{ base: 'wrap', md: 'nowrap' }}
                justifyContent="start"
              >
                {infoPills.map((text, i) => (
                  <Badge key={i} size="tinyToBodyMd" mb="2px">
                    {text}
                  </Badge>
                ))}
              </HStack>
            ) : null}
            <HStack w="100%">
              <Pricing
                pricing={Number(pricing)}
                pricingUnit={pricingUnit}
                recipeType={recipe.type}
                isTrial
                pricingPrefix={pricingPrefix}
              />
            </HStack>
          </Stack>
        </HStack>
        {recipe.mini_pictos.length > 0 && (
          <HStack justifyContent="space-between" alignItems="center" px={{ base: 0, lg: 5 }}>
            {recipe.mini_pictos.map((picObj, i) => (
              <Stack
                key={`${picObj.text}${i}`}
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                maxW={{ base: '52px', lg: '68px' }}
              >
                <Image
                  size={{ base: 9, lg: 12 }}
                  source={{ uri: `${images}CardIcons/${picObj.slug}.png` }}
                  alt={picObj.text}
                  resizeMode="contain"
                />
                <Spacer size={1} />
                <Text size="tinyToCaption" fontWeight="bold">
                  {picObj.text}
                </Text>
              </Stack>
            ))}
          </HStack>
        )}
      </Box>
    </Pressable>
  );
};
