import { ScrollView, Stack, useBreakpointValue } from 'native-base';
import { useRef, useState } from 'react';

import { BannerCarousel } from './BannerCarousel';
import { ProductSection } from './ProductSection';
import { ShopAnchorBar } from './ShopAnchorBar';

import { Image } from '@/components/Elements';
import { Footer } from '@/components/Portal';
import { ASSETS_URL } from '@/constants';
import { useIsMobile } from '@/hooks';

export const ShopTab = () => {
  const snacksRef = useRef<React.RefObject<HTMLDivElement>>(null);
  const supplementsRef = useRef<React.RefObject<HTMLDivElement>>(null);
  const merchRef = useRef<React.RefObject<HTMLDivElement>>(null);

  const [scrollOffset, setScrollOffset] = useState(0);

  const isMobile = useIsMobile();

  const refsList = [
    { title: 'Snacks', ref: snacksRef },
    { title: 'Supplements', ref: supplementsRef },
    { title: 'Merch', ref: merchRef },
  ];

  const stickyHeaderIndex = useBreakpointValue({ base: 0, lg: undefined });

  const tempImgURI = `${ASSETS_URL}/pictos/MarketingCards/APRIL_ST_EVAN_23711.png`;

  const scrollViewDirection = useBreakpointValue({ base: 'column', lg: 'row' });

  return (
    <Stack w="100%" h="100%" alignItems="center">
      <ScrollView
        style={{ width: '100%' }}
        contentContainerStyle={{
          justifyContent: 'center',
          flexDirection: scrollViewDirection,
        }}
        onScroll={(e) => setScrollOffset(e.nativeEvent.contentOffset.y)}
        scrollEventThrottle={300}
        stickyHeaderIndices={[stickyHeaderIndex]}
      >
        <ShopAnchorBar refsList={refsList} scrollOffset={scrollOffset} />
        <Stack
          space={9}
          w="100%"
          maxW={{ lg: '1024px' }}
          mt={{ base: 6, lg: 0 }}
          mb={{ base: '524px', lg: '478px' }}
          pt={{ base: 0, lg: 12 }}
        >
          {isMobile ? (
            <BannerCarousel uris={[tempImgURI, tempImgURI, tempImgURI]} />
          ) : (
            <Stack
              borderRadius="8px"
              overflow="hidden"
              w="100%"
              maxW={{ lg: '1024px' }}
              h={{ base: '140px', lg: '320px' }}
              mt={{ base: 6, lg: 0 }}
            >
              <Image
                w="100%"
                h="100%"
                resizeMode="cover"
                source={{ uri: `${ASSETS_URL}/pictos` }}
              />
            </Stack>
          )}
          <Stack space={{ base: 9, lg: 12 }} px={{ base: 4, lg: 0 }}>
            {refsList.map(({ title, ref }) => {
              return <ProductSection title={title} ref={ref} />;
            })}
          </Stack>
        </Stack>
        <Footer position="absolute" bottom={0} left={0} />
      </ScrollView>
    </Stack>
  );
};
