import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';

export type ProductBodyData = {
  code: string;
  quantity: number;
  recurring: boolean;
};

const postAddon = ({ petPlanId, products }: { petPlanId: string; products: ProductBodyData[] }) => {
  const currentUrl = window.location.href;
  return axios
    .post(`/pet_plans/${petPlanId}/products`, { products, page_path: currentUrl })
    .then((response) => response.data);
};

export const useUpdateAddon = () => {
  return useMutation(
    ({ petPlanId, products }: { petPlanId: string; products: ProductBodyData[] }) =>
      postAddon({ petPlanId, products })
  );
};
