import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';

const checkValidEmail = (email: string) => {
  return axios
    .post<string>(`/sessions/check_valid_email`, { email }, { noErrorToast: true })
    .then((res) => res.data);
};

export const useCheckValidEmail = () => {
  return useMutation(checkValidEmail);
};
