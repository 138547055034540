import { responsiveSizes } from '@/theme/typography/fontSizes';

const baseStyle = {
  _text: {
    fontWeight: 'bold',
  },
  _loading: {
    opacity: 1,
    cursor: 'pointer',
  },
};

const _badgeBase = {
  ...responsiveSizes.bodyMdToLg,
  w: 'fit-content',
  px: { base: '16px', lg: '24px' },
  py: '10px',
  borderRadius: { base: '25px', lg: '30px' },
};
const _badgeSmallBase = {
  px: { base: '16px', lg: '24px' },
  py: { base: '7px', lg: '10px' },
  borderRadius: { base: '25px', lg: '30px' },
  ...responsiveSizes.bodySmToMd,
};

const variants = {
  primary: {
    bg: 'sntBlue.primary',
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: { base: '2px', lg: '3px' },
    borderRadius: 0,
    w: { base: '100%', lg: 'fit-content' },
    py: { base: '14px', lg: '16px' },
    px: { base: '14px', lg: '125px' },
    ...responsiveSizes.bodyMlToLg,
    _light: {
      _text: {
        textTransform: 'uppercase',
      },
      _hover: {
        bg: 'sntBlue.hover',
      },
      _disabled: {
        opacity: 1, // disable NativeBase's _disabled partial opacity
        bg: 'white',
        borderColor: 'sntGrey.primary',
        _text: {
          color: 'sntGrey.primary',
        },
      },
      _pressed: {
        bg: 'black',
        _text: {
          color: 'white',
        },
      },
    },
  },
  secondary: {
    bg: 'white',
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: { base: '2px', lg: '3px' },
    borderRadius: 0,
    w: { base: '100%', lg: 'fit-content' },
    py: { base: '14px', lg: '16px' },
    px: { base: '14px', lg: '125px' },
    ...responsiveSizes.bodyMlToLg,
    _light: {
      _text: {
        textTransform: 'uppercase',
      },
      _hover: {
        bg: 'gallery',
      },
      _disabled: {
        opacity: 1, // disable NativeBase's _disabled partial opacity
        bg: 'white',
        borderColor: 'sntGrey.primary',
        _text: {
          color: 'sntGrey.primary',
        },
      },
      _pressed: {
        bg: 'black',
        _text: {
          color: 'white',
        },
      },
    },
  },
  inline: {
    padding: 0,
    w: 'fit-content',
    borderWidth: '0px',
    ...responsiveSizes.bodyMdToLg,
    _light: {
      _text: {
        color: 'black',
      },
      _icon: {
        color: 'black',
      },
      _disabled: {
        _text: {
          color: 'sntGrey.primary',
        },
        _icon: {
          color: 'sntGrey.primary',
        },
      },
      _hover: {
        textDecoration: 'underline',
      },
      _pressed: {
        textDecorationColor: 'sntBlue.primary',
        _text: {
          color: 'sntBlue.primary',
        },
        _icon: {
          color: 'sntBlue.primary',
        },
      },
    },
  },
  underlineMini: {
    padding: 0,
    w: 'fit-content',
    textDecoration: 'underline',
    ...responsiveSizes.bodySmToMd,
    _light: {
      _text: {
        color: 'black',
        fontWeight: 'bold',
      },
      _disabled: {
        color: 'sntGrey.primary', // colors underline
        _text: {
          color: 'sntGrey.primary',
          textDecorationColor: 'sntGrey.primary',
        },
        _icon: {
          color: 'sntGrey.primary',
        },
      },
      _hover: {
        color: 'sntBlue.hover', // colors underline
        _text: {
          color: 'sntBlue.hover',
          textDecorationColor: 'sntBlue.hover',
        },
        _icon: {
          color: 'sntBlue.hover',
        },
      },
      _pressed: {
        color: 'sntBlue.primary', // colors underline
        _text: {
          color: 'sntBlue.primary',
          textDecorationColor: 'sntBlue.primary',
        },
        _icon: {
          color: 'sntBlue.hover',
        },
      },
      _icon: {
        color: 'black',
      },
    },
  },
  hugPrimary: {
    bg: 'sntBlue.primary',
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderRadius: 0,
    w: 'fit-content',
    h: { base: '40px', lg: '48px' },
    px: { base: '12px', lg: '36px' },
    ...responsiveSizes.bodyMlToLg,
    _light: {
      _hover: {
        bg: 'sntBlue.hover',
      },
      _disabled: {
        opacity: 1, // disable NativeBase's _disabled partial opacity
        bg: 'white',
        borderColor: 'sntGrey.primary',
        _text: {
          color: 'sntGrey.primary',
        },
      },
      _pressed: {
        bg: 'black',
        _text: {
          color: 'white',
        },
      },
    },
  },
  hugSecondary: {
    bg: 'white',
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderRadius: 0,
    w: 'fit-content',
    h: { base: '40px', lg: '48px' },
    px: { base: '12px', lg: '36px' },
    ...responsiveSizes.bodyMlToLg,
    _light: {
      _hover: {
        bg: 'gallery',
      },
      _disabled: {
        opacity: 1, // disable NativeBase's _disabled partial opacity
        borderColor: 'sntGrey.primary',
        _text: {
          color: 'sntGrey.primary',
        },
      },
      _pressed: {
        bg: 'black',
        borderColor: 'black',
        _text: {
          color: 'white',
        },
      },
    },
  },
  hugTertiary: {
    bg: 'white',
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderRadius: 0,
    w: 'fit-content',
    h: '36px',
    px: { base: '12px', lg: '36px' },
    ...responsiveSizes.bodySmToMd,
    _light: {
      _hover: {
        bg: 'gallery',
      },
      _disabled: {
        opacity: 1, // disable NativeBase's _disabled partial opacity
        borderColor: 'sntGrey.primary',
        _text: {
          color: 'sntGrey.primary',
        },
      },
      _pressed: {
        bg: 'black',
        borderColor: 'black',
        _text: {
          color: 'white',
        },
      },
    },
  },
  badgeBold: {
    ..._badgeBase,
    bg: 'sntBlue.primary',
    borderWidth: '1px',
    borderColor: 'transparent', // allows seamless toggle to outline variant
    _light: {
      _hover: {
        bg: 'sntBlue.hover',
      },
      _text: {
        color: 'black',
      },
      _icon: {
        color: 'black',
      },
      _disabled: {
        opacity: 1, // disable NativeBase's _disabled partial opacity
        background: 'sntBlue.disabled',
        _text: {
          color: 'black',
        },
      },
    },
  },
  badgeBoldOutline: {
    ..._badgeBase,
    bg: 'white',
    borderWidth: '1px',
    borderColor: 'sntBlue.primary',
    _light: {
      _hover: {
        borderColor: 'sntBlue.hover',
      },
      _text: {
        color: 'black',
      },
      _icon: {
        color: 'black',
      },
      _disabled: {
        opacity: 1, // disable NativeBase's _disabled partial opacity
        borderColor: 'sntBlue.disabled',
        _text: {
          color: 'sntGrey.primary',
        },
      },
    },
  },
  badgeBoldError: {
    ..._badgeBase,
    bg: 'error.default',
    _light: {
      _hover: {
        bg: 'error.hover',
      },
      _text: {
        color: 'white',
      },
      _icon: {
        color: 'white',
      },
    },
  },
  badgeBoldOutlineError: {
    ..._badgeBase,
    bg: 'white',
    borderWidth: '1px',
    borderColor: 'error.default',
    _light: {
      _hover: {
        borderColor: 'error.hover',
        _text: {
          color: 'error.hover',
        },
      },
      _text: {
        color: 'error.default',
      },
      _icon: {
        color: 'error.default',
      },
    },
  },
  badgeSmall: {
    ..._badgeSmallBase,
    borderWidth: '1px',
    borderColor: 'transparent', // allows seamless toggle to outline variant
    bg: 'sntBlue.primary',
    _light: {
      _hover: {
        bg: 'sntBlue.hover',
      },
      _text: {
        color: 'black',
      },

      _icon: {
        color: 'black',
      },
      _disabled: {
        opacity: 1, // disable NativeBase's _disabled partial opacity
        background: 'sntBlue.disabled',
        _text: {
          color: 'black',
        },
      },
    },
  },
  badgeSmallOutline: {
    ..._badgeSmallBase,
    bg: 'white',
    borderWidth: '1px',
    borderColor: 'sntBlue.primary',
    _light: {
      _hover: {
        borderColor: 'sntBlue.hover',
      },
      _text: {
        color: 'black',
      },
      _icon: {
        color: 'black',
      },
    },
    _disabled: {
      opacity: 1, // disable NativeBase's _disabled partial opacity
      borderColor: 'sntBlue.disabled',
      _text: {
        color: 'sntGrey.primary',
      },
    },
  },
  badgeSmallInfo: {
    ..._badgeSmallBase,
    bg: 'sntBlue.primary',
    px: { base: 2, lg: 3 },
    py: { base: '6px', lg: 2 },
    _light: {
      _hover: {
        bg: 'sntBlue.hover',
      },
      _text: {
        color: 'black',
      },

      _icon: {
        color: 'black',
      },
      _disabled: {
        opacity: 1, // disable NativeBase's _disabled partial opacity
        background: 'sntBlue.disabled',
        _text: {
          color: 'black',
        },
      },
    },
  },
  badgeLargeInfo: {
    ..._badgeBase,
    bg: 'sntBlue.primary',
    px: 3,
    py: { base: 2, lg: 3 },
    _light: {
      opacity: 1,
      _hover: {
        bg: 'sntBlue.hover',
      },
      _text: {
        color: 'black',
      },

      _icon: {
        color: 'black',
      },
      _disabled: {
        opacity: 1, // disable NativeBase's _disabled partial opacity
        background: 'sntBlue.disabled',
        _text: {
          color: 'black',
        },
      },
    },
  },
  portalNav: {
    _text: {
      fontSize: { base: 'body.md', xl: 'body.lg' },
      lineHeight: { base: 'body.md', xl: 'body.lg' },
    },
    fontWeight: 'bold',
  },
  burgerMenuNav: {
    padding: 0,
    w: 'fit-content',
    borderWidth: '0px',
    ...responsiveSizes.titleSm,
    fontWeight: 'bold',
  },
};

const defaultProps = {
  variant: 'primary',
  size: 'NativeBaseSizeHack',
};

const ButtonGroup = {
  variants: {
    modalFooter: {
      bgColor: 'aquaIsland',
    },
  },
};

export const Button = {
  baseStyle,
  variants,
  sizes: responsiveSizes,
  defaultProps,
  ButtonGroup,
};
