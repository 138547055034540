import { useMemo } from 'react';

import { UsePlanOptionsQueryData, usePlans } from './usePlans';

import { UnkibbleFreshTopper, PricingModelStatus, RecipeType } from '@/api';
import { cheapestPlanByProduct } from '@/utils';

/**
 * Helper hook to wrap usePlans and expose common data structures
 */
export const useRecipeOptions = ({
  pet,
  discounts,
  sessionId,
  accountId,
}: UsePlanOptionsQueryData) => {
  const { data, isLoading, isIdle } = usePlans({ pet, discounts, sessionId, accountId });
  return useMemo(() => {
    if (!data) {
      return { data: undefined, isLoading, isIdle };
    }

    function filterByActiveStatus(pricesData: UnkibbleFreshTopper[]) {
      return pricesData.filter(
        (meal) => meal.subscription.price.pricing_model.status === PricingModelStatus.ACTIVE
      );
    }

    const unkibblePlans = filterByActiveStatus(data.prices.unkibble);
    const freshPlans = filterByActiveStatus(data.prices.fresh);
    const cheapestPlans = cheapestPlanByProduct(
      [...unkibblePlans, ...freshPlans],
      'trial',
      'price_per_meal'
    );

    const recipeOptionsData = {
      planSize: data.plan_size.id,
      maxRecipes: {
        [RecipeType.FRESH]: data.max_recipes_fresh,
        [RecipeType.UNKIBBLE]: data.max_recipes_unkibble,
      },
      plans: {
        [RecipeType.FRESH]: freshPlans,
        [RecipeType.UNKIBBLE]: unkibblePlans,
      },
      cheapestPlans: [cheapestPlans[RecipeType.UNKIBBLE], cheapestPlans[RecipeType.FRESH]],
    };
    return { data: recipeOptionsData, isLoading, isIdle };
  }, [data, isIdle, isLoading]);
};
