import Constants from 'expo-constants';

const getEnvVar = (name: string): string =>
  Constants?.expoConfig?.extra?.[name] || process.env[name];

export const API_URL = getEnvVar('API_URL');
export const STRIPE_PUBLISHABLE_KEY = getEnvVar('STRIPE_PUBLISHABLE_KEY');
export const TRACKING_URL = getEnvVar('TRACKING_URL');
export const HAS_DATADOG = getEnvVar('HAS_DATADOG');
export const ENVIRONMENT = getEnvVar('DATADOG_ENV_NAME');
export const SEGMENT_API_KEY = getEnvVar('SEGMENT_API_KEY');
export const SHOW_SHOP_TAB = getEnvVar('SHOW_SHOP_TAB');
