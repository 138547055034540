import { HStack, IStackProps, VStack } from 'native-base';

import { useIsMobile } from '@/hooks';

export const VToHStack = ({ ...props }: IStackProps) => {
  const isMobile = useIsMobile();
  // Allows the space prop to be used in either a
  // vertical or horizontal flex direction
  return isMobile ? <VStack {...props} /> : <HStack {...props} />;
};
