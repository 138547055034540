import { axios } from '@/lib/axios';

type NameAndEmail = {
  email: string;
  first_name: string;
};

export const get_or_create_by_email = (data: NameAndEmail) =>
  axios
    .post(`/sessions/get_or_create_by_email`, data, { noErrorToast: true })
    .then((res) => res.data);
