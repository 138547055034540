import { capitalize } from './string';

import { OrderProduct, PetPlanRecipeData, RecipeType } from '@/api';
import { ASSETS_URL } from '@/constants';

export const getMealRecipeName = (recipeType: RecipeType) =>
  recipeType === RecipeType.UNKIBBLE ? 'UnKibble' : 'Fresh';

export const getSampleMealRecipeName = (mealProduct: OrderProduct) => {
  return `${mealProduct.quantity} x ${getMealRecipeName(mealProduct.recipes[0].type)} (Sample)`;
};

export const getRecipeImageSrc = (
  recipes: PetPlanRecipeData[],
  section: 'FoodType' | 'PortionSize'
) => {
  const foodType = capitalize(recipes[0].type);
  const imgSrc = `${ASSETS_URL}/pictos/PetPlans/${section}/${foodType}`;

  let recipeFilename = `${recipes[0].id}.png`;
  switch (recipes[0].type) {
    case RecipeType.UNKIBBLE:
      if (recipes.some((recipe) => recipe.id === 'U-B')) {
        recipeFilename = 'U-B.png';
      } else if (recipes.some((recipe) => recipe.id === 'U-C')) {
        recipeFilename = 'U-C.png';
      } else if (recipes.some((recipe) => recipe.id === 'U-S')) {
        recipeFilename = 'U-S.png';
      } else {
        recipeFilename = 'U-T.png';
      }
      break;
    case RecipeType.FRESH:
      if (section === 'PortionSize') {
        recipeFilename = 'FreshPortion.png';
      } else if (recipes.some((recipe) => recipe.id === 'F-B')) {
        recipeFilename = 'F-B.png';
      } else if (recipes.some((recipe) => recipe.id === 'F-L')) {
        recipeFilename = 'F-L.png';
      } else {
        recipeFilename = 'F-T.png';
      }
      break;
  }
  return `${imgSrc}/${recipeFilename}`;
};

/**
 * Given a list of recipe IDs/types, return the image URL for the product image.
 *
 * Set `inclusions` to false to get no-inclusion versions for UnKibble images
 */
export const getRecipesProductImageSrc = (
  recipes: { id: string; type: RecipeType }[],
  inclusions = true,
  image_uri?: string | null
) => {
  if (image_uri) {
    return `${ASSETS_URL}/${image_uri}`;
  }

  const recipeType = recipes[0].type;
  const noInclusionPath = !inclusions && recipeType === RecipeType.UNKIBBLE ? 'portal/' : '';
  const recipeFilename = recipes
    .map(({ id }) => id)
    .sort()
    .join('');

  return `${ASSETS_URL}/pictos/${capitalize(recipeType)}/${noInclusionPath + recipeFilename}.png`;
};

export const getRecipesBowlImageSrc = (
  recipes: string[],
  recipeType: RecipeType.FRESH | RecipeType.UNKIBBLE,
  isTopper: boolean
) => {
  return `${ASSETS_URL}/pictos/${recipeType === RecipeType.UNKIBBLE ? 'Unkibble' : 'Fresh'}/bowls/${
    isTopper ? 'topper/' : ''
  }${recipes.sort().join('')}.webp`;
};
