import { Text, View } from 'native-base';

import { Cart, DiscountLimitType } from '@/api';
import { joinWithCommas } from '@/utils';

interface OrderSummaryExplainerProps {
  cart: Cart['cart_preview'];
}

export const OrderSummaryExplainer = ({ cart }: OrderSummaryExplainerProps) => {
  const discount = cart.plans[0]?.discounts?.[0]; // only one discount per session is supported

  let subscriptionExplainer: React.ReactNode[] | string;
  let nextXOrdersExplainer = '';
  if (
    discount &&
    discount.limit_type === DiscountLimitType.X_ORDERS_PER_CUSTOMER_SUBSCRIPTION_TRIAL_SPLIT
  ) {
    subscriptionExplainer = [];
    for (let index = 0; index < cart.plans.length; index++) {
      const { plan, pet_name } = cart.plans[index];
      const pricePerWeek = plan.subscription.price.price_per_week;
      const discountedPricePerWeek = plan.subscription.price.discounted_price_per_week;
      const frequency = plan.subscription.product.frequency;
      const element = (
        <>
          {pet_name}'s plan will be billed and delivered every {frequency}{' '}
          {frequency === 1 ? 'week' : 'weeks'} at{' '}
          <Text size="bodySmToMd" fontWeight="medium" fontFamily="secondary" strikeThrough>
            ${pricePerWeek}
          </Text>{' '}
          ${discountedPricePerWeek}/week{index < cart.plans.length - 1 ? ', ' : ''}
        </>
      );
      subscriptionExplainer.push(element);
      if (cart.plans.length > 1 && index === cart.plans.length - 2) {
        subscriptionExplainer.push(<> and </>);
      }
    }
    const orderLanguage = discount.limit_value === 1 ? 'order' : `${discount.limit_value} orders`;
    nextXOrdersExplainer = ` This discount will apply to your next ${orderLanguage}.`;
  } else {
    const explainerParts = cart.plans.map(({ plan, pet_name }) => {
      const pricePerWeek = plan.subscription.price.price_per_week;
      const frequency = plan.subscription.product.frequency;
      return `${pet_name}'s plan will be billed and delivered every ${frequency} ${
        frequency === 1 ? 'week' : 'weeks'
      } at $${pricePerWeek}/week`;
    });
    subscriptionExplainer = joinWithCommas(explainerParts);
  }

  return (
    <View display="inline-block">
      <Text fontWeight="bold" size="bodySmToMd" fontFamily="secondary">
        After the trial{' '}
      </Text>
      <Text size="bodySmToMd" fontWeight="medium" fontFamily="secondary">
        {subscriptionExplainer}, plus any selected add-ons.{nextXOrdersExplainer}
      </Text>
    </View>
  );
};
