import axios from 'axios';
import { FormControl, Heading, Stack } from 'native-base';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { LoginResolver } from '../resolvers';
import { BARK_ACCOUNT_ERROR, LoginErrorMessage, PENDING_ACCOUNT_ERROR } from './LoginErrorMessage';

import { LoginCredentials } from '@/api';
import { Button, InputFloatLabel } from '@/components/Elements';
import { useAuth, useUTT } from '@/hooks';
import { PublicScreenProps } from '@/types';

const defaultValues = {
  email: '',
  password: '',
};

export const LoginForm = ({ navigation }: Omit<PublicScreenProps<'Login'>, 'route'>) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();
  const { UTTidentify } = useUTT();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<LoginCredentials>({ resolver: LoginResolver, defaultValues, mode: 'onBlur' });

  const onSubmit: SubmitHandler<LoginCredentials> = async (data) => {
    setIsLoading(true);
    try {
      const userRes = await login({ email: data.email, password: data.password });
      navigation.navigate('ProtectedScreens', {});
      UTTidentify(userRes?.id || '', userRes?.email || '');
      window.analytics?.identify(userRes?.email, {
        account_id: userRes?.id,
      });
      window.hj('identify', userRes?.id, {
        email: userRes?.email,
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (
          axios.isAxiosError(error) &&
          error?.response?.status === 403 &&
          error?.response?.data === PENDING_ACCOUNT_ERROR
        ) {
          setLoginError(PENDING_ACCOUNT_ERROR);
        } else if (
          axios.isAxiosError(error) &&
          error?.response?.status === 403 &&
          error?.response?.data === BARK_ACCOUNT_ERROR
        ) {
          setLoginError(BARK_ACCOUNT_ERROR);
        } else {
          setLoginError(`The email or password you have entered is incorrect.`);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const submitIsDisabled =
    !watch().password || !watch().email || !!loginError || Object.keys(errors).length !== 0;

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      space={{ base: '16px', lg: '24px' }}
      w={{ base: '100%', sm: '80%', md: '540px' }}
      maxW="540px"
      px={{ base: 4, lg: 0 }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="start"
        w="100%"
        h="fit-content"
        borderColor="sntGrey.outline"
        borderWidth="1px"
        borderStyle="solid"
        p={{ base: '16px', lg: '24px' }}
        space={{ base: '16px', lg: '24px' }}
      >
        <Heading size="bodyMdToLg" fontWeight="bold">
          Please log in to continue
        </Heading>
        <FormControl isRequired isInvalid={!!errors.email}>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <Stack space="8px">
                  <InputFloatLabel
                    onBlur={onBlur}
                    onChangeText={(val) => {
                      onChange(val);
                      setLoginError(undefined);
                    }}
                    onSubmitEditing={handleSubmit(onSubmit)}
                    value={value}
                    placeholder="Email"
                    keyboardType="email-address"
                    type="text"
                    testID="input-email"
                  />
                  {errors?.email?.message ? (
                    <FormControl.ErrorMessage size="bodySmToMd">
                      {errors?.email?.message}
                    </FormControl.ErrorMessage>
                  ) : null}
                </Stack>
              );
            }}
          />
        </FormControl>

        <FormControl isRequired isInvalid={'password' in errors}>
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="start"
                  w="100%"
                  space="8px"
                >
                  <InputFloatLabel
                    onBlur={onBlur}
                    onChangeText={(val) => {
                      onChange(val);
                      setLoginError(undefined);
                    }}
                    onSubmitEditing={handleSubmit(onSubmit)}
                    value={value}
                    placeholder="Password"
                    secureTextEntry={!showPassword}
                    type="password"
                    textAlign="start"
                    testID="input-password"
                    InputRightElement={
                      <Button
                        variant="inline"
                        size="bodySmToMd"
                        p={0}
                        isDisabled={!watch('password')}
                        onPress={() => setShowPassword((prevShowPassword) => !prevShowPassword)}
                      >
                        {showPassword ? 'HIDE' : 'SHOW'}
                      </Button>
                    }
                  />
                  {errors?.password?.message ? (
                    <FormControl.ErrorMessage size="bodySmToMd">
                      {errors?.password?.message}
                    </FormControl.ErrorMessage>
                  ) : null}
                </Stack>
              );
            }}
          />
          <LoginErrorMessage loginError={loginError} />
        </FormControl>
        <Stack space={{ base: '16px', lg: '24px' }}>
          <Button
            onPress={() => navigation.navigate('SetPassword', { variant: 'reset' })}
            variant="underlineMini"
            size="bodySmToMd"
          >
            Forgot Password?
          </Button>

          <Button
            onPress={() => navigation.navigate('SetPassword', { variant: 'firsttime' })}
            variant="underlineMini"
            size="bodySmToMd"
          >
            First Time Logging In?
          </Button>
        </Stack>
      </Stack>
      <Button
        variant="primary"
        w={{ base: '100%', lg: '290px' }}
        size="bodyMlToLg"
        onPress={handleSubmit(onSubmit)}
        isLoading={isLoading}
        isDisabled={submitIsDisabled}
        testID="button-login"
      >
        Login
      </Button>
    </Stack>
  );
};
