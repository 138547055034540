import { useFocusEffect } from '@react-navigation/native';
import { Button, HStack, IStackProps, Stack, VStack } from 'native-base';
import { useCallback, useMemo, useState } from 'react';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { useAccount, useIsMobile } from '@/hooks';
import segment from '@/segment';

type RefObject = {
  title: string;
  ref: React.MutableRefObject<any>;
};

interface StickyWrapperProps extends IStackProps {
  isMobile: boolean;
}

const StickyWrapper = ({ isMobile, ...props }: StickyWrapperProps) => {
  return isMobile ? (
    <HStack
      justifyContent="center"
      alignItems="center"
      space={4}
      position="sticky"
      top={0}
      left={0}
      {...props}
    />
  ) : (
    <VStack
      justifyContent="center"
      alignItems="start"
      space={9}
      py="72px"
      position="sticky"
      top={0}
      left={0}
      {...props}
    />
  );
};

export const ShopAnchorBar = ({
  refsList,
  scrollOffset,
  ...props
}: {
  refsList: RefObject[];
  scrollOffset: number;
} & IStackProps) => {
  const [selectedRef, setSelectedRef] = useState<React.RefObject<HTMLDivElement> | undefined>(
    undefined
  );
  const [isLoadingManualScroll, setIsLoadingManualScroll] = useState(false);
  const isMobile = useIsMobile();
  const account = useAccount();

  const refsSortedByOffset = useMemo(() => {
    return refsList.sort((refA, refB) => {
      if (refA?.ref?.current && refB?.ref?.current) {
        return refA?.ref?.current?.offsetTop - refB?.ref?.current?.offsetTop;
      } else {
        return 0;
      }
    });
  }, [refsList]);

  const handlePress = (ref: React.MutableRefObject<HTMLDivElement>, title: string) => {
    setIsLoadingManualScroll(true);
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    setIsLoadingManualScroll(false);

    if (account && title) {
      segment.trackEvent('Clicked Shop Page Navigation', {
        account_id: account.id,
        email: account.email,
        section_clicked: title,
      });
    }
  };

  useFocusEffect(
    useCallback(() => {
      if (isLoadingManualScroll) return;

      const inViewRefObj = refsSortedByOffset.find((refObj) => {
        if (!refObj.ref?.current) return;
        const refOffset = refObj.ref.current.offsetTop;
        const refHeight = refObj.ref.current.clientHeight;
        return refOffset + refHeight >= scrollOffset;
      });
      if (inViewRefObj?.ref && inViewRefObj.ref !== selectedRef) {
        setSelectedRef(inViewRefObj.ref);
      }
    }, [refsSortedByOffset, selectedRef, scrollOffset, isLoadingManualScroll])
  );

  const dropShadow = isMobile
    ? {
        bg: 'white',
        style: {
          shadowColor: 'rgba(0,0,0,0.15)',
          shadowOffset: { width: -4, height: 4 },
          shadowRadius: 4,
        },
      }
    : {};

  return (
    <Stack
      w={{ base: '100%', lg: 'fit-content' }}
      h={{ base: 'fit-content', lg: '100%' }}
      pr={{ base: 0, lg: 12 }}
      pl={{ base: 0, lg: '84px' }}
      {...props}
    >
      <StickyWrapper isMobile={isMobile} {...dropShadow}>
        {refsList.map(({ title, ref }, index) => {
          return (
            <Stack
              space={{ base: 1, lg: 0 }}
              alignItems="center"
              justifyContent="end"
              py={{ base: 2, lg: 0 }}
              borderBottomWidth="2px"
              borderBottomColor={selectedRef === ref && isMobile ? 'black' : 'transparent'}
            >
              {isMobile ? (
                <Image
                  source={{ uri: `${ASSETS_URL}/pictos` }}
                  w={{ base: '34px', lg: '67px' }}
                  h={{ base: '30px', lg: '40px' }}
                />
              ) : null}
              <Button
                key={index}
                variant="inline"
                _text={{ color: selectedRef === ref ? 'black' : 'sntGrey.primary' }}
                onPress={() => handlePress(ref, title)}
                _hover={{ textDecoration: 'none', _text: { color: 'sntBlue.hover' } }}
              >
                {isMobile ? title : title.toLocaleUpperCase()}
              </Button>
              {!isMobile ? (
                <Stack w="100%" alignItems="center">
                  <Image
                    w="100%"
                    h="6px"
                    resizeMode="contain"
                    opacity={selectedRef === ref ? 1 : 0}
                    source={{ uri: `${ASSETS_URL}/FAQ/Underline.png` }}
                  />
                </Stack>
              ) : null}
            </Stack>
          );
        })}
      </StickyWrapper>
    </Stack>
  );
};
