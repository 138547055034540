import { Divider, HStack, Heading, Stack, Text } from 'native-base';

import { OrderProduct, ProductType } from '@/api/types';
import { formatPrice, getMealRecipeName, getWeeksOfFood } from '@/utils';

export interface FoodSectionProps {
  products: OrderProduct[];
  type: 'Meals' | 'Snacks' | 'Extras' | 'Supplements';
}

export const FoodSection = ({ products, type }: FoodSectionProps) => {
  if (products.length === 0) {
    return null;
  }

  const getRecipeText = (orderProduct: OrderProduct) => {
    if (type === 'Meals') {
      const mealRecipeName = getMealRecipeName(orderProduct.recipes[0].type);
      const weeksOfFood = getWeeksOfFood(orderProduct.code);
      if (weeksOfFood) {
        return (
          (orderProduct.product_type === ProductType.SAMPLE ? `${orderProduct.quantity} x ` : '') +
          `${mealRecipeName} ${weeksOfFood}`
        );
      }
      return mealRecipeName;
    }
    if ([ProductType.MATERIAL, ProductType.SCOOP].includes(orderProduct.product_type)) {
      return orderProduct.name;
    }
    return `${orderProduct.quantity} x ${orderProduct.recipes
      .map((recipe) => recipe.name)
      .join(', ')}`;
  };

  return (
    <Stack space={{ base: '16px', lg: '24px' }} paddingBottom="16px">
      <Stack
        space={{ base: '8px', lg: '16px' }}
        w="100%"
        justifyContent="center"
        alignItems="start"
      >
        <HStack w="100%" justifyContent="space-between">
          <Heading size="bodyMdToLg" fontWeight="bold">
            {type}
          </Heading>
        </HStack>
        {products.map((product: OrderProduct, i) => {
          return (
            <Stack w="100%" space={{ base: '8px', lg: '16px' }} key={i}>
              <HStack w="100%" justifyContent="space-between">
                <Text fontWeight="medium" size="bodyMdToLg">
                  {getRecipeText(product)}
                </Text>
                {product?.price?.discounted_price &&
                Number(product.price.discounted_price) < Number(product.price.price) ? (
                  <HStack space={{ base: '4px', lg: '8px' }}>
                    <Text size="bodyMdToLg" textDecorationLine="line-through">
                      {formatPrice(Number(product.unit_price) * product.quantity)}
                    </Text>
                    <Text size="bodyMdToLg" fontWeight="bold">
                      {formatPrice(product.price.discounted_price)}
                    </Text>
                  </HStack>
                ) : (
                  <Text size="bodyMdToLg" fontWeight={type === 'Extras' ? 'medium' : 'bold'}>
                    {formatPrice(
                      Number(product.unit_price) * product.quantity,
                      type === 'Extras' ? 'free' : 'dash'
                    )}
                  </Text>
                )}
              </HStack>
              {type === 'Meals' ? (
                <HStack w="100%" justifyContent="space-between">
                  <Text color="sntGrey.primary" size="bodySmToMd">
                    {product.recipes.map((recipe) => recipe.name).join(', ')}
                  </Text>
                </HStack>
              ) : null}
            </Stack>
          );
        })}
      </Stack>
      <Divider bg="gallery" marginBottom="8px" />
    </Stack>
  );
};
