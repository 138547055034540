import { Button, Stack } from 'native-base';
import { useEffect, useState } from 'react';

import { CancelAddonsModal } from '../DogsAndPlans/CancelAddons';
import { AddOnSection } from './AddOnSection';
import { ShoppingExperienceSkeleton } from './ShoppingExperienceSkeleton';

import { useUpdateAddon, useAddonRecipes } from '@/api';
import { Pet, PetPlan, PetPlanProduct, RecipeFull } from '@/api/types';
import { ToastType, displayToast } from '@/components/Elements';
import { AddonsType } from '@/constants';
import { useAccount, useAuth } from '@/hooks';
import { ProtectedScreenProps } from '@/types';
import { getActivePlans } from '@/utils';

export const ShoppingExperience = ({
  navigation,
  route,
}: ProtectedScreenProps<'ShoppingExperience'>) => {
  const account = useAccount();
  const { refetchUser } = useAuth();
  const { data: allAddons, isLoading: isRecipesLoading } = useAddonRecipes(account?.id);
  const { mutateAsync: updateAddon } = useUpdateAddon();
  const [productAdded, setProductAdded] = useState(false);
  const [isUpdateSnacksLoading, setIsUpdateSnacksLoading] = useState(false);
  const [addonToCancel, setAddonToCancel] = useState<PetPlanProduct>();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { recommendedFor } = route.params;

  useEffect(() => {
    if (recommendedFor) {
      navigation.setOptions({ title: `Add products to ${recommendedFor}'s Plan` });
    }
  }, [recommendedFor, navigation]);

  const addonsByType: Record<string, RecipeFull[]> = {};
  allAddons?.forEach((recipe: RecipeFull) => {
    if (addonsByType.hasOwnProperty(recipe.type)) {
      addonsByType[recipe.type].push(recipe);
    } else {
      addonsByType[recipe.type] = [recipe];
    }
  });

  const handleAdd = async (code: string) => {
    setIsUpdateSnacksLoading(true);
    const petPlan = getActivePlans(account)[0];
    const pet = account.pets.find((pet) => petPlan.id === pet.pet_plan.id);
    if (petPlan) {
      const existingProduct = petPlan?.products.find((product) => product.code === code);
      const newQuantity = existingProduct ? existingProduct.quantity + 1 : 1;
      try {
        await updateAddon({
          petPlanId: petPlan.id,
          products: [{ code, quantity: newQuantity, recurring: true }],
        });
        const snack = allAddons?.find((recipe) => recipe.default_product?.code === code);
        await refetchUser();
        setIsUpdateSnacksLoading(false);
        displayToast({
          type: ToastType.Success,
          message: `${snack?.name} have been added to ${pet?.name}'s plan.`,
        });
        setProductAdded(true);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const decrementAddon = async (
    product: PetPlanProduct,
    quantity: number,
    pet?: Pet,
    petPlan?: PetPlan
  ) => {
    const addonPetPlan = petPlan || getActivePlans(account)[0];
    const addonPet = pet || account.pets.find((pet) => addonPetPlan.id === pet.pet_plan.id);
    const cleanAddonName = product.recipes.map((recipe) => recipe.name).join(', ');
    try {
      updateAddon({
        petPlanId: addonPetPlan.id,
        products: [{ code: product.code, quantity, recurring: true }],
      }).then(() => {
        refetchUser();
        displayToast({
          message: `${cleanAddonName} have been removed from ${
            addonPet ? addonPet.name : 'your pet'
          }'s plan.`,
          type: ToastType.Success,
        });
      });
    } catch (err) {
      displayToast({
        message: `An error occurred while removing ${cleanAddonName}. Please try again or reach out to us at care@spotandtango.com for assistance.`,
        type: ToastType.Error,
      });
    }
  };

  const handleRemove = async (code: string) => {
    setIsUpdateSnacksLoading(true);
    const petPlan = getActivePlans(account)[0];
    const pet = account.pets.find((pet) => petPlan.id === pet.pet_plan.id);

    if (petPlan) {
      const existingProduct = petPlan?.products.find((product) => product.code === code);
      if (!existingProduct) {
        console.error('Product not found on pet plan to remove');
        return;
      }

      if (existingProduct.quantity - 1 === 0) {
        setAddonToCancel(existingProduct);
        setShowCancelModal(true);
      } else {
        const newQuantity = existingProduct.quantity - 1;

        await decrementAddon(existingProduct, newQuantity, pet, petPlan);
      }
    }
    setIsUpdateSnacksLoading(false);
  };

  const handleRemoveAll = async (code: string) => {
    setIsUpdateSnacksLoading(true);
    const petPlan = getActivePlans(account)[0];
    const pet = account.pets.find((pet) => petPlan.id === pet.pet_plan.id);
    if (petPlan) {
      try {
        await updateAddon({
          petPlanId: petPlan.id,
          products: [{ code, quantity: 0, recurring: true }],
        });
        const snack = allAddons?.find((recipe) => recipe.default_product?.code === code);
        await refetchUser();
        setIsUpdateSnacksLoading(false);
        displayToast({
          type: ToastType.Success,
          message: `${snack?.name} have been removed from ${pet?.name}'s plan.`,
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <Stack w="100%" h="100%" alignItems="center" pt={{ base: 2, md: 4 }}>
      <Stack
        w="100%"
        space={{ base: 4, md: 6 }}
        alignItems="center"
        justifyContent="center"
        pb="200px" // space for footer
      >
        {!isRecipesLoading ? (
          Object.keys(addonsByType).map((recipeType) => (
            <AddOnSection
              key={recipeType}
              onAdd={handleAdd}
              onRemove={handleRemove}
              onRemoveAll={handleRemoveAll}
              addons={addonsByType[recipeType]}
              isLoading={isUpdateSnacksLoading}
              recipeType={recipeType as AddonsType}
            />
          ))
        ) : (
          <ShoppingExperienceSkeleton />
        )}
      </Stack>
      <Stack variant="stickyFooter" position="fixed" px={4}>
        <Button
          w={{ base: '100%', lg: '300px' }}
          onPress={() => {
            if (navigation.canGoBack()) {
              navigation.goBack();
            } else {
              navigation.navigate('Home');
            }
          }}
          isDisabled={!productAdded}
          variant="primary"
        >
          Done
        </Button>
      </Stack>
      {addonToCancel && (
        <CancelAddonsModal
          product={addonToCancel}
          isOpen={showCancelModal}
          closeModal={() => setShowCancelModal(false)}
          onSubmit={() => decrementAddon(addonToCancel, 0)}
        />
      )}
    </Stack>
  );
};
