import { Badge, Stack, Text } from 'native-base';

import { CreditCardDetailsCard } from './CreditCardDetailsCard';

import { usePaymentMethods } from '@/api';
import { Button, EditableCard, LoadingSpinner } from '@/components/Elements';
import { useCardsExpiryStatus } from '@/hooks/useCardsExpiryStatus';
import { ProtectedScreenProps } from '@/types';
import { cardIsExpired, cardIsExpiringSoon } from '@/utils';

export const PaymentMethodList = ({
  navigation,
  route: { params },
}: ProtectedScreenProps<'PaymentMethodList'>) => {
  const { data: paymentMethods } = usePaymentMethods();
  const { nonExpiringCards } = useCardsExpiryStatus();

  if (!paymentMethods) {
    return <LoadingSpinner accessLabel="Change Payment Method" />;
  }

  const onPressBillingMethod = (cardId: string) => {
    console.log(params?.successRedirect);
    navigation.navigate('EditCreditCard', {
      cardId,
      successRedirect: params?.successRedirect,
      successRedirectParams: params?.successRedirectParams,
    });
  };

  const billingMethods = paymentMethods.filter(({ card }) => !!card); // for now, just filter non-cards out
  const addCreditCardParams = {
    successRedirect: params?.successRedirect,
    successRedirectParams: params?.successRedirectParams,
  };
  if (billingMethods.length === 0) {
    Object.assign(addCreditCardParams, { forceDefault: true });
  }

  return (
    <Stack
      px={{ base: '16px', lg: '0px' }}
      w={{ base: '100%', lg: '540px' }}
      alignSelf="center"
      space={{ base: '16px', lg: '24px' }}
    >
      {billingMethods.map(({ card, id, is_default }, i) => {
        const isExpired = cardIsExpired(card);
        const isExpiringSoon = cardIsExpiringSoon(card);
        return (
          <EditableCard
            onPress={is_default ? undefined : () => onPressBillingMethod(id)}
            key={i}
            stackProps={{ flexDirection: 'column' }}
            chevron={is_default ? false : 'right'}
            borderColor={isExpired ? 'error.default' : isExpiringSoon ? 'sandy' : undefined}
          >
            <CreditCardDetailsCard stripeCard={card} />
            {is_default ? (
              <Badge mt={2} variant="primaryTag" size="xs">
                Primary
              </Badge>
            ) : null}
            {isExpired ? (
              <Text size="bodySm" color="error.default" mt={2}>
                Your card has expired.
              </Text>
            ) : null}
            {isExpiringSoon ? (
              <Text size="bodySm" color="sandy" mt={2}>
                Your card will expire soon.{' '}
                {(nonExpiringCards !== undefined && nonExpiringCards.length) === 0
                  ? 'Add a new payment method to continue your subscription.'
                  : ''}
              </Text>
            ) : null}
          </EditableCard>
        );
      })}
      <Stack>
        <Button
          variant="underlineMini"
          onPress={() => navigation.navigate('AddCreditCard', addCreditCardParams)}
          _text={{
            fontSize: { base: 'body.sm', lg: 'body.md' },
            lineHeight: { base: 'body.sm', lg: 'body.md' },
          }}
          justifyContent="left"
        >
          Add New Payment Method
        </Button>
      </Stack>
    </Stack>
  );
};
