import { Stack } from 'native-base';
import { useWindowDimensions } from 'react-native';
import Carousel from 'react-native-reanimated-carousel';

import { Image } from '@/components/Elements';

export const BannerCarousel = ({ uris }: { uris: string[] }) => {
  const windowWidth = useWindowDimensions().width;
  return (
    <Carousel
      width={windowWidth * 0.8} // renderItem width
      height={140}
      style={{
        width: windowWidth,
        height: 140,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      data={uris}
      renderItem={({ item }) => (
        <Stack w="100%" h="100%" px={1}>
          <Image borderRadius="8px" w="100%" h="100%" resizeMode="cover" source={{ uri: item }} />
        </Stack>
      )}
    />
  );
};
