import { Stack } from 'native-base';
import { useState } from 'react';
import { GestureResponderEvent } from 'react-native';

import { PetPlanProductStatus, RecipeFull } from '@/api/types';
import { PetPillButton, PopUpProps, PortalPopUp } from '@/components/Elements';
import { useAccount } from '@/hooks';

type SelectDogProps = {
  recipe: RecipeFull;
  onConfirm: (code: string | undefined, petId: string) => Promise<any>;
  onSuccess?: (results: any[]) => void;
  isLoading: boolean;
  /**
   * Filters shown pets by these IDs. If undefined, show all active pets.
   */
  petIds?: string[];
} & Omit<PopUpProps, 'onConfirm'>;

export const DogSelectModal = ({
  recipe,
  onConfirm,
  onSuccess,
  isLoading,
  petIds,
  ...props
}: SelectDogProps) => {
  const account = useAccount();
  let activePets = account.pets.filter((pet) => pet.status === 'ACTIVE');
  if (petIds) {
    activePets = activePets.filter((pet) => petIds.includes(pet.id));
  }
  activePets = activePets.filter((pet) =>
    pet.pet_plan.products
      .filter((product) => product.status !== PetPlanProductStatus.CANCELLED)
      .every((product) => product.code !== recipe.default_product.code)
  );

  const [selectedPetIds, setSelectedPetIds] = useState<string[]>([]);

  const togglePet = (e: GestureResponderEvent, petId: string) => {
    e.preventDefault();
    if (selectedPetIds.includes(petId)) {
      setSelectedPetIds(selectedPetIds.filter((id) => petId !== id));
    } else {
      setSelectedPetIds([...selectedPetIds, petId]);
    }
  };

  const handleConfirm = async () => {
    const confirmPromises = selectedPetIds.map((petId) =>
      onConfirm(recipe.default_product?.code, petId)
    );
    const res = await Promise.all(confirmPromises);
    onSuccess?.(res);
  };

  return (
    <PortalPopUp
      heading={`Which plans would you like to add ${recipe.name} to?`}
      size="xl"
      isConfirmButtonDisabled={selectedPetIds.length === 0 || isLoading}
      onConfirm={handleConfirm}
      childSpacerSize={0}
      useRNModal
      {...props}
    >
      <Stack px="20px" w="100%" space={{ base: '16px', lg: '24px' }}>
        <Stack flexWrap="wrap" direction="row" space={{ base: '16px', lg: '24px' }} py={4}>
          {activePets.map((pet) => (
            <PetPillButton
              pet={pet}
              selected={selectedPetIds.includes(pet.id)}
              key={pet.id}
              onPress={(e) => togglePet(e, pet.id)}
              marginTop={{ base: '8px', lg: '12px' }}
              marginBottom={{ base: '8px', lg: '12px' }}
            />
          ))}
        </Stack>
      </Stack>
    </PortalPopUp>
  );
};
