import { VStack, Stack, useBreakpointValue, SimpleGrid, HStack, Heading, Text } from 'native-base';
import { IVStackProps } from 'native-base/lib/typescript/components/primitives/Stack/VStack';
import { useState } from 'react';

import { RecipeFull, RecipeType } from '@/api/types';
import { CarouselItem, PDPModal, Tab, TabSize, TabStatus, TabStyle } from '@/components/Elements';
import {
  ADDONS_CONFIGURATION,
  AddonsType,
  ASSETS_URL,
  PORTAL_NEW_BADGE_RECIPE_IDS,
} from '@/constants';
import { useAccount, useSessionQuery } from '@/hooks';
import segment from '@/segment';
import { capitalize, getActivePlans, getProductCountByRecipeType } from '@/utils';

const images = `${ASSETS_URL}/pictos/`;

export interface AddOnSectionProps {
  addons: RecipeFull[];
  isLoading: boolean;
  onAdd: (code: string) => void;
  onRemove: (code: string) => void;
  onRemoveAll: (code: string) => void;
  recipeType: AddonsType;
  props?: IVStackProps;
}

export const AddOnSection = ({
  addons,
  onAdd,
  onRemove,
  onRemoveAll,
  isLoading,
  recipeType,
  props,
}: AddOnSectionProps) => {
  const { type, maxAddons, textForDissabledMessage, unit, description } =
    ADDONS_CONFIGURATION[recipeType];
  const account = useAccount();
  const [pdpRecipe, setPdpRecipe] = useState<RecipeFull | null>(null);
  const activePlans = getActivePlans(account);
  const activePlan = activePlans[0];
  const { session } = useSessionQuery();

  const existingPdpProduct = activePlan.products.some(
    (product) => product.code === pdpRecipe?.default_product.code
  );

  const [showPDPModal, setShowPDPModal] = useState(false);

  const planAddonsTotalCount = getProductCountByRecipeType(activePlan, type);

  const onPressAddOn = (recipe: RecipeFull) => {
    segment.trackEvent('Click Add-On Product Details', {
      email: account.email,
      product_code: recipe.default_product.code,
      pet_name: null, // kept for parity with old Segment events
      session_id: session?.id,
      account_id: account.id,
    });

    setPdpRecipe(recipe);
    setShowPDPModal(true);
  };

  const gridItemWidth = useBreakpointValue({ base: '160px', md: '240px' });
  const gridColumns = useBreakpointValue({ base: 2, md: 3 });
  const spacingY = useBreakpointValue({ base: 4, md: 6 });
  const headerSpacer = useBreakpointValue({ base: false, md: true });

  return (
    <VStack {...props}>
      <SimpleGrid columns={gridColumns} spacingX={6} spacingY={spacingY}>
        <HStack w={gridItemWidth} alignItems="center">
          <Heading size="bodyMlToTitleSm" fontWeight="bold">
            {`${capitalize(recipeType)}s`}
          </Heading>
        </HStack>
        {headerSpacer ? <Stack w={gridItemWidth} /> : null}
        <Stack w={gridItemWidth} alignItems="flex-end">
          <Tab size={TabSize.Large} status={TabStatus.Active} style={TabStyle.Outline}>
            <Text size="tinyToCaption" fontWeight="medium">
              Subscription Products
            </Text>
          </Tab>
        </Stack>

        {addons.map((recipe) => {
          const existingPlanProduct = activePlan.products.find(
            (product) => product.code === recipe.default_product.code
          );

          let plusDisabledMessage: string | undefined = undefined;
          if (recipe.type === type && planAddonsTotalCount === maxAddons) {
            plusDisabledMessage = `You have reached the maximum of ${maxAddons} ${textForDissabledMessage} per order`;
          }
          const showNewBadge = PORTAL_NEW_BADGE_RECIPE_IDS.includes(recipe.id);

          return (
            <Stack key={recipe.id} w={gridItemWidth}>
              <CarouselItem
                h="auto"
                mb={2}
                onPressImage={() => {
                  onPressAddOn(recipe);
                }}
                w="100%"
                imageProps={{ size: gridItemWidth }}
                justifyContent="center"
                alignItems="center"
                unit={unit}
                title={recipe.name}
                description={description.forShopModal[recipe.id]}
                price={recipe.default_product.price.price}
                image={`${images}${capitalize(recipe.type)}/${recipe.id}.png`}
                value={existingPlanProduct?.quantity || 0}
                isLoading={isLoading}
                isLongPressAvailable
                hoverStateImage={`${images}${capitalize(recipe.type)}/${recipe.id}-HS.png`}
                plusDisabledMessage={plusDisabledMessage}
                showNewBadge={showNewBadge}
                onAdd={() => onAdd(recipe.default_product?.code)}
                onRemove={() => onRemove(recipe.default_product?.code)}
              />
            </Stack>
          );
        })}
      </SimpleGrid>

      {pdpRecipe ? (
        <PDPModal
          recipe={pdpRecipe}
          buttonText={existingPdpProduct ? 'Remove' : 'Add to Plan'}
          prices={[pdpRecipe.default_product?.price.price]}
          pricingUnits={[pdpRecipe.type === RecipeType.SNACK ? 'bag' : 'pack']}
          updateState={() => {
            if (existingPdpProduct) {
              onRemoveAll(pdpRecipe.default_product?.code);
            } else {
              onAdd(pdpRecipe.default_product?.code);
            }
          }}
          isOpen={showPDPModal}
          onClose={() => setShowPDPModal(false)}
        />
      ) : null}
    </VStack>
  );
};
