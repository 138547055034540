import * as Linking from 'expo-linking';
import { Stack, Text, Button, IStackProps } from 'native-base';

import { BillingCard } from '../Orders';
import { AccountTabSkeleton } from './AccountTabSkeleton';

import { usePaymentMethods } from '@/api';
import {
  AvatarImageGroup,
  EditableCard,
  HiddenTextDots,
  PortalWrapper,
} from '@/components/Elements';
import { usePaymentError } from '@/hooks';
import { useAccount, useAuth } from '@/hooks/useAuth';
import segment from '@/segment';
import { ProtectedScreenProps } from '@/types';
import { formatPhoneNumber } from '@/utils';
import { sendErrorReport } from '@/utils/analytics';

export const AccountTab = ({ navigation }: ProtectedScreenProps<'Account'>) => {
  const { logout } = useAuth();
  const account = useAccount();

  const primaryAddress = account.addresses.find((addr) => addr.is_primary) || account.addresses[0];
  const activePets = account.pets.filter((pet) => pet.status === 'ACTIVE');
  const inactivePets = account.pets.filter((pet) =>
    ['INACTIVE', 'CANCELLED', 'PAUSED'].includes(pet.status)
  );
  const { data: paymentMethods, isLoading } = usePaymentMethods();

  const stripeCard = paymentMethods?.find((method) => method.is_default)?.card;

  const paymentError = usePaymentError();

  const onLogoutPress = async () => {
    try {
      await logout();
      return Linking.openURL('https://spotandtango.com/');
    } catch (error) {
      sendErrorReport(error);
    }
  };

  return (
    <PortalWrapper containerProps={{ alignItems: 'start', px: { base: '16px', lg: '0px' } }}>
      {isLoading ? (
        <AccountTabSkeleton />
      ) : (
        <Stack
          justifyContent="center"
          alignItems="center"
          w="100%"
          space={{ base: '16px', lg: '24px' }}
        >
          <EditableCard
            heading="Personal Information"
            headingProps={{ pb: 2 }}
            stackProps={{ flexDirection: 'column' }}
            onPress={() => navigation.navigate('PersonalInformation')}
          >
            <Stack flexDirection="row">
              <Stack flexDirection="column" space={{ base: '0px', lg: '8px' }}>
                <Text size="bodyMdToLg">
                  {account.first_name} {account.last_name}
                </Text>
                <Text size="bodyMdToLg">{account.email}</Text>
                <Text size="bodyMdToLg">{formatPhoneNumber(account.phone_number || '')}</Text>
              </Stack>
            </Stack>
          </EditableCard>

          <CardGroup>
            <EditableCard
              heading="Shipping"
              stackProps={{ direction: 'column', space: { base: '8px', lg: '16px' } }}
              onPress={() => navigation.navigate('AddressList')}
            >
              <Stack flexDirection="row">
                <Stack flexDirection="column" space={{ base: '0px', lg: '8px' }}>
                  <Text size="bodyMdToLg">{primaryAddress.address1}</Text>
                  {primaryAddress?.address2 ? (
                    <Text size="bodyMdToLg">{primaryAddress.address2}</Text>
                  ) : null}
                  <Text size="bodyMdToLg">
                    {primaryAddress.city}, {primaryAddress.state.toUpperCase()} {primaryAddress.zip}
                  </Text>
                </Stack>
              </Stack>
            </EditableCard>
            <BillingCard
              onPress={() => navigation.navigate('PaymentMethodList')}
              stackProps={{ direction: 'column', space: { base: 1, lg: 2 } }}
              card={stripeCard ? { brand: stripeCard.brand, last4: stripeCard.last4 } : undefined}
              paymentError={paymentError}
            />
          </CardGroup>

          <CardGroup w="100%">
            {activePets.length > 0 ? (
              <EditableCard
                flex={1}
                heading="Manage or Cancel Plans"
                stackProps={{ direction: 'column', space: { base: '8px', lg: '16px' } }}
                onPress={() => {
                  segment.trackEvent('Click Active Plans', {
                    account_id: account.id,
                    email: account.email,
                  });
                  navigation.navigate('ManageActivePlans');
                }}
              >
                <Stack flexDirection="row" overflow="hidden">
                  <Stack flexDirection="row" justifyContent="center" alignItems="center">
                    <AvatarImageGroup pets={activePets} />
                    <Stack
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                      px={1}
                      overflowX="hidden"
                    >
                      <Text size="bodyMdToLg" overflow="hidden">
                        {activePets.map((pet) => pet.name).join(', ')}
                      </Text>
                    </Stack>
                  </Stack>
                </Stack>
              </EditableCard>
            ) : null}
            {inactivePets.length > 0 ? (
              <EditableCard
                flex={1}
                h={{ base: 'fit-content', lg: '100%' }}
                heading="Reactivate Plans"
                stackProps={{ direction: 'column', space: { base: '8px', lg: '16px' } }}
                onPress={() => navigation.navigate('ManageInactivePlans')}
              >
                <Stack flexDirection="row" overflow="hidden">
                  <Stack flexDirection="row" justifyContent="center" alignItems="center">
                    <AvatarImageGroup pets={inactivePets} />
                    <Stack
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                      px={1}
                      overflowX="hidden"
                    >
                      <Text size="bodyMdToLg" overflow="hidden">
                        {inactivePets.map((pet) => pet.name).join(', ')}
                      </Text>
                    </Stack>
                  </Stack>
                </Stack>
              </EditableCard>
            ) : (
              false
            )}
          </CardGroup>
          <CardGroup>
            <EditableCard
              heading="Password"
              h={{ base: 'fit-content', lg: '100%' }}
              stackProps={{ direction: 'column', space: { base: '8px', lg: '16px' } }}
              onPress={() => navigation.navigate('ChangePassword')}
            >
              <Stack flexDirection="row" justifyContent="center" alignItems="center">
                <Stack flexDirection="row" justifyContent="center" alignItems="center">
                  <HiddenTextDots />
                </Stack>
              </Stack>
            </EditableCard>
            <EditableCard
              h={{ base: 'fit-content', lg: '100%' }}
              heading="Notification Preferences"
              stackProps={{ flexDirection: 'column' }}
              onPress={() => navigation.navigate('NotificationPreferences')}
            />
          </CardGroup>
          <Stack flexDir="row" justifyContent="center" my={3}>
            <Button variant="hugPrimary" onPress={onLogoutPress} px={{ base: 4, lg: 9 }}>
              LOGOUT
            </Button>
          </Stack>
        </Stack>
      )}
    </PortalWrapper>
  );
};

export const CardGroup = (props: IStackProps) => {
  return (
    <Stack
      direction={{ base: 'column', lg: 'row' }}
      space={{ base: '16px', lg: '24px' }}
      justifyContent="center"
      alignItems="center"
      w={{ base: '100%', lg: '408px' }}
      {...props}
    >
      {props.children}
    </Stack>
  );
};
