import { useFocusEffect } from '@react-navigation/native';
import { Box, Heading, Stack, Text, VStack } from 'native-base';
import { useCallback } from 'react';

import { BuildPlan } from '@/api/types';
import { FullTopperCard } from '@/components/Elements';
import { Dog, PlanType } from '@/types';
import { dogAge, getBreed } from '@/utils';

interface ChoosePlanSizeProps {
  freshData?: BuildPlan;
  topperData?: BuildPlan;
  dataIsReady: boolean;
  dog: Partial<Dog>;
  planType: PlanType | null;
  setPlanType: (planType: PlanType) => void;
}

export const ChoosePlanSize = ({
  dog,
  dataIsReady,
  freshData,
  topperData,
  planType,
  setPlanType,
}: ChoosePlanSizeProps) => {
  useFocusEffect(
    useCallback(() => {
      setPlanType(dog?.plan_type || 'full');
    }, [dog])
  );
  const breed = getBreed(dog.breeds) === 'Unknown Breed' ? '' : `${getBreed(dog.breeds)}`;
  const age = dogAge(dog.birth_year, dog.birth_month, false) || 'Unknown age';
  const lifestyle = dog.lifestyle?.replaceAll('-', ' ');
  const bodyType = dog.body_type?.replaceAll('-', ' ');

  return dataIsReady && freshData && topperData ? (
    <VStack
      w="100%"
      alignItems="center"
      space={{ base: '36px', md: '48px' }}
      pb="98px"
      px={{ base: '16px', lg: 0 }}
    >
      <VStack space="16px" w="100%" maxW="960px">
        <Heading variant="titleSmToMd">Here's {dog.name}'s Plan!</Heading>
        <Text
          px={{ base: '28px', lg: 0 }}
          fontFamily="secondary"
          fontWeight="book"
          textAlign="center"
          size="bodySmToMd"
          w="100%"
        >
          {`We've determined just the right amount of food based on ${dog.name}'s profile. The following plans are perfect for a ${age} old ${breed} like ${dog.name} who's ${dog.weight} pounds, ${lifestyle}, and has a "${bodyType}" body type.`}
        </Text>
      </VStack>
      <Stack
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent="center"
        alignItems="center"
      >
        <FullTopperCard
          planType="full"
          selected={planType === 'full'}
          dog={dog}
          planData={freshData}
          onPress={() => setPlanType('full')}
        />
        <Box w={{ base: '16px', lg: '36px' }} h={{ base: '24px', lg: '16px' }} />
        <FullTopperCard
          planType="topper"
          selected={planType === 'topper'}
          dog={dog}
          planData={topperData}
          onPress={() => setPlanType('topper')}
        />
      </Stack>
    </VStack>
  ) : null;
};
