import { Stack, ISkeletonProps, Skeleton, Center } from 'native-base';

export const RecipeSelectFormSkeleton = (props: ISkeletonProps) => (
  <Stack alignSelf="center" w="100%" mb={3}>
    <Center mb={5}>
      <Skeleton
        borderRadius={{ base: '16px', lg: '24px' }}
        w={{ base: '99px', lg: '110px' }}
        h={{ base: '32px', lg: '42px' }}
      />
    </Center>

    <Center mb={5}>
      <Skeleton.Text lines={1} w="100px" />
    </Center>

    <Stack
      direction={{ base: 'column', lg: 'row' }}
      space={4}
      alignItems="center"
      justifyContent="center"
    >
      {[...Array(3)].map((_, i) => (
        <Skeleton
          key={i}
          w={{ base: '100%', md: '484px', lg: '324px' }}
          minH={{ base: '162px', md: '175px', lg: '378px' }}
          {...props}
        />
      ))}
    </Stack>
  </Stack>
);
