import { ITextProps } from 'native-base';

import { ToastType, displayToast } from '@/components/Elements';

const AXIOS_ERROR_TOAST_NAME = 'axios_toast';

export const defaultErrorHandler = () => {
  displayToast({
    id: AXIOS_ERROR_TOAST_NAME,
    message:
      'We are unable to process your request at this time. Please try again later or contact care@spotandtango.com for further assistance.',
    type: ToastType.Error,
    duration: 3000,
  });
};

export const zipCodeNotValid = () => {
  displayToast({
    id: AXIOS_ERROR_TOAST_NAME,
    message:
      'We were unable to verify your zip code. Please try again or contact care@spotandtango.com for further assistance.',
    type: ToastType.Error,
    title: 'Invalid zip code',
    duration: 3000,
  });
};

export const petPlanInHoldStatusErrorHandler = () => {
  displayToast({
    id: AXIOS_ERROR_TOAST_NAME,
    message:
      'We are unable to make changes to your pup’s plan due to a payment issue with their most recent order. Please update your payment method before making changes to future orders.',
    type: ToastType.Error,
    title: 'Please update your payment method.',
    duration: 3000,
  });
};

export const invalidAddressErrorHandler = () => {
  displayToast({
    message:
      'Please input a valid shipping address or email care@spotandtango.com if you need additional support.',
    type: ToastType.Error,
    title: 'Invalid or incomplete address',
    duration: 3000,
  });
};

export const paymentFailedErrorHandler = (
  styledMessage?: ITextProps['children'],
  onPress?: () => void,
  isRetry?: boolean
) => {
  displayToast({
    id: isRetry ? 'payment_retry_error_toast' : 'payment_error_toast',
    message: `Your payment ${
      isRetry ? 'retry' : 'method'
    } was unsuccessful. Please add a new form of payment so we can start preparing your pup's order.`,
    styledMessage,
    type: ToastType.Error,
    title: isRetry ? 'Payment unsuccessful' : 'Payment error',
    duration: 10000,
    onPress,
  });
};
