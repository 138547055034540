import { Heading, IHeadingProps, IImageProps, ITextProps, Text, VStack } from 'native-base';
import { IVStackProps } from 'native-base/lib/typescript/components/primitives/Stack/VStack';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
const images = `${ASSETS_URL}/pictos/`;

export type LabelledPictoProps = {
  src: string;
  caption?: string;
  heading?: string;
  imageProps?: IImageProps;
  headingProps?: IHeadingProps;
  captionProps?: ITextProps;
} & IVStackProps;

export const LabelledPicto = ({
  src,
  caption,
  heading,
  imageProps = { w: { base: '36px', lg: '48px' }, h: { base: '36px', lg: '48px' } },
  captionProps,
  headingProps,
  ...props
}: LabelledPictoProps) => {
  return (
    <VStack
      data-testid="labelledpicto"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      space="4px"
      w={{ base: '52px', lg: '68px' }}
      {...props}
    >
      <Image
        source={{ uri: `${images}${src}` }}
        alt={caption}
        resizeMode="contain"
        {...imageProps}
      />
      <VStack space="8px">
        {heading ? (
          <Heading size={{ base: 'bodyMl', lg: 'bodyLg' }} fontWeight={700} {...headingProps}>
            {heading}
          </Heading>
        ) : null}
        {caption ? <Text {...captionProps}>{caption}</Text> : null}
      </VStack>
    </VStack>
  );
};

LabelledPicto.displayName = 'LabelledPicto';
