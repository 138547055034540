import { Text } from 'native-base';

import { PopUpProps, PortalPopUp } from '@/components/Elements';

interface ConfirmRushDeliveryModalProps extends Omit<PopUpProps, 'children' | 'heading'> {
  orderTotal: string;
}

export const ConfirmRushDeliveryModal = ({
  orderTotal,
  ...props
}: ConfirmRushDeliveryModalProps) => {
  return (
    <PortalPopUp
      heading="Ready to receive your order ASAP?"
      headerProps={{ px: 10 }}
      modalContentProps={{ maxW: { md: '392px' } }}
      backBtnText="CANCEL"
      {...props}
    >
      <Text size="bodySmToMd" fontFamily="secondary" textAlign="center">
        By clicking “Confirm”, your order will be processed immediately and your card will be
        charged ${orderTotal}.
      </Text>
    </PortalPopUp>
  );
};
