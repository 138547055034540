export const fontSizes = {
  extraLargeTitle: '64px',
  largeTitle: '48px',
  title: {
    lg: '40px',
    ml: '36px',
    md: '32px',
    sm: '24px',
  },
  body: {
    lg: '20px',
    ml: '18px',
    md: '16px',
    sm: '14px',
  },
  caption: '12px',
  tiny: '10px',
};

export const responsiveSizes = {
  tiny: {
    _text: {
      fontSize: 'tiny',
      lineHeight: 'tiny',
    },
    fontSize: 'tiny',
    lineHeight: 'tiny',
  },
  caption: {
    _text: {
      fontSize: 'caption',
      lineHeight: 'caption',
    },
    fontSize: 'caption',
    lineHeight: 'caption',
  },
  bodySm: {
    _text: {
      fontSize: 'body.sm',
      lineHeight: 'body.sm',
    },
    fontSize: 'body.sm',
    lineHeight: 'body.sm',
  },
  bodyMd: {
    _text: {
      fontSize: 'body.md',
      lineHeight: 'body.md',
    },
    fontSize: 'body.md',
    lineHeight: 'body.md',
  },
  bodyMl: {
    _text: {
      fontSize: 'body.ml',
      lineHeight: 'body.lg',
    },
    fontSize: 'body.ml',
    lineHeight: 'body.lg',
  },
  bodyLg: {
    _text: {
      fontSize: 'body.lg',
      lineHeight: 'body.lg',
    },
    fontSize: 'body.lg',
    lineHeight: 'body.lg',
  },
  titleSm: {
    _text: {
      fontSize: 'title.sm',
      lineHeight: 'title.sm',
    },
    fontSize: 'title.sm',
    lineHeight: 'title.sm',
  },
  titleMd: {
    _text: {
      fontSize: 'title.md',
      lineHeight: 'title.md',
    },
    fontSize: 'title.md',
    lineHeight: 'title.md',
  },
  titleLg: {
    _text: {
      fontSize: 'title.lg',
      lineHeight: 'title.lg',
    },
    fontSize: 'title.lg',
    lineHeight: 'title.lg',
  },
  largeTitle: {
    _text: {
      fontSize: 'largeTitle',
      lineHeight: 'largeTitle',
    },
    fontSize: 'largeTitle',
    lineHeight: 'largeTitle',
  },
  tinyToCaption: {
    _text: {
      fontSize: { base: 'tiny', lg: 'caption' },
      lineHeight: { base: 'tiny', lg: 'caption' },
    },
    fontSize: { base: 'tiny', lg: 'caption' },
    lineHeight: { base: 'tiny', lg: 'caption' },
  },
  tinyToBodySm: {
    _text: {
      fontSize: { base: 'tiny', lg: 'body.sm' },
      lineHeight: { base: 'tiny', lg: 'body.sm' },
    },
    fontSize: { base: 'tiny', lg: 'body.sm' },
    lineHeight: { base: 'tiny', lg: 'body.sm' },
  },
  tinyToBodyMd: {
    _text: {
      fontSize: { base: 'tiny', lg: 'body.md' },
      lineHeight: { base: 'tiny', lg: 'body.md' },
    },
    fontSize: { base: 'tiny', lg: 'body.md' },
    lineHeight: { base: 'tiny', lg: 'body.md' },
  },
  captionToBodySm: {
    _text: {
      fontSize: { base: 'caption', lg: 'body.sm' },
      lineHeight: { base: 'caption', lg: 'body.sm' },
    },
    fontSize: { base: 'caption', lg: 'body.sm' },
    lineHeight: { base: 'caption', lg: 'body.sm' },
  },
  captionToBodyMd: {
    _text: {
      fontSize: { base: 'caption', lg: 'body.md' },
      lineHeight: { base: 'caption', lg: 'body.md' },
    },
    fontSize: { base: 'caption', lg: 'body.md' },
    lineHeight: { base: 'caption', lg: 'body.md' },
  },
  bodySmToMd: {
    _text: {
      fontSize: { base: 'body.sm', lg: 'body.md' },
      lineHeight: { base: 'body.sm', lg: 'body.md' },
    },
    fontSize: { base: 'body.sm', lg: 'body.md' },
    lineHeight: { base: 'body.sm', lg: 'body.md' },
  },
  bodySmToMl: {
    _text: {
      fontSize: { base: 'body.sm', lg: 'body.ml' },
      lineHeight: { base: 'body.sm', lg: 'body.ml' },
    },
    fontSize: { base: 'body.sm', lg: 'body.ml' },
    lineHeight: { base: 'body.sm', lg: 'body.ml' },
  },
  bodySmToLg: {
    _text: {
      fontSize: { base: 'body.sm', lg: 'body.lg' },
      lineHeight: { base: 'body.sm', lg: 'body.lg' },
    },
    fontSize: { base: 'body.sm', lg: 'body.lg' },
    lineHeight: { base: 'body.sm', lg: 'body.lg' },
  },
  bodyMlToLg: {
    _text: {
      fontSize: { base: 'body.ml', lg: 'body.lg' },
      lineHeight: 'body.lg',
    },
    fontSize: { base: 'body.ml', lg: 'body.lg' },
    lineHeight: 'body.lg',
  },
  bodyMdToLg: {
    _text: {
      fontSize: { base: 'body.md', lg: 'body.lg' },
      lineHeight: { base: 'body.md', lg: 'body.lg' },
    },
    fontSize: { base: 'body.md', lg: 'body.lg' },
    lineHeight: { base: 'body.md', lg: 'body.lg' },
  },
  bodySmToTitleMd: {
    _text: {
      fontSize: { base: 'body.sm', lg: 'title.sm' },
      lineHeight: { base: 'body.md', lg: 'title.sm' },
    },
    fontSize: { base: 'body.sm', lg: 'title.sm' },
    lineHeight: { base: 'body.md', lg: 'title.sm' },
  },
  bodyMdToTitleMd: {
    _text: {
      fontSize: { base: 'body.md', lg: 'title.md' },
      lineHeight: { base: 'body.md', lg: 'title.md' },
    },
    fontSize: { base: 'body.md', lg: 'title.md' },
    lineHeight: { base: 'body.md', lg: 'title.md' },
  },
  bodyMdToTitleSm: {
    _text: {
      fontSize: { base: 'body.md', lg: 'title.sm' },
      lineHeight: { base: 'body.md', lg: 'title.sm' },
    },
    fontSize: { base: 'body.md', lg: 'title.sm' },
    lineHeight: { base: 'body.md', lg: 'title.sm' },
  },
  bodyMlToTitleSm: {
    _text: {
      fontSize: { base: 'body.ml', lg: 'title.sm' },
      lineHeight: { base: 'body.lg', lg: 'title.sm' },
    },
    fontSize: { base: 'body.ml', lg: 'title.sm' },
    lineHeight: { base: 'body.lg', lg: 'title.sm' },
  },
  bodyMlToTitleMd: {
    _text: {
      fontSize: { base: 'body.ml', lg: 'title.md' },
      lineHeight: { base: 'body.lg', lg: 'title.md' },
    },
    fontSize: { base: 'body.ml', lg: 'title.md' },
    lineHeight: { base: 'body.lg', lg: 'title.md' },
  },
  bodyLgToTitleMd: {
    _text: {
      fontSize: { base: 'body.lg', lg: 'title.md' },
      lineHeight: { base: 'body.lg', lg: 'title.md' },
    },
    fontSize: { base: 'body.lg', lg: 'title.md' },
    lineHeight: { base: 'body.lg', lg: 'title.md' },
  },
  titleSmToMd: {
    _text: {
      fontSize: { base: 'title.sm', lg: 'title.md' },
      lineHeight: { base: 'title.sm', lg: 'title.md' },
    },
    fontSize: { base: 'title.sm', lg: 'title.md' },
    lineHeight: { base: 'title.sm', lg: 'title.md' },
  },
  titleSmToLg: {
    _text: {
      fontSize: { base: 'title.sm', lg: 'title.lg' },
      lineHeight: { base: 'title.sm', lg: 'title.lg' },
    },
    fontSize: { base: 'title.sm', lg: 'title.lg' },
    lineHeight: { base: 'title.sm', lg: 'title.lg' },
  },
  titleMlToLg: {
    _text: {
      fontSize: { base: 'title.ml', lg: 'title.lg' },
      lineHeight: { base: 'title.ml', lg: 'title.lg' },
      textAlign: 'center',
    },
    fontSize: { base: 'title.ml', lg: 'title.lg' },
    lineHeight: { base: 'title.ml', lg: 'title.lg' },
    textAlign: 'center',
  },
  titleMdToMl: {
    _text: {
      fontSize: { base: 'title.md', lg: 'title.ml' },
      lineHeight: { base: 'title.md', lg: 'title.ml' },
      textAlign: 'center',
    },
    fontSize: { base: 'title.md', lg: 'title.ml' },
    lineHeight: { base: 'title.md', lg: 'title.ml' },
    textAlign: 'center',
  },
  titleLgToXl: {
    _text: {
      fontSize: { base: 'title.lg', lg: 'extraLargeTitle' },
      lineHeight: { base: 'title.lg', lg: 'extraLargeTitle' },
      textAlign: 'center',
    },
    fontSize: { base: 'title.lg', lg: 'extraLargeTitle' },
    lineHeight: { base: 'title.lg', lg: 'extraLargeTitle' },
    textAlign: 'center',
  },
};
