import { Heading, Stack, Text, VStack } from 'native-base';

import { RecipeType } from '@/api';

export const Extras = ({ recipeType }: { recipeType: RecipeType }) => {
  return (
    <VStack py={4} space={2}>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Heading size="bodyMdToLg" fontWeight="bold">
          Extras
        </Heading>
      </Stack>
      {recipeType !== RecipeType.FRESH && (
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Text size="bodyMdToLg" maxW={{ base: '200px', md: '100%' }} h="auto" fontWeight="medium">
            Customized Scoop
          </Text>

          <Text mx={1} size="bodyMdToLg" fontWeight="medium">
            FREE
          </Text>
        </Stack>
      )}
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Text size="bodyMdToLg" maxW={{ base: '200px', md: '100%' }} h="auto" fontWeight="medium">
          Personalized Feeding Guide
        </Text>

        <Text mx={1} size="bodyMdToLg" fontWeight="medium">
          FREE
        </Text>
      </Stack>
    </VStack>
  );
};
