import { Stack, Heading, Divider, IStackProps, HStack, Badge } from 'native-base';

import { OrderProduct } from '@/api/types';
import { Image } from '@/components/Elements';
import { getRecipesProductImageSrc, round } from '@/utils';

export interface LineItemPlanCardExtraProps extends IStackProps {
  product: OrderProduct;
  disabled?: boolean;
  showDivider?: boolean;
}

export const LineItemPlanCardExtra = ({
  product,
  disabled,
  showDivider,
  ...props
}: LineItemPlanCardExtraProps) => {
  const productIsFree = product.unit_price === undefined || Number(product.unit_price) === 0;
  return (
    <HStack space={{ base: 2, lg: 4 }} {...props}>
      <Stack w={{ base: 16, lg: 20 }} h={{ base: 16, lg: 20 }}>
        <Image
          source={{ uri: getRecipesProductImageSrc(product.recipes, false) }}
          w="100%"
          h="100%"
          alt={product.name}
          resizeMode="contain"
          opacity={disabled ? 50 : undefined}
        />
      </Stack>
      <Stack flex={3} space={2} justifyContent="space-between">
        <HStack alignItems="center" justifyContent="space-between">
          <Heading
            size="bodyMdToLg"
            fontWeight="bold"
            color={disabled ? 'sntGrey.primary' : undefined}
          >
            {product.name}
          </Heading>
          {!productIsFree ? (
            <Badge
              variant="smallInfoPillSecondary"
              _text={{ color: disabled ? 'sntGrey.primary' : undefined }}
              borderColor={disabled ? 'sntGrey.primary' : undefined}
            >
              {`$${round(product.quantity * Number(product.unit_price), 2)}`}
            </Badge>
          ) : null}
        </HStack>
        {showDivider ? <Divider bgColor="gallery" w="85%" mt={4} /> : null}
      </Stack>
    </HStack>
  );
};
