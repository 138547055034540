import { Stack, Heading, Text } from 'native-base';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
const images = `${ASSETS_URL}/pictos/CardColorIcons/`;

type ValuePropPictoProps = { src: string; header: string; content: string };

export const ValuePropPicto = ({ src, header, content }: ValuePropPictoProps) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      w={{ base: '144px', lg: '164px' }}
      space="8px"
    >
      <Image
        size={{ base: 12, lg: 16 }}
        source={{ uri: `${images}${src}.svg` }}
        alt={header}
        resizeMode="contain"
      />

      <Heading size="bodyMd" textAlign="center" color="grey.dark">
        {header}
      </Heading>

      <Text
        textAlign="center"
        fontSize={{ base: 'body.sm', lg: 'body.md' }}
        lineHeight={{ base: 'body.sm', lg: 'body.md' }}
        color="grey.dark"
      >
        {content}
      </Text>
    </Stack>
  );
};
