import {
  Box,
  Spacer,
  Text,
  Button,
  Stack,
  useBreakpointValue,
  IImageProps,
  IStackProps,
  HStack,
} from 'native-base';
import React, { useRef, useState } from 'react';
import { Animated, Easing } from 'react-native';
import { StarIcon as StarIconOutline } from 'react-native-heroicons/outline';
import { StarIcon } from 'react-native-heroicons/solid';

import { Image } from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
const images = `${ASSETS_URL}/pictos/Reviews/`;

export type Review = {
  reviewer_name: string;
  rating: number | null;
  review: string;
  id: number;
};

type ReviewCardProps = {
  expanded?: boolean;
  picto: string;
  setExpanded?: () => void;
  setCarouselHeight?: (height: number) => void;
  imageProps?: IImageProps;
  carouselIndex: number;
  cardIndex: number;
} & Review &
  IStackProps;

const Stars = ({ rating }: { rating: number }) => {
  const stars = [];
  for (let i = 1; i <= rating; i++) {
    stars.push(
      rating >= i ? (
        <StarIcon key={'star' + i} size="16px" />
      ) : (
        <StarIconOutline key={'starOutline' + i} size="16px" />
      )
    );
  }
  return <HStack space={1}>{stars}</HStack>;
};

export const ReviewCard = ({
  reviewer_name,
  rating,
  review,
  picto,
  expanded = false,
  setExpanded,
  setCarouselHeight,
  carouselIndex = 0,
  cardIndex,
  imageProps,
  ...props
}: ReviewCardProps) => {
  const shortenedText = useBreakpointValue({
    base: review.slice(0, 100) + ' ...',
    md: review.slice(0, 125) + ' ...',
  });

  const textBoxHeight = useBreakpointValue({ base: 128, md: 160 });
  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState(textBoxHeight);

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [60, bodySectionHeight],
  });

  const triggerClose = () => {
    Animated.timing(animatedController, {
      duration: 75,
      toValue: 0,
      easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      useNativeDriver: true,
    }).start();
  };

  const triggerOpen = () => {
    Animated.timing(animatedController, {
      duration: 50,
      toValue: 1,
      easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      useNativeDriver: true,
    }).start();
  };

  return (
    <Stack
      display="flex"
      flexDir="column"
      justifyContent="start"
      alignItems="center"
      w={{ base: '311px', md: '400px' }}
      h="fit-content"
      minH={{ base: '392px', md: '456px' }}
      overflow="hidden"
      onLayout={(event) => {
        if (setCarouselHeight && (!expanded || cardIndex === carouselIndex))
          setCarouselHeight(event.nativeEvent.layout.height);
      }}
      {...props}
    >
      {expanded ? triggerOpen() : triggerClose()}
      <Box w={{ base: '311px', md: '400px' }} h={{ base: '264px', md: '296px' }}>
        <Image
          w={{ base: '311px', md: '400px' }}
          h={{ base: '264px', md: '296px' }}
          source={{ uri: images + picto }}
          alt={reviewer_name + ' review image'}
          resizeMode="cover"
          width="100%"
          height="100%"
          borderBottomColor="black"
          borderBottomWidth="2px"
          borderBottomStyle="solid"
          {...imageProps}
        />
      </Box>
      <Animated.View style={[{ height: bodyHeight }]}>
        <Box
          display="flex"
          flexDir="column"
          justifyContent="start"
          alignItems="center"
          overflow="hidden"
          p={{ base: '16px', md: '24px' }}
          h="fit-content"
          w="100%"
          onLayout={(event) => {
            setBodySectionHeight(event.nativeEvent.layout.height);
          }}
        >
          <Text size="bodySmToMd" fontWeight="bold">
            {reviewer_name}
          </Text>
          <Spacer size="8px" />

          {rating && <Stars rating={rating} />}

          <Spacer size="8px" />

          <Text size="bodySmToMd" textAlign="center" fontFamily="secondary" fontWeight="medium">
            {expanded || review.length <= shortenedText.length ? review : shortenedText}{' '}
            {review.length <= shortenedText.length ? null : (
              <Button
                variant="underlineMini"
                onPress={setExpanded}
                _text={{ fontFamily: 'secondary', fontWeight: 'bold' }}
              >
                {expanded ? 'Read Less' : 'Read More'}
              </Button>
            )}
          </Text>
        </Box>
      </Animated.View>
    </Stack>
  );
};
