import { useAccount } from './useAuth';

import { useAddonRecipes } from '@/api';
import {
  Pet,
  PetPlan,
  PetPlanRecipeData,
  PetPlanStatus,
  ProductType,
  RecipeFull,
  RecipeType,
} from '@/api/types';
import { MAX_SNACKS_PER_PLAN, MAX_SUPPLEMENTS_PER_PLAN } from '@/constants';

export const useRecommendedAddons = (selectedPets?: Pet[] | undefined): RecipeFull[] => {
  const account = useAccount();
  const { data: allRecipes, isSuccess } = useAddonRecipes(account?.id);
  if (!isSuccess) {
    return [];
  }

  const allAddons = allRecipes?.filter((recipe) =>
    [RecipeType.SNACK, RecipeType.SUPPLEMENT].includes(recipe.type)
  );
  let availableRecipes = allAddons || [];
  const inEveryPetPlan = (petPlans: PetPlan[], recipe: RecipeFull): boolean => {
    if (petPlans.length === 0) return true;
    return (
      [RecipeType.SNACK, RecipeType.SUPPLEMENT].includes(recipe.type) &&
      petPlans.every((petPlan) =>
        petPlan.products.some((product) =>
          product.recipes.some((prodRecipe: PetPlanRecipeData) => prodRecipe.id === recipe.id)
        )
      )
    );
  };

  const getAvailableRecipes = (petPlans: PetPlan[]) => {
    let petsWithMaxSnacks = 0;
    let petsWithMaxSupplements = 0;

    petPlans.forEach((petPlan) => {
      let snackQuantity = 0;
      let supplementQuantity = 0;

      petPlan.products.forEach((product) => {
        if (product.product_type === ProductType.SNACK) {
          snackQuantity += product.quantity;
        } else if (product.product_type === ProductType.SUPPLEMENT) {
          supplementQuantity += product.quantity;
        }
      });
      if (snackQuantity >= MAX_SNACKS_PER_PLAN) {
        petsWithMaxSnacks += 1;
      }
      if (supplementQuantity >= MAX_SUPPLEMENTS_PER_PLAN) {
        petsWithMaxSupplements += 1;
      }
    });

    if (petsWithMaxSnacks === petPlans.length) {
      availableRecipes = availableRecipes.filter(
        (addOnRecipe) => addOnRecipe.type !== RecipeType.SNACK
      );
    }
    if (petsWithMaxSupplements === petPlans.length) {
      availableRecipes = availableRecipes.filter(
        (addOnRecipe) => addOnRecipe.type !== RecipeType.SUPPLEMENT
      );
    }
    availableRecipes = availableRecipes.filter((recipe) => !inEveryPetPlan(petPlans, recipe));
    return availableRecipes;
  };

  const petList = selectedPets || account.pets;
  const petPlans = petList
    .map((pet) => pet.pet_plan)
    .filter((petPlan) => petPlan.status !== PetPlanStatus.CANCELLED);

  const availableAddons = getAvailableRecipes(petPlans);

  return availableAddons;
};
